/*
 * @Date: 2022-04-07 13:52:14
 * @LastEditors: royce
 * @LastEditTime: 2022-05-27 10:59:59
 * @FilePath: /mall-web/src/models/goods.ts
 */
import server from '@/services/goods';
import shop from '@/services/shop';
import { IRootState } from '@/store';
import { DETAIL_BUY_TYPE, GoodListItem, GOODS_SORT_TYPE, HOME_TYPE } from '@/types';
import _ from 'lodash';

const PAGE_SIZE = 10;

export default {
  state: {
    goodsList: [],
    isShowSearch: false,
    channl: 'PC',
    pageIndex: 1,
    pageSize: PAGE_SIZE,
    orderType: HOME_TYPE.LAST_UPDATED_ON,
    sortType: GOODS_SORT_TYPE.DESC,
    total: 0,
    shopName: '',
    shopId: '',
    keyWords: '',
    emptyKeyword: '',
    goodCategory: '',
    categoryName: '',
    minPrice: '',
    maxPrice: '',
    categoryList: [],
    shopInfo: { address: '', shopId: '', shopName: '' },
  },
  reducers: {
    update(prevState, payload) {
      return { ...prevState, ...payload };
    },
    resetParam(preState) {
      const resetParam = {
        minPrice: '',
        maxPrice: '',
        keyWords: '',
        pageIndex: 1,
        goodCategory: '',
        categoryName: '',
        orderType: HOME_TYPE.LAST_UPDATED_ON,
        sortType: GOODS_SORT_TYPE.DESC,
      };
      return { ...preState, ...resetParam };
    },
  },
  effects: (dispatch) => ({
    async getGoodsList(data, { shop }: IRootState) {
      const { shopId, shopName, goodCategory, categoryName, minPrice, maxPrice, orderType, sortType, keyWords,pageIndex } = shop;
      const param = {
        shopId,
        shopName,
        goodCategory,
        categoryName,
        minPrice,
        maxPrice,
        orderType,
        sortType,
        keyWords,
        pageIndex,
        ...data,
      };
      delete param.shopName;
      const paramsNotNull = _.pickBy({ pageSize: PAGE_SIZE, channl: 'PC', ...param });
      try {
        const res = await server.getHomeGoodsList(paramsNotNull);
        const totalCount = res.total;
        const state = {
          goodsList: res.rows,
          total: totalCount,
          ...param,
        };
        dispatch.shop.update(state);
      } catch (error) {
        dispatch.shop.update({ ...data, emptyKeyword: data.keyWords || '' });
      }
    },
    async getCategoryListByShopId(shopId) {
      try {
        const res = await shop.getCategoryListByShopId(shopId);
        dispatch.shop.update({ categoryList: _.isArray(res) ? res : [] });
      } catch (error) {}
    },
    async getShopInfo(shopId) {
      try {
        const res = await shop.shopInfo(shopId);
        dispatch.shop.update({ shopInfo: res });
      } catch (error) {}
    },
  }),
};
