import { DELIVERY_TYPE } from '@/types';
import { config, request } from 'ice';
import { pickBy } from 'lodash';
import qs from 'qs';

const { BASE_URL } = config;
interface PurchaseOrderType {
  goods: Array<{
    categoryId: string; //三级分类ID
    goodsName: string; //商品名称
    spec: string[]; //商品规格ID（多个），使用 /shm/category/spec/matrix接口返回数据中的 value
    quantity: number; //采购数量
    unit: string; //采购数量单位
    price?: number; //预期单价
  }>;
  deliveryMethod: DELIVERY_TYPE; //配送方式：1. supplier_delivery
  deliveryAddress?: string; //当配送地址为supplier_delivery 时，填写 收货地址ID
  selfGetAddress?: string; //当配送地址为self_get 时，填写货物所在地的行政区划代码
  qunotationDeadLine: string; //最晚报价日期：yyyymmdd
  latestDeliveryDate: string; //最晚发货日期：yyyymmdd，当配送方式为 supplier_delivery时，必填
  latestPickDate: string; //最晚提货日期：yyyymmdd，当配送方式为 self_get 时，必填
  invoiceNeed: true; //是否需要开票
  comments?: string; //备注
}
export default {
  // 采购统计
  async getOverall() {
    return await request.get(`${BASE_URL}/shm/purchase/overall`);
  },
  // 采购动态
  async getPurchaseNewCount(count: number = 8) {
    return await request.get(`${BASE_URL}/shm/purchase/news?count=${count}`);
  },
  // 提交采购
  async submitPurchse(data: PurchaseOrderType) {
    return await request.post(`${BASE_URL}/shm/purchase/purchaseOrder`, data);
  },
  //获取规格笛卡尔积列表
  async getSpecMatrix(categoryId: string) {
    return await request.get(`${BASE_URL}/shm/category/spec/matrix?categoryId=${categoryId}`);
  },
  //获取采购单状态列表
  async getPurchaseStatusList() {
    return await request.get(`${BASE_URL}/shm/purchase/purchaseOrder/status`);
  },
  //获取采购单列表
  async getPurchaseOrderList(data) {
    return await request.get(`${BASE_URL}/shm/purchase/collect/list?${qs.stringify(pickBy({ ...data }))}`);
  },
  //获取采购单详情
  async getPurchaseOrderDetail(orderId, additional = false) {
    return await request.get(
      `${BASE_URL}/shm/purchase/purchaseOrderDetail?pOrderId=${orderId}&additional=${additional}`,
    );
  },
  //采购下单
  async purchaseOrder(data) {
    return await request.post(`${BASE_URL}/shm/purchase/purchaseOrder/confirm`, data);
  },
  //接受报价
  async acceptPurchaseOrder(pOrderId) {
    return await request.put(`${BASE_URL}/shm/purchase/purchaseOrder/accept?pOrderId=${pOrderId}`);
  },
  //拒绝报价
  async rejectPurchaseOrder(pOrderId) {
    return await request.put(`${BASE_URL}/shm/purchase/purchaseOrder/reject?pOrderId=${pOrderId}`);
  },
  //取消报价
  async cancelPurchaseOrder(pOrderId) {
    return await request.put(`${BASE_URL}/shm/purchase/purchaseOrder/cancel?pOrderId=${pOrderId}`);
  },
};
