import Dexie, { Table } from 'dexie';

export interface HistoryGoods {
  id?: number;
  goodsId: string;
  name: string;
  price: number;
  image: string;
  userId: string;
  time: number;
  goodsUnit?: string;

}


export class GoodsDexie extends Dexie {
  // 'friends' is added by dexie when declaring the stores()
  // We just tell the typing system this is the case
  goods!: Table<HistoryGoods>;
  constructor() {
    super('gong-lu');
    this.version(6).stores({
      goods: '++id,goodsId, name, price,userId,unit,time', // Primary key and indexed props
    });
  }
}

export const htdb = new GoodsDexie();
