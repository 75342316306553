/*
 * @Date: 2022-04-07 13:52:14
 * @LastEditors: royce
 * @LastEditTime: 2022-06-20 13:21:56
 * @FilePath: /mall-web/src/models/global.ts
 */
import server from '@/services/global';
import home from '@/services/catalog';
import cart from '@/services/cart';
import { history } from 'ice';
import listWithLetters from '@/assets/listWithLetters.json';
import { formatCategory } from '@/utils';
import _ from 'lodash';
import { IRootDispatch, IRootState } from '@/store';
import { AUTH_TYPE, CategoryType } from '@/types';
const mockbqs = {
  showList: [
    {
      name: '建筑工程', //资质名称
      id: 'xxxx1xxxx', //资质ID
    },
    {
      name: '工路工程', //资质名称
      id: 'xxxx2xxxx', //资质ID
    },
    {
      name: '铁路工程', //资质名称
      id: 'xxx3xxxxx', //资质ID
    },
  ], //页面展示的列表
  hidenList: [
    {
      name: '电力工程', //资质名称
      id: 'xxxxx4xxx', //资质ID
    },
    {
      name: '矿山、石油化工', //资质名称
      id: 'xxxxxx5xx', //资质ID
    },
  ], //放在“更多”中的列表
};
const mockBqsDetail = [
  {
    categoryId: '00000000-0000-0000-0000-100000000000', //商品类别ID
    categoryName: '混凝土构建', //商品类别名称
    level: 2, //商品类别层级
    parentId: 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx', //上一级类别ID
    children: [
      {
        categoryId: '00000000-0000-0000-0000-100000000001',
        categoryName: '混凝土制品',
        level: 3,
        parentId: '00000000-0000-0000-0000-100000000000',
      },
      {
        categoryId: '00000000-0000-0000-0000-100000000002',
        categoryName: '预制桩',
        level: 3,
        parentId: '00000000-0000-0000-0000-100000000000',
      },
    ], //下级分类列表
  },
];
export interface CityType {
  areaCode: number;
  areaName: string;
}

export enum LoginModalType {
  LOGIN = 'login',
  REGISTER = 'register',
  RESET_PASSWORD = 'resetPassword',
}
export interface AreaType {
  code: number;
  name: string;
  children: AreaType[];
}
export interface BQSType {
  name: string;
  id: string;
}
export interface CityValue {
  firstLetter: string;
  areaInfoList: CityType[];
}

export default {
  state: {
    loginVisible: false,
    showAllCategory: false,
    showItemCategory: false,
    type: LoginModalType.LOGIN,
    subCategoryList: [],
    activeMenu: '',
    categoryList: [],
    totalCategoryList: [],
    cityList: {},
    cityName: '杭州市',
    currentArea: [],
    areas: [],
    areasProvince: [],
    shortCartList: [],
    bqsList: [] as BQSType[],
    bqsMoreList: [] as BQSType[],
    bqsDetail: [] as CategoryType[],
    activeMenuName: '',
    activeMenuV2: '',
    expressCompany: [
      {
        comCode: 'yuantong', //物流公司编码
        name: '圆通速递', //物流公司名称
      },
      {
        comCode: 'huitongkuaidi', //物流公司编码
        name: '百世快递', //物流公司名称
      },
    ],
  },

  reducers: {
    update(prevState, payload) {
      return { ...prevState, ...payload };
    },
    showLogin(prevState) {
      prevState.loginVisible = true;
      prevState.type = LoginModalType.LOGIN;
    },
    showRegister(prevState) {
      prevState.loginVisible = true;
      prevState.type = LoginModalType.REGISTER;
    },
  },
  effects: (dispatch: IRootDispatch) => ({
    async uploadFile(data) {
      const res = await server.uploadFile(data);
      return res;
    },
    async getCatalogList(parentId) {
      const res = await home.getCatalogList(parentId);
      const treelist = formatCategory(res);
      dispatch.global.update({ categoryList: treelist, totalCategoryList: res });
    },
    async getCartAllList(data?, rootState?) {
      try {
        const { user } = rootState;
        const res = await cart.getAllCart({
          pageIndex: 1,
          pageSize: 100,
          memberId: user.id,
          ...data,
        });

        const temp = [];
        if (res.rows.length > 0) {
          res.rows.forEach((item) => {
            temp.push(...item.goodsList);
          });
        }
        dispatch.global.update({ shortCartList: temp });
      } catch (error) {
        console.log(error, 'error');
      }
    },
    async getLocal() {
      const res = await server.getCityList();

      const cityCodes = ['ABCDE', 'FGHJK', 'LMNPQ', 'RSTWX', 'YZ'];
      const lm = {};
      cityCodes.forEach((item: string) => {
        const temp = [];
        for (const i in item) {
          const ls = res.areaList.filter((l: CityValue) => l.firstLetter === item[i]);
          temp.push(ls);
        }
        lm[item] = temp;
      });
      dispatch.global.update({ cityList: lm, cityName: res.currentLocation });
    },
    async getExpressCompany() {
      const res = await server.getExpressCompany();
      dispatch.global.update({ expressCompany: res });
    },
    async getArea() {
      const res = await server.getCityLocations();
      dispatch.global.update({ areas: res });
    },
    async getAreaProvince() {
      const res = await server.getCityLocations({ type: 'PROVINCE' });
      dispatch.global.update({ areasProvince: res });
    },

    async checkLogin(data, { user }: IRootState) {
      const { id, authState } = user;

      if (!id) {
        dispatch.global.showLogin();
        return { flag: false, msg: '请先登录' };
      }
      if (authState < AUTH_TYPE.PERSON_APPROVED) {
        // 跳转实名认证
        history && history.push('/workbench/personAuth');
        return { flag: false, msg: '请先完成实名认证' };
      }
      if (authState < AUTH_TYPE.ENTERPRISE_APPROVED) {
        //跳转企业认证
        history && history.push('/workbench/auth');
        return { flag: false, msg: '请先完成企业认证' };
      }
      return { flag: true };
    },
    async getBqsData() {
      const res = await server.getMenuBqs();
      const { showList, hidenList } = res;
      dispatch.global.update({ bqsList: showList, bqsMoreList: hidenList });
    },
    async getBqsDetail(id, { global }: IRootState) {
      const arr = [...global.bqsList, ...global.bqsMoreList];
      const target = arr.find((item) => item.id === id);
      dispatch.global.update({ activeMenuName: target?.name });
      const res = await server.getMenuBqsDetail(id);
      if (res) {
        dispatch.global.update({ bqsDetail: res });
      }
    },
  }),
};
