/*
 * @Date: 2022-05-24 15:47:16
 * @LastEditors: royce
 * @LastEditTime: 2022-06-20 16:53:10
 * @FilePath: /mall-web/src/models/address.ts
 */
import server from '../services/enquiry';
import { DELIVERY_TYPE, EnquiryItem, ENQUIRY_STATUS, INVOICE_NEED_TYPE } from './../types';
import { showErrorToast, showSuccess } from '@/utils';
import { history as iHistory } from 'ice';
import qs from 'qs';
import _ from 'lodash';
import { IRootState, IRootDispatch } from '@/store';
import { ENQUIRY_DETAIL_MOCK } from '@/mocks';

export const ALL_ENQUIRY__STATUS = undefined;
export const ENQUIRY_PAGE_SIZE = 10;
export default {
  state: {
    enquiryData: [],
    enquiryList: [],

    enquiryDetail: {
      addressId: '',
      amount: 1,
      enquiryCode: '',
      enquiryTime: '',
      goodsId: '',
      goodsName: '',
      latestDeliveryDate: '',
      orderId: '',
      url: '',
      picJson: [],
      price: 0,
      shopId: '',
      shopName: '',
      invoiceNeed: INVOICE_NEED_TYPE.YES,
      postFee: 0,
      spec: {},
      remark: '',
      deliveryMethod: DELIVERY_TYPE.BY_SELLER,
      enquiryStatus: ENQUIRY_STATUS.QUOTED,
      enquiryAddress: {
        address: '',
        area: '',
        city: '',
        province: '',
        receiveName: '',
        receivePhone: '',
      },
      goodsList: [],
    },
    status: undefined,
    startTime: '',
    endTime: '',
    total: 0,
    pageIndex: 1,
    searchLoading: false,
    searchCode: '',
    dateRange: [] as any,
  },
  reducers: {
    update(prevState, payload) {
      return { ...prevState, ...payload };
    },
    updateGoodsAttachment(prevState, payload) {
      prevState.enquiryData.forEach((item, index) => {
        if (index === payload.index) {
          item.attachment = payload.url;
        }
      });
    },
    updateGoodsCount(prevState, payload) {
      prevState.enquiryData.forEach((item, index) => {
        if (index === payload.index) {
          item.count = payload.count;
        }
      });
    },
  },
  effects: (dispatch: IRootDispatch) => ({
    async submitEnquiry(data, { user }: IRootState) {
      try {
        await server.submitEnquiry({ memberId: user.id, ...data });
        showSuccess('询价提交成功');
        setTimeout(() => {
          iHistory && iHistory.push('/workbench/enquiryList');
        }, 500);
      } catch (error) {}
    },
    async getEnquiryList(data, { user, enquiry }: IRootState) {
      dispatch.enquiry.update({ searchLoading: true });
      const { dateRange, searchCode } = enquiry;
      if (Array.isArray(dateRange) && dateRange.length) {
        data['startTime'] = enquiry.dateRange[0].format('YYYY-MM-DD');
        data['endTime'] = enquiry.dateRange[1].format('YYYY-MM-DD');
      }
      if (searchCode) {
        data['keyWords'] = searchCode;
      }
      console.log(data, 'getEnquiryList');
      const res = await server.getEnquiryList({ memberId: user.id, ...data, pageSize: ENQUIRY_PAGE_SIZE });
      setTimeout(() => {
        dispatch.enquiry.update({ enquiryList: res.rows, total: res.total, searchLoading: false });
      }, 300);
      const query = { status: data.status, pageIndex: data.pageIndex };
      if (location.pathname.indexOf('enquiry-detail') < 0) {
        history && history.pushState({}, '', `${location.pathname}?${qs.stringify(query)}`);
      }
    },
    async getEnquiryDetail(enquiryCode, { user, enquiry }) {
      const res = await server.getEnquiryDetail(enquiryCode);
      console.log(res, 'res');
      const { province, city, area, address, receiveName, receivePhone } = res;
      const { goodsName, spec, amount, url, goodsUnit,unitEach,priceUnit,measureMethod } = res;

      dispatch.enquiry.update({
        enquiryDetail: {
          ...res,
          goodsList: [{ goodsName, spec, amount, url, goodsUnit,unitEach,priceUnit,measureMethod}],
          enquiryAddress: {
            province,
            city,
            area,
            address,
            receiveName,
            receivePhone,
          },
        },
      });
    },
    async onEnquiryOrder({ enquiryItem }: { enquiryItem: EnquiryItem }, { user }) {
      const {
        amount,
        enquiryCode,
        goodsId,
        goodsName,
        spec,
        postFee,
        price,
        picJson,
        shopId,
        shopName,
        deliveryMethod = DELIVERY_TYPE.BY_SELLER,
        invoiceNeed,
        address,
        area,
        province,
        city,
        receiveName,
        receivePhone,
        goodsUnit,
        measureMethod,
        unitEach,
        priceUnit
      } = enquiryItem;
      const goods = {
        count: amount,
        goodsName,
        picJson,
        spec,
        goodsId,
        goodsUnit,
        measureMethod,
        unitEach,
        priceUnit
      };
      // 根据询价单生成订单
      const submitData = {
        skus: [goods],
        shopName,
        shopId,
        loading: false,
        goodsTotal: price,
        picJson,
        enquiryCode,
        postFee,
        invoiceNeed,
        deliveryMethod,
        enquiryAddress: {
          address: address,
          area: area,
          city: city,
          province: province,
          receiveName: receiveName,
          receivePhone: receivePhone,
        },
      };
      dispatch.submit.update(submitData);
      sessionStorage.removeItem('submitData');
      sessionStorage.setItem('submitData', JSON.stringify(submitData));
      iHistory && iHistory.push('/submit?from=enquiry');
    },
    async closeEnquiry(data, { user, enquiry }) {
      const { pageIndex, status } = enquiry;
      const res = await server.closeEnquiry(data.enquiryCode);
      dispatch.enquiry.getEnquiryDetail(data.enquiryCode);
      dispatch.enquiry.getEnquiryList({ pageIndex, status });
      showSuccess('询价关闭成功');
    },
    async deleteEnquiry(data, { user, enquiry }) {
      const { pageIndex, status } = enquiry;
      console.log(data, 'data');
      const res = await server.deleteEnquiry(data.enquiryCode);
      showSuccess('询价删除成功');
      dispatch.enquiry.getEnquiryDetail(data.enquiryCode);
      dispatch.enquiry.getEnquiryList({ pageIndex, status });
    },
  }),
};
