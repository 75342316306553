import { config, request } from 'ice';
import qs from 'qs';

const { BASE_URL } = config;

export default {
  submitEnquiry(data) {
    return request.post(`${BASE_URL}/shm/enquiry`, data);
  },
  async getEnquiryList(data) {
    return await request.get(`${BASE_URL}/shm/enquiry/${data.memberId}/buyer?${qs.stringify(data)}`);
  },
  async getEnquiryDetail(enquiryCode) {
    return await request.get(`${BASE_URL}/shm/enquiry/${enquiryCode}/detail`);
  },
  async closeEnquiry(enquiryCode) {
    return await request.post(`${BASE_URL}/shm/enquiry/${enquiryCode}/close`);
  },
  async deleteEnquiry(enquiryCode) {
    return await request.delete(`${BASE_URL}/shm/enquiry/${enquiryCode}/delete`);
  },
};
