/*
 * @Date: 2022-05-23 13:28:10
 * @LastEditors: royce
 * @LastEditTime: 2022-06-01 15:02:50
 * @FilePath: /mall-web/src/components/InquiryItem/index.tsx
 */
import { useEffect } from 'react';
import enquiryTop from '@/assets/enquiry_top.png';
import './index.less';

export default function EnquiryTitle(props) {
  //   const [userState, userDispatchers] = appStore.useModel('user');

  useEffect(() => {}, []);

  return (
    <div className="base" >
      <div className="wrapper">
        <img className="image" src={enquiryTop} />
      </div>
    </div>
  );
}
