/*
 * @Date: 2022-05-23 13:28:10
 * @LastEditors: royce
 * @LastEditTime: 2022-06-27 15:37:33
 * @FilePath: /mall-web/src/components/ShopHeader/index.tsx
 */
import logo from '@/assets/logo.png';
import appStore from '@/store';
import { addOrUpdateQuerys, showWarning } from '@/utils';
import { URL_PATH } from '@/utils/routerUtil';
import { CaretDownFilled, DownOutlined } from '@ant-design/icons';
import { Button, Image, Input, MenuProps, Popover, Tree, TreeProps } from 'antd';
import { useHistory, useLocation, getSearchParams } from 'ice';
import { trim } from 'lodash';
import QueryString from 'qs';
import qs from 'qs';
import { useEffect, useState } from 'react';
import ShopTitlleItem from '../ShopTitlleItem';
import styles from './index.module.less';

export default function ShopHeader(props) {
  const [shopState, shopActions] = appStore.useModel('shop');
  const [globalState] = appStore.useModel('global');
  const [goodslist, goodslistDispatchers] = appStore.useModel('goodslist');
  const [goodsState] = appStore.useModel('goods');
  const [openCategory, setOpenCategory] = useState(false);
  const { keyWords, orderType, sortType } = shopState;
  const history = useHistory();
  const location = useLocation();

  console.log(location.pathname, 'pathname');
  const search = qs.parse(location.search.slice(1));
  const shopId = shopState.shopId || search.shopId;
  const shopName = shopState.shopName || search.shopName;
  //   const [userState, userDispatchers] = appStore.useModel('user');
  console.log(keyWords, 'keyWords');
  useEffect(() => {}, []);
  const handleOpenChange = (newOpen: boolean) => {
    setOpenCategory(newOpen);
  };
  const onShopGoods = () => {
    shopActions.resetParam();
    const isFromDetail = location.pathname === URL_PATH.GOODS_DETAIL;
    const params = {
      shopId: isFromDetail ? goodsState.goodsResult.shopId : search.shopId,
      shopName: isFromDetail ? goodsState.goodsResult.shopName : search.shopName,
    };
    shopActions.update(params);
    if (location.pathname !== URL_PATH.SHOP) {
      history.push(`/shop?${qs.stringify(params)}`);
    } else {
      shopActions.getGoodsList({});
      // addOrUpdateQuerys({ shopId, shopName });
    }
  };

  const onHome = () => {
    history.push('/');
  };
  const onShopIndex = () => {
    if (location.pathname === URL_PATH.GOODS_DETAIL) {
      history.push(`/shopIndex?shopId=${goodsState.goodsResult.shopId}&shopName=${goodsState.goodsResult.shopName}`);
    } else {
      history.push(`/shopIndex?shopId=${shopId}&shopName=${shopName}`);
    }
  };

  // const onClearSearchHistory = () => {
  //   goodsListDispatchers.resetSearchHistory();
  // };
  // const onChangeSearch = (event) => {
  //   goodsListDispatchers.update({ keyWords: event.target.value });
  // };
  const searchShop = () => {
    if (!keyWords) {
      showWarning('请输入搜索条件');
      return;
    }
    const trimed = trim(keyWords)
    shopActions.resetParam();
    shopActions.update({keyWords:trimed})
    if (location.pathname === '/shop') {
      shopActions.getGoodsList({});
    } else {
      const params = { shopId, shopName };
      history.push(`/shop?${QueryString.stringify(params)}`);
    }
  };
  const searchAll = () => {
    if (!keyWords) {
      showWarning('请输入搜索条件');
      return;
    }
    const trimed = trim(keyWords)
    goodslistDispatchers.update({ categorys: [] });
    history.push(`/goods?keyWords=${trimed}`, { searchfrom: '/shop' });
  };
  const onChangeSearch = (event) => {
    shopActions.update({ keyWords: event.target.value });
  };

  const onSelect: TreeProps['onSelect'] = (selectedKeys, info) => {
    setOpenCategory(false);
    shopActions.resetParam();

    const categoryName = info.node.categoryName;
    shopActions.update({goodCategory: selectedKeys[0],categoryName})
    if (location.pathname === '/shop') {
      shopActions.getGoodsList({});
      addOrUpdateQuerys({ shopId, goodCategory: selectedKeys[0], shopName, categoryName });
    } else {
      history.push(
        `/shop?shopId=${shopId}&shopName=${shopName}`,
      );
    }
  };
  const categoryTree = () => {
    return (
      <Tree
        showLine
        switcherIcon={<DownOutlined />}
        // defaultExpandedKeys={['0-0-0']}
        fieldNames={{ title: 'categoryName', key: 'categoryId', children: 'child' }}
        onSelect={onSelect}
        treeData={shopState.categoryList}
      />
    );
  };

  return (
    <div className={styles.searchContainer}>
      <div className={styles.searchBody}>
        <>
          <div className={styles.logoContainer}>
            <Image preview={false} onClick={onHome} className={styles.logo} src={logo} />
            <span className={styles.logoTitle}>工路</span>
          </div>
          <div className={styles.shopContainer}>
            <ShopTitlleItem onShop={onShopIndex} title={shopName} />
          </div>
        </>
        <div className={styles.searchInp}>
          <div className={styles.searchRow}>
            <Input
              size="large"
              value={keyWords}
              maxLength={16}
              className={styles.queryInp}
              placeholder="请输入商品名称"
              onPressEnter={searchShop}
              onChange={onChangeSearch}
            />
            <Button onClick={searchShop} className={styles.searchBtn} size="large" type="primary">
              搜索本店
            </Button>
            <Button onClick={searchAll} className={styles.cart} size="large" type="default">
              搜索全站
            </Button>
          </div>

          {/* <div>{'热词'}</div> */}
        </div>
      </div>
      <div className={styles.tLine}>
        <div className={styles.naviContainer}>
          <span
            onClick={onShopIndex}
            className={location.pathname === '/shopIndex' ? styles.homeTitleSelect : styles.homeTitle}
          >
            首页
          </span>
          <span style={{ width: '20px' }} />
          <Popover
            placement="bottom"
            open={openCategory}
            onOpenChange={handleOpenChange}
            trigger="hover"
            content={categoryTree}
          >
            <span
              onClick={onShopGoods}
              className={location.pathname === '/shop' ? styles.homeTitleSelect : styles.homeTitle}
            >
              货架商品
              <CaretDownFilled style={{ color: '#DADADA' }} />
            </span>
          </Popover>

          {/* <Dropdown position={DropdownPosition.AutoBottomLeft}>
            <DropdownHoverTrigger>

            </DropdownHoverTrigger>
            <DropdownContent>
              <Menu />
            </DropdownContent>
          </Dropdown> */}
          <span style={{ width: '20px' }} />
          {/* <span onClick={onShopInfo} className={styles.homeTitle}>
            企业介绍
          </span> */}
        </div>
      </div>
    </div>
  );
}
