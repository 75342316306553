/* eslint-disable max-lines */
/*
 * @Date: 2022-05-23 09:59:11
 * @LastEditors: royce
 * @LastEditTime: 2022-06-27 13:20:11
 * @FilePath: /mall-web/src/Layouts/BasicLayout/index.tsx
 */
import EnquiryTitle from '@/components/EnquiryTitle';
import LayoutFooter from '@/components/LayoutFooter';
import LayoutHeaderMenu from '@/components/LayoutHeaderMenu';
import LayoutHeaderTop from '@/components/LayoutHeaderTop';
import LayoutHeaderV2 from '@/components/LayoutHeaderV2';
import LoginView from '@/components/LoginView';
import { LoginModalType } from '@/models/global';
import appStore from '@/store';
import { showWarning } from '@/utils';
import { isShowMenu, renderTitleView, URL_PATH } from '@/utils/routerUtil';
import { Layout, Modal } from 'antd';
import _ from 'lodash';
import { createElement, useEffect, useMemo, useState } from 'react';
import styles from './index.module.less';

const { Content } = Layout;

const loopMenuItem = (menus) =>
  menus.map(({ icon, children, ...item }) => ({
    ...item,
    icon: icon && createElement(icon),
    children: children && loopMenuItem(children),
  }));

export default function BasicLayout({ children, location }) {
  const showMenu = isShowMenu(location.pathname);
  const [globalState, globalDispatchers] = appStore.useModel('global');
  const [userState, userDispatchers] = appStore.useModel('user');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [sms, setSms] = useState('');
  const [loginLoading, setLoginLoading] = useState(false);
  const grayPaths = [URL_PATH.HOME, URL_PATH.ENQUIRY, URL_PATH.PURCHASE];
  const fullPaths = [URL_PATH.PURCHASE_CENTER, , URL_PATH.PURCHASE];
  const contentStyle = !(grayPaths.indexOf(location.pathname) > -1)
    ? { backgroundColor: 'white' }
    : { backgroundColor: '#f1f1f1' };
  let childrenStyle = fullPaths.indexOf(location.pathname) > -1 ? styles.fullContainer : styles.container;
  if (location.pathname === URL_PATH.ENQUIRY) {
    childrenStyle = styles.containerV2;
  }
  const { showAllCategory, categoryList, type } = globalState;
  const loginModalTitle = useMemo(() => {
    if (type === LoginModalType.LOGIN) {
      return '登录';
    } else if (type === LoginModalType.REGISTER) {
      return '注册';
    } else {
      return '忘记密码';
    }
  }, [type]);
  const loginBtnText = useMemo(() => {
    if (type === LoginModalType.LOGIN) {
      return '登录';
    } else if (type === LoginModalType.REGISTER) {
      return '注册并登录';
    } else {
      return '提交';
    }
  }, [type]);
  useEffect(() => {
    if (userState.token && userState.id) {
      userDispatchers.getUserInfo(userState.id);
      userDispatchers.getCompanyAuth();
    }

    if (!categoryList.length) {
      globalDispatchers.getCatalogList();
    }

    globalDispatchers.getLocal();
    globalDispatchers.getArea();
  }, []);

  useEffect(() => {
    if (showMenu) {
      globalDispatchers.getBqsData();
    }
  }, [showMenu]);

  useMemo(() => {
    if (!showAllCategory) {
      globalDispatchers.update({ showItemCategory: false });
    }
  }, [showAllCategory]);
  if (_.get(location, 'pathname') === '/login') {
    return <div>{children}</div>;
  }

  const onClose = () => {
    globalDispatchers.update({ loginVisible: false });
  };
  const changePassword = (event) => {
    setPassword(event.target.value);
  };
  const changeSMS = (event) => {
    setSms(event.target.value);
  };

  const onSwitchLogin = () => {
    globalDispatchers.update({ type: LoginModalType.LOGIN });
  };

  const changeUsername = (event) => {
    setUsername(event.target.value);
  };
  const loginRequest = async () => {
    if (!checkInput()) {
      return;
    }
    setLoginLoading(true);
    const error = await userDispatchers.userLogin({ username, password });
    !error && onClose();
    setLoginLoading(false);
  };
  const registerRequest = async () => {
    if (!checkInput()) {
      return;
    }
    try {
      setLoginLoading(true);
      const error = await userDispatchers.register({ phone: username, password, code: sms });
      !error && onClose();
      setLoginLoading(false);
      console.log(error, 'errorregisterRequest');
    } catch (error) {
      console.log(error, 'errorregisterRequest');
    }
  };
  const resetRequest = async () => {
    if (!checkInput()) {
      return;
    }
    try {
      setLoginLoading(true);
      const error = await userDispatchers.resetPwd({ newPassword: password, code: sms, mobile: username });
      !error && onSwitchLogin();
      setLoginLoading(false);
    } catch (error) {
      console.log(error, 'errorregisterRequest');
    }
  };
  const checkInput = () => {
    if (!username || username.length !== 11) {
      showWarning('请输入手机号');
      return false;
    }

    if (!password) {
      showWarning('请输入密码');
      return false;
    }
    if (type === LoginModalType.REGISTER || type === LoginModalType.RESET_PASSWORD) {
      if (!sms) {
        showWarning('请输入验证码');
        return false;
      }
    }
    return true;
  };

  const renderExtraHeader = () => {
    if (location.pathname === URL_PATH.ENQUIRY) {
      return <EnquiryTitle />;
    }
    return null;
  };
  const renderTop = () => {
    if (location.pathname == URL_PATH.PURCHASE_CENTER) {
      return <LayoutHeaderV2 />;
    }
    return <LayoutHeaderTop />;
  };
  return (
    <Layout>
      {renderTop()}
      <Content style={contentStyle}>
        {renderTitleView(location.pathname)}
        {showMenu && <LayoutHeaderMenu />}
        {renderExtraHeader()}
        <div className={childrenStyle}>{children}</div>
      </Content>
      {globalState.loginVisible && (
        <Modal
          width={300}
          maskClosable={false}
          destroyOnClose
          title={loginModalTitle}
          confirmLoading={loginLoading}
          closable={false}
          cancelText="取消"
          okText={loginBtnText}
          visible={globalState.loginVisible}
          onOk={() => {
            if (type === LoginModalType.LOGIN) {
              loginRequest();
            } else if (type === LoginModalType.REGISTER) {
              registerRequest();
            } else {
              resetRequest();
            }
          }}
          onCancel={onClose}
        >
          <LoginView
            changeUsername={changeUsername}
            changePassword={changePassword}
            changeSMS={changeSMS}
            username={username}
          />
        </Modal>
      )}
      <LayoutFooter />
    </Layout>
  );
}
