/*
 * @Date: 2022-05-23 13:28:10
 * @LastEditors: royce
 * @LastEditTime: 2022-06-08 16:04:37
 * @FilePath: /mall-web/src/components/ShopTitlleItem/index.tsx
 */
import { useEffect } from 'react';
import { Tabs, Image } from 'antd';
import styles from './index.module.less';
import shangjia from '@/assets/tuijianshangjia.png';
import yirenzheng from '@/assets/yirenzheng.png';

const { TabPane } = Tabs;

export default function ShopTitlleItem(props) {
  const { title,onShop } = props;
  //   const [userState, userDispatchers] = appStore.useModel('user');

  useEffect(() => {

  }, []);


  return (
    <div onClick={onShop} className={styles.container}>
      <span className={styles.titleText}>{title}</span>
      <div className={styles.subBox}>
        <Image preview={false} className={styles.tuijImage} src={shangjia} />
        <Image preview={false} className={styles.yirenzheng} src={yirenzheng} />
      </div>
    </div>
  );
}

