export const LineH = () => {
  return (
    <div
      style={{
        background: ' #F5F5F6',
        height: 1,
        // width: '98%',
        // margin: 'auto',
      }}
    />
  );
};
