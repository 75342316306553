/*
 * @Date: 2022-05-23 16:53:19
 * @LastEditors: royce
 * @LastEditTime: 2022-06-20 16:32:39
 * @FilePath: /mall-web/src/services/address.ts
 */
import { config, request } from 'ice';
import qs from 'qs';

const {
  BASE_URL,
} = config;

export default {
  async getAddress(data) {
    return await request.get(`${BASE_URL}/shm/member/${data.memberId}/address`);
  },
  async deleteAddress(data) {
    return await request.delete(`${BASE_URL}/shm/member/${data.memberId}/address?${qs.stringify(data)}`);
  },
  async addAddress(data) {
    return await request.post(`${BASE_URL}/shm/member/${data.memberId}/address`, data);
  },
  async updateAddress(data) {
    return await request.put(`${BASE_URL}/shm/member/${data.memberId}/address`, data);
  },
};
