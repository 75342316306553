/*
 * @Date: 2022-05-23 13:28:10
 * @LastEditors: royce
 * @LastEditTime: 2022-06-23 10:32:26
 * @FilePath: /mall-web/src/components/GoodsFilter/index.tsx
 */
import appStore from '@/store';
import { getGoodsCover, showWarning } from '@/utils';
import { useHistory } from 'ice';
import _ from 'lodash';
import { useEffect } from 'react';
import styles from './index.module.less';

export default function ShortCartPop() {
  const history = useHistory();
  const [globalState, globalDispatchers] = appStore.useModel('global');
  const [cartState, cartDispatchers] = appStore.useModel('cart');
  const [userState] = appStore.useModel('user');
  const { shortCartList } = globalState;
  //   const [userState, userDispatchers] = appStore.useModel('user');

  useEffect(() => {}, []);
  const onDeleteCart = async (cartId: string) => {
    await cartDispatchers.deleteItem({ cartId, memberId: userState.id });
    globalDispatchers.getCartAllList();
  };
  const onCart = async () => {
    if (!userState.id) {
      showWarning('请登录');
      globalDispatchers.showLogin();
      return;
    }
    history.push('/cart');
  };

  return (
    <div className={styles.shortCartView}>
      {shortCartList.length > 0 && <div className={styles.shortCartHd}>最近加入的商品</div>}
      <div className={styles.shortCartList}>
        {shortCartList.map((item) => {
          const { goodsName, price, count, specTitle } = item;
          const specKeys = Object.keys(_.defaultTo(specTitle, {}));
          const specString = specKeys.map((k) => {
            return `${k}:${specTitle[k]}`;
          });
          const goodsTitle = `${goodsName} ${specString.join('/')}`;
          console.log(goodsTitle, 'specString');
          return (
            <div className={styles.cartItem}>
              <div className={styles.cartLeft}>
                <img className={styles.cartItemImage} src={getGoodsCover(item)} />
                <span className={styles.cartGoodsName}>{goodsTitle}</span>
              </div>
              <div className={styles.cartInfo}>
                <div className={styles.cartGoodsPrice}>
                  <span style={{ fontWeight: 600 }}>¥{price}</span> x{count}
                </div>
                <div
                  onClick={() => {
                    onDeleteCart(item.cartId);
                  }}
                  className={styles.cartGoodsDelete}
                >
                  删除
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className={styles.shortCartFt}>
        <div className={styles.shortCartFtInfo}>
          共<span>{shortCartList.length}</span>件商品
        </div>
        <div onClick={onCart} className={styles.shortCartFtBtn}>
          {userState.id ? '去购物车' : '未登录'}
        </div>
      </div>
    </div>
  );
}
