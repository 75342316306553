
/*
 * @Date: 2022-05-24 15:47:16
 * @LastEditors: royce
 * @LastEditTime: 2022-06-20 16:53:10
 * @FilePath: /mall-web/src/models/address.ts
 */

import _, { isArray } from 'lodash';
import { IRootState, IRootDispatch } from '@/store';
import global from '@/services/global';
import server from '../services/purchase';
import { BannerType, PurchaseDynamicType, StatisticType } from './../types';

export default {
  state: {
    banners: [] as BannerType[],
    statistics: [] as StatisticType[],
    dynamicData: [] as PurchaseDynamicType[],
  },
  reducers: {
    update(prevState, payload) {
      return { ...prevState, ...payload };
    },
  },
  effects: (dispatch: IRootDispatch) => ({
    /* banner */
    async getBanner(data, { user }: IRootState) {
      const res = await global.getBanner({ bannerType: 'pc_centralizedPurchase_index', userId: user.id });
      if (isArray(res)) {
        dispatch.purchaseCenter.update({ banners: res });
      }
    },
    /* 统计数据 */
    async getStatistics(data, { user }: IRootState) {
      const res = await server.getOverall();
      if (isArray(res)) {
        dispatch.purchaseCenter.update({ statistics: res });
      }
    },
    /* 采购动态 */
    async getDynamicData() {
      const res = await server.getPurchaseNewCount();
      if (isArray(res)) {
        dispatch.purchaseCenter.update({ dynamicData: res });
      }
    },
  }),
};
