
import "core-js/stable";
import "regenerator-runtime/runtime";
/*
 * @Date: 2022-04-07 13:52:14
 * @LastEditors: royce
 * @LastEditTime: 2022-06-22 16:27:31
 * @FilePath: /mall-web/src/app.tsx
 */
import { APP_MODE, config, IAppConfig, runApp } from 'ice';
import appStore from '@/store';
import { ConfigProvider, message, Spin } from 'antd';
import locale from 'antd/es/locale/zh_CN';
import _ from 'lodash';
import 'moment/dist/locale/zh-cn';
import 'zent/css/index.css';
import { addWindowsReload } from './utils';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import * as pkg from "../package.json";

// Sentry.init({
//   dsn: 'https://2da43e16bebe4125a2ea208be5cfabcf@sentry.gonglu.info:900/4',
//   integrations: [new BrowserTracing()],
//   environment: APP_MODE === 'prod' ? 'production' : 'test',
//   release:pkg.version,
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

const appConfig: IAppConfig = {
  app: {
    rootId: 'ice-container',
    addProvider: ({ children }) => {
      return <ConfigProvider locale={locale}>{children}</ConfigProvider>;
    },
  },

  router: {
    type: 'browser',
    basename: '/buyer/',
    fallback: (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Spin />
      </div>
    ),
  },
  request: {
    baseURL: '',
    interceptors: {
      request: {
        onConfig: (config) => {
          const token = localStorage.getItem('token') || '';
          // 发送请求前：可以对 RequestConfig 做一些统一处理
          config.headers = {
            'Content-Type': _.get(config, 'headersProps.ContentType', 'application/json'),
          };
          if (!_.get(config, 'data.noToken')) {
            config.headers['Authorization'] = token;
          }
          // console.log('request config', config);

          return config;
        },
        onError: (error) => {
          return Promise.reject(error);
        },
      },
      response: {
        onConfig: (response) => {
          // 请求成功：可以做全局的 toast 展示，或者对 response 做一些格式化
          if (response.data.status > 300 || response.data.status < 200) {
            const msg = _.get(response, 'data.message') || null;
            message.destroy();
            message.error(msg || '请求失败');
            return false;
          }

          response.data.data = response.data.data || true;
          return response.data;
        },
        onError: (error) => {
          const statusCode = _.get(error, 'response.status');
          const code = _.get(error, 'response.data.code') || null;
          const msg = _.get(error, 'response.data.message') || null;
          if (code === 35009) {
            return;
          }
          // 请求出错：服务端返回错误状态码
          message.destroy();
          message.error(msg || '您的网络发生异常，无法连接服务器');
          // console.log('error', error.response);

          if (statusCode === 401) {
            message.destroy();
            message.error(msg || '请重新登录');
            appStore.getModel('user')[1].resetUser();

            // history && history.push('/login');
          }

          return Promise.reject(error);
        },
      },
    },
  },
};

runApp(appConfig);
addWindowsReload();

  