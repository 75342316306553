import { BannerType } from './../types';
/*
 * @Date: 2022-04-07 13:52:14
 * @LastEditors: royce
 * @LastEditTime: 2022-06-22 10:04:28
 * @FilePath: /mall-web/src/models/home.ts
 */
import { getCache, StoreCacheType } from '@/mocks';
import server from '@/services/goods';
import global from '@/services/global';
import { CategoryType, DETAIL_BUY_TYPE, GoodListItem, HOME_TYPE } from '@/types';
import { formatCategory, listToTree } from '@/utils';
import _, { isArray } from 'lodash';

export interface TopCategoryItem {
  categoryId: string;
  categoryName: string;
  picUrl: string;
  descSummary: string;
  descDetail: string[];
  subCategory: TopCategoryItem[];
}

export interface HomeState {
  goodsList: GoodListItem[];
  hotList: CategoryType[];
  pageIndex: number;
  hasNextPage: true;
  categoryList: CategoryType[];
  flatCategoryList: CategoryType[];
  totalAmount: number;
  totalEnquiryAmount: number;
  totalEnquiryOrder: number;
  topCategoryList: TopCategoryItem[];
}

export default {
  state: {
    goodsList: getCache(StoreCacheType.HomeGoodsData),
    homeBanner: [] as BannerType[],
    hotList: [],
    categoryList: [],
    pageIndex: 1,
    hasNextPage: true,
    subCategoryList: [],
    showItemCategory: false,
    totalAmount: 0,
    totalEnquiryAmount: 0,
    totalEnquiryOrder: 0,
    topCategoryList: [],
    flatCategoryList: [],
    mainCategoryList: [],
  },
  reducers: {
    update(prevState, payload) {
      return { ...prevState, ...payload };
    },
  },
  effects: (dispatch) => ({
    async getGoodsList() {
      const param = {
        pageIndex: 1,
        pageSize: 20,
        channl: 'APP',
        orderType: HOME_TYPE.LAST_UPDATED_ON,
        sortType: 'DESC',
      };
      const res = await server.getHomeGoodsList(param);
    },
    async getHomeBannerList(data, { user }) {
      const res = await global.getBanner({ bannerType: 'pc_index', userId: user.id });
      if (isArray(res)) {
        dispatch.home.update({ homeBanner: res });
      }
    },
    async getHomeStaticData() {
      const res = await server.getHomeStaticData();
      dispatch.home.update({ hotList: [...res] });
    },
    async getYearSummery() {
      const res = await global.getYearSummery();
      dispatch.home.update(res);
    },

    async getCatalogList(parentId) {
      const res = await server.getCatalogList(parentId);
      const treelist = formatCategory(res);
      dispatch.home.update({ categoryList: treelist, flatCategoryList: res, mainCategoryList: treelist[0].children });
    },
    async getTopCategory() {
      const res = await server.getTopCategory();
      dispatch.home.update({ topCategoryList: res });
    },
  }),
};
