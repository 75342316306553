/*
 * @Date: 2022-05-23 13:28:10
 * @LastEditors: royce
 * @LastEditTime: 2022-06-21 15:40:59
 * @FilePath: /mall-web/src/components/SimpleHeader/index.tsx
 */
import { useEffect, useState } from 'react';
import { useRequest, Link, useHistory } from 'ice';
import { Tabs, Cascader, Input, Button, Space, Image, MenuProps } from 'antd';
import type { SizeType } from 'antd/lib/config-provider/SizeContext';
import type { RadioChangeEvent } from 'antd';
import styles from './index.module.less';
import logo from '@/assets/logo.png';
import {
  Dropdown,
  Menu,
  DropdownButton,
  DropdownPosition,
  DropdownHoverTrigger,
  DropdownContent,
} from 'zent';


export default function SimpleHeader(props) {
  const { title = '结算页', subTitle, icon } = props;
  const [keyWords, setKeyWords] = useState('');
  const history = useHistory();
  const onChange = (value: string[]) => {
    console.log(value);
  };
  //   const [userState, userDispatchers] = appStore.useModel('user');

  useEffect(() => {

  }, []);
  const onClick: MenuProps['onClick'] = ({ key }) => {

  };

  const onHome = async () => {
    history.push('/');
  };


  return (
    <div className={styles.searchContainer}>

      <div className={styles.searchBody}>
        <div className={styles.logoContainer}>
          <Image preview={false} onClick={onHome} className={styles.logo} src={logo} />
          <span className={styles.logoTitle}>工路</span>
          <span className={styles.jsTitle}>{title}</span>
        </div>

      </div>
      {title === '结算页' ? (<div className={styles.tipContainer}>填写并核对订单信息</div>) : null}:
    </div>
  );
}

