import logo from '@/assets/logo.png';
import { CityValue } from '@/models/global';
import appStore from '@/store';
import { addOrUpdateQuery, showToast, showWarning } from '@/utils';
import { ShoppingCartOutlined } from '@ant-design/icons';
import { Badge, Button, Image, Input, Select } from 'antd';
import { useHistory } from 'ice';
import { trim } from 'lodash';
import { useState } from 'react';
import { Popover } from 'zent';
import ShortCartPop from '../ShortCartPop';
import styles from './index.module.less';

const { Option } = Select;
export default function LayoutHeaderSearch() {
  const history = useHistory();
  const { location } = history;
  const [cityVisable, setCityVisable] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [activeWord, setActiveWord] = useState('ABCDE');
  const [userState] = appStore.useModel('user');
  const [globalState, globalDispatchers] = appStore.useModel('global');
  const [goodsListState, goodsListDispatchers] = appStore.useModel('goodslist');
  const { orderType, sortType, keyWords, searchHistory } = goodsListState;
  const { cityName, cityList, shortCartList } = globalState;
  const cityCodes = Object.keys(cityList);
  const selectBefore = (
    <Select defaultValue="goods" className={styles.searchBefore}>
      <Option value="goods">商品</Option>
      {/* <Option value="shop">店铺</Option> */}
    </Select>
  );

  const onChangedCity = (value) => {
    setCityVisable(false);
    globalDispatchers.update({ cityName: value.areaName });
  };
  const onHistoryItem = (item: string) => {
    setShowHistory(false);
    if (location.pathname === '/goods') {
      addOrUpdateQuery('keyWords', item);
      goodsListDispatchers.getGoodsList({ pageIndex: 1, orderType, sortType, keyWords: item });
    } else {
      history.push(`/goods?keyWords=${item}`, { searchfrom: '/' });
    }
    goodsListDispatchers.update({ categorys: [] });
  };
  const onCart = async () => {
    if (!userState.id) {
      showWarning('请登录');
      globalDispatchers.showLogin();
      return;
    }
    history.push('/cart');
  };

  const onSearch = (value) => {
    if (!keyWords) {
      showToast('请输入搜索条件');
      return;
    }
    const trimed = trim(keyWords)
    if (location.pathname === '/goods') {
      addOrUpdateQuery('keyWords', trimed);

      goodsListDispatchers.getGoodsList({ pageIndex: 1, orderType, sortType, keyWords:trimed, minPrice: '', maxPrice: '' });
    } else {
      history.push(`/goods?keyWords=${trimed}`, { searchfrom: '/' });
    }
    goodsListDispatchers.update({ categorys: [], goodCategory: '' });
  };

  const onClearSearchHistory = () => {
    goodsListDispatchers.resetSearchHistory();
  };
  const onChangeSearch = (event) => {
    goodsListDispatchers.update({ keyWords: event.target.value });
  };
  const onHome = async () => {
    history.push('/');
    globalDispatchers.update({ activeMenu: '' });
  };

  return (
    <div className={styles.searchContainer}>
      <div className={styles.searchBody}>
        <div className={styles.logoContainer}>
          <Image
            preview={false}
            onClick={(e) => {
              e.stopPropagation();
              onHome();
            }}
            className={styles.logo}
            src={logo}
          />
          <span className={styles.logoTitle}>工路</span>
        </div>
        <Popover
          visible={cityVisable}
          onVisibleChange={(v) => {
            setCityVisable(v);
          }}
          position={Popover.Position.BottomLeft}
        >
          <Popover.Trigger.Hover>
            <div className={styles.districtSelector}>
              <span className={styles.cityName}>{cityName}</span>
            </div>
          </Popover.Trigger.Hover>
          <Popover.Content>
            <div className={styles.areaView}>
              <div className={styles.currentCityContainer}>
                <div className={styles.cityTitle}>{'当前城市'}</div>
                <div className={styles.currentCity}>{cityName}</div>
              </div>
              <ul className={styles.tabCity}>
                {cityCodes.map((code) => {
                  const cls = code === activeWord ? `${styles.tabCard} ${styles.activeTab}` : styles.tabCard;
                  return (
                    <li
                      key={code}
                      onClick={() => {
                        setActiveWord(code);
                      }}
                      className={cls}
                    >
                      {code}
                    </li>
                  );
                })}
              </ul>
              {cityList[activeWord] &&
                cityList[activeWord].map((items: CityValue[]) => {
                  const cityItem = items[0];
                  return (
                    <div key={cityItem.firstLetter} className={styles.selector}>
                      <div className={styles.letter}>{cityItem.firstLetter}</div>
                      <ul className={styles.items}>
                        {cityItem.areaInfoList.map((city) => {
                          return (
                            <li
                              onClick={() => {
                                onChangedCity(city);
                              }}
                              key={city.areaCode}
                              className={styles.item}
                            >
                              {city.areaName}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  );
                })}
            </div>
          </Popover.Content>
        </Popover>

        <div className={styles.searchInp}>
          <div className={styles.searchRow}>
            <Popover
              onVisibleChange={(v) => {
                setShowHistory(v);
              }}
              visible={showHistory}
              position={Popover.Position.BottomLeft}
            >
              <Popover.Trigger.Click>
                <Input
                maxLength={16}
                  value={keyWords}
                  className={styles.queryInp}
                  addonBefore={selectBefore}
                  placeholder="请输入商品名称"
                  onPressEnter={onSearch}
                  onChange={onChangeSearch}
                  bordered={false}
                  size="large"
                />
              </Popover.Trigger.Click>
              <Popover.Content>
                {searchHistory.length !== 0 ? (
                  <div className={styles.searchHistory}>
                    <div className={styles.historyTitle}>
                      <span>搜索记录</span>
                      <span className={styles.historyTitleRight} onClick={onClearSearchHistory}>
                        清空历史记录
                      </span>
                    </div>
                    <ul className={styles.historyContent}>
                      {searchHistory.map((item) => {
                        return (
                          <li
                            className={styles.historyItem}
                            onClick={() => {
                              onHistoryItem(item);
                            }}
                            key={item}
                            title={item}
                          >
                            {item}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                ) : null}
              </Popover.Content>
            </Popover>
            <Button onClick={onSearch} className={styles.searchBtn} type="primary">
              搜索
            </Button>
            <Popover position={Popover.Position.BottomLeft}>
              <Popover.Trigger.Hover>
                <Button
                  icon={
                    <Badge offset={[-8, 0]} size={'small'} count={shortCartList.length}>
                      <ShoppingCartOutlined style={{ marginRight: 10 }} />
                    </Badge>
                  }
                  onClick={onCart}
                  className={styles.cart}
                  type="default"
                >
                  {'购物车'}
                </Button>
              </Popover.Trigger.Hover>
              <Popover.Content>
                <ShortCartPop />
              </Popover.Content>
            </Popover>
          </div>

          {/* <div>{'热词'}</div> */}
        </div>
      </div>
    </div>
  );
}
