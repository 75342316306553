import { ORDER_COST_TYPE } from './../types';
import { showWarning } from '@/utils';
/*
 * @Date: 2022-04-07 13:52:14
 * @LastEditors: royce
 * @LastEditTime: 2022-06-27 18:09:40
 * @FilePath: /mall-web/src/models/submit.ts
 */
import { InvoiceContent, InvoiceObj, InvoiceTitle, InvoiceType } from '@/components/InvoiceView';
import { AddressItem } from '@/models/address';
import addressServer from '@/services/address';
import orderServer from '@/services/order';
import { IRootDispatch, IRootState } from '@/store';
import { DELIVERY_TYPE, INVOICE_NEED_TYPE, OfflinePayItem, PAY_TYPE } from '@/types';
import { getBaseUrl, showErrorToast, showSuccess, sleep } from '@/utils';
import { history, config } from 'ice';
import { plus, times } from 'number-precision';
import qs from 'qs';

export interface ContractType {
  bestsignContractId: string;
  contractContent: string;
  contractCode: string;
  contractName: string;
  contractStatus: string;
  id: string;
  orderCode: string;
  partybSignedDate: string;
  partyaUrl: string;
  partyaSignedDate: string;
  partyaSigner: string;
  partybSigner: string;
  payUrl: string;
}
export interface SubmitSkuType {
  count: number;
  goodsId: string;
  goodsName: string;
  picJson: string[];
  cartId: string;
}
export interface PayMethodObj {
  payMethod: PAY_TYPE; //支付方式
  payMethodName: string; //支付方式名称
  signType: string; //签约方式
  available: boolean; //是否可用
}
interface OrderParam {
  memberId: string;
  filePath?: string;
  currentAddress: AddressItem;
  skus?: SubmitSkuType[];
  remark?: string;
  accountId?: string;
  enquiryCode?: string;
  invoiceObj?: InvoiceObj;
  totalPrice: number;
  invoiceNeed: string;
  deliveryMethod?: DELIVERY_TYPE;
}

export default {
  state: {
    order: null,
    skus: [],
    cartIds: [],
    noAddress: false,
    contract: {} as any,
    orderCreatestatus: false,
    orderId: '',
    orderCode: '',
    payResultTitle: '支付成功',
    shopId: '',
    shopName: '',
    orderDescribe: '',
    addressList: [],
    partyaUrl: '',
    showContract: false,
    payModal: false,
    offlineAccountId: '',
    offlinePayModal: false,
    showAddressModal: false,
    payUrl: '',
    shopInfo: { shopId: '', status: '', shopName: '', offlineAccounts: [] as OfflinePayItem[] },
    shopAccounts: [],
    payMethodObj: {} as PayMethodObj,
    paymentBillCode: '',
    enquiryAddress: {
      receiveName: '',
      province: '',
      city: '',
      area: '',
      receivePhone: '',
      address: '',
    },
    activeAddress: {
      receiveName: '',
      province: '',
      city: '',
      area: '',
      receivePhone: '',
      addressId: '',
      address: '',
    },
    addressId: '',
    enquiryCode: '',
    payingModalVisable: false,
    postFee: 0,
    serviceFee: 0,
    invoiceNeed: INVOICE_NEED_TYPE.NO,
    goodsTotal: 0,
    deliveryMethod: DELIVERY_TYPE.BY_SELLER,
    loading: false,
    contractModalVisable: false,
    actualPayPrice: 0,
    payMethodsList: [] as PayMethodObj[],
    price: 0, //商品总价（如需开票，该字段已包含税费）
    taxPrice: 0, //商品总价中所包含的税费：
    buyerServiceAmount: 0, //买家平台佣金
    sellerServiceAmount: 0, //卖家平台佣金
  },
  reducers: {
    update(prevState, payload) {
      const state = { ...prevState, ...payload };

      return state;
    },
  },
  effects: (dispatch: IRootDispatch) => ({
    async getAddress({ addressId }: { addressId?: string }, { submit, user }) {
      await sleep(500);
      const res = await addressServer.getAddress({
        memberId: user.id,
        pageIndex: 1,
        pageSize: 100,
      });

      const { rows } = res;
      let data = { addressList: rows } as any;
      if (rows && rows.length) {
        if (addressId) {
          const address = rows.find((item) => item.addressId === addressId);
          if (address) {
            data.activeAddress = address;
          }
        } else {
          data.activeAddress = rows.find((item) => item.def) || rows[0];
        }
      } else {
        showWarning('请添加收货地址');
        dispatch.submit.update({ showAddressModal: true });
      }

      const storeData = JSON.parse(sessionStorage.getItem('submitData') || '{}');
      if (!submit.skus.length && storeData) {
        dispatch.submit.update(storeData);
        data = { ...data, ...storeData };
        console.log(data, 'data', storeData);
        if (storeData.shopId) {
          dispatch.submit.getShopAccount(storeData.shopId);
          dispatch.submit.getShopPayMethods(storeData.shopId);
        }
      }

      dispatch.submit.update(data);
      dispatch.submit.getOrderCost();
    },
    async addAddress(data, { user }) {
      await addressServer.addAddress({
        memberId: user.id,
        ...data,
      });
      console.log('dddd');
      dispatch.submit.getAddress({});
    },
    async getShopAccount(shopId) {
      if (!shopId) {
        return;
      }
      const res = await orderServer.getShopAccount({
        shopId,
      });
      dispatch.submit.update({ shopInfo: res });
    },
    async getShopPayMethods(shopId) {
      if (!shopId) {
        return;
      }
      const res = await orderServer.getShopPayMethod(shopId);
      if (Array.isArray(res)) {
        const aMethod = res.find((item) => item.available);
        console.log(aMethod, 'aMethod');
        const data = { payMethodsList: res, payMethodObj: aMethod };

        // const obj = res.find((item) => item.payMethod !== PAY_TYPE.OFFLINE)
        // if(obj){
        //   data['payMethodObj'] = obj
        // }
        dispatch.submit.update(data);
      } else {
        dispatch.submit.update({ payMethodsList: [], payMethodObj: {} });
        showWarning('没有找可用的支付方式');
      }
    },

    async createContract(data, rootState) {
      try {
        const { user } = rootState;
        const { contractType } = user;
        const res1 = await orderServer.createContract({ contractType, ...data });
        const res2 = await orderServer.createContractSign({ fileId: res1.bestsignContractId });
        const res3 = await orderServer.createContractSignA({
          contractCode: res2,
          contractType,
          memberId: user.id,
          signType: 'PARTY_A',
          returnUrl: `${getBaseUrl()}/signedResult`,
        });
        return Promise.resolve(res3);
      } catch (error) {
        return Promise.reject(error);
      }

      // TODO: 打开签约页面
    },
    async createOrder(
      {
        memberId,
        filePath,
        currentAddress,
        skus,
        invoiceNeed,
        remark,
        enquiryCode,
        totalPrice,
        invoiceObj = {} as any,
        deliveryMethod,
      }: OrderParam,
      rootState: IRootState,
    ) {
      dispatch.submit.update({ loading: true });
      const { submit, user } = rootState;
      const { receivePhone, receiveName, city, area, address, province } = currentAddress;
      const { payMethodObj, shopId, postFee, buyerServiceAmount } = submit;
      const { payMethod, signType } = payMethodObj;
      // 账户信息
      const cartIds: string[] = [];
      const goodsList = skus
        ? skus.map((sku) => {
            const { count, goodsId, goodsName, picJson, cartId } = sku;
            const cover = Array.isArray(picJson) && picJson.length ? picJson[0] : '';
            if (cartId) {
              cartIds.push(cartId);
            }

            return {
              count,
              goodId: goodsId,
              name: goodsName,
              image: cover,
            };
          })
        : [];
      const addressObj = {
        area,
        receiverName: receiveName,
        receiverPhone: receivePhone,
        city,
        province,
        street: '',
        houseNumber: address,
      };
      const orderObj = {
        ...addressObj,
        goodsList,
        postFee,
        totalPrice,
        payMethod,
        remark,
        invoiceNeed,
        deliveryMethod,
        signType,
        tenantId: shopId,
        buyerServiceAmount,
        ...invoiceObj,
      };

      const enquiryOrderObj = {
        enquiryCode,
        payMethod,
        signType,
        remark,
        invoiceNeed,
        tenantId: shopId,
        buyerServiceAmount,
        ...invoiceObj,
      };
      try {
        const orderResult = enquiryCode
          ? await orderServer.createEnquiryOrder(enquiryOrderObj)
          : await orderServer.createOrder(orderObj);
        const { orderId, orderCode, actualPayPrice, paymentBillCode } = orderResult;
        // if (notEnoughGoodsIds && notEnoughGoodsIds.length) {
        //   const names = notEnoughGoodsIds.map((item) => item.name).join(',');

        //   // TODO: 提示库存不足({ message: names, title: '以下商品库存不足' });
        //   return;
        // }
        if (cartIds.length && config.env) {
          // 删除购物车
          dispatch.cart.deleteItem({ cartId: cartIds, memberId: user.id });
          setTimeout(() => {
            dispatch.cart.getCartList();
            dispatch.global.getCartAllList();
          }, 1000);
        }
        dispatch.submit.update({
          orderId,
          orderCode,
          actualPayPrice,
          paymentBillCode,
        });

        // 需要合同
        if (filePath) {
          // 创建合同
          const res3 = await dispatch.submit.createContract({
            orderId,
            orderCode,
            userName: memberId,
            memberId,
            filePath,
          });

          dispatch.submit.update({
            contract: res3,
          });
          return { data: res3 };
        }
        return { data: orderResult };
      } catch (error) {
        dispatch.submit.update({ loading: false });
        return { error };
      }
      // else {
      //
      // }
    },
    async getOrderCost(_, rootState: IRootState) {
      const search = qs.parse(location.search.slice(1));
      const { submit } = rootState;
      const { skus, activeAddress, deliveryMethod, enquiryCode } = submit;
      if (search.from === 'enquiry') {
        try {
          const res = await orderServer.getEnquiryOrderCost({ type: ORDER_COST_TYPE.XJD, id: enquiryCode });
          dispatch.submit.update(res);
        } catch (error) {}
      } else {
        const skuInfos = skus.map((sku: SubmitSkuType) => {
          const { goodsId, count } = sku;
          return {
            skuId: goodsId,
            skuQuantity: count,
          };
        });
        const param = {
          invoiceNeed: true,
          deliveryInfo: {
            addressId: activeAddress.addressId,
            type: deliveryMethod,
          },
          skuInfos: skuInfos,
        };
        try {
          const res = await orderServer.getOrderCost(param);
          dispatch.submit.update(res);
        } catch (error) {
          console.log(error, 'error');
        }
      }
    },
    async createOnline(_, rootState: IRootState) {
      const { submit, user } = rootState;
      const { paymentBillCode, actualPayPrice } = submit;
      const orderPriceFen = times(actualPayPrice, 100);
      const param = {
        billCode: paymentBillCode,
        amount: orderPriceFen,
        remark: orderPriceFen > 50000 ? '超过5W元大额存单的备注' : '',
        memberId: user.id,
        returnUrl: `${getBaseUrl()}/workbench/order`,
      };
      try {
        const res = await orderServer.createOnline(param);
        return res;
      } catch (error) {}
    },
    async submitPayDocument(url, rootState) {
      const { submit, user } = rootState;
      const { shopInfo, orderId, offlineAccountId } = submit;
      const { shopId } = shopInfo;
      const param = {
        orderId,
        accountId: offlineAccountId,
        mobile: user.auth.mobile,
        paymentVoucher: url,
        tenantId: shopId,
        memberId: user.id,
      };
      try {
        await orderServer.submitPayDocument(param);
        dispatch.submit.update({ offlinePayModal: false, loading: false });
        showSuccess('上传成功');
        setTimeout(() => {
          history && history.push('/workbench/order');
        }, 1000);
      } catch (error) {
        showErrorToast(error.message);
      }
    },
  }),
};
