import { getBaseUrl } from '@/utils';
import { showSuccess } from './../utils/index';
/*
 * @Date: 2022-05-24 15:47:16
 * @LastEditors: royce
 * @LastEditTime: 2022-06-20 16:53:10
 * @FilePath: /mall-web/src/models/address.ts
 */
import { IRootDispatch, IRootState } from '@/store';
import { LabelValueType, PayAccountType, RefundAccountType } from '@/types';
import server from '../services/payAccount';

const mockTypeList = [
  {
    name: '支付宝', //类别名称（展示用）
    code: 'ALIPAY', //类别代码（传入后台时，请使用 code）
    signType: [
      {
        name: '支付宝记账本', //签约类型名称
        code: 'alipay_account', //签约类型代码
      },
    ], //可用签约类型
  },
  {
    name: '银行卡', //类别名称（展示用）
    code: 'bank_card', //类别代码（传入后台时，请使用 code）
  },
];
const mockAccountList = [
  {
    id: '120b69ac-4770-4ec1-866e-b5bd80531450', //线下账户ID
    type: 'bankCard', //类型 企业支付宝 aliPay  企业银行卡 bankCard
    accountName: '李天', //户名
    accountNo: '23232434343', //账号
    bankName: '中国银行', //开户行
    bankCode: 'ICBC', //开户行编码
  },
];
export interface PAY_ACCOUNT_CODE {
  name: string;
  code: string;
}

export default {
  state: {
    accountALiList: [] as PayAccountType[],
    accountBankList: [] as PayAccountType[],
    refundAliList: [

    ] as RefundAccountType[],
    refundBankList:[ {
      id: '120b69ac-4770-4ec1-866e-b5bd80531450', //线下账户ID
      type: 'bankCard', //类型 企业支付宝 aliPay  企业银行卡 bankCard
      accountName: '李天', //户名
      accountNo: '23232434343', //账号
      bankName: '中国银行', //开户行
      bankCode: 'ICBC', //开户行编码
    }]  as RefundAccountType[],
    accountTypes: [],
    shouldReturnAll:false,
    bankList: [] as LabelValueType[],
  },
  reducers: {
    update(prevState, payload) {
      return { ...prevState, ...payload };
    },
  },
  effects: (dispatch: IRootDispatch) => ({
    async getAccountList(data, { user }: IRootState) {
      const res = await server.getAccountList();
      if (Array.isArray(res)) {
        const accounts = res.map((item, index) => {
          return { ...item, key: item.accountId };
        })
        dispatch.payAccount.update({
          accountALiList:accounts.filter(item=>item.accountTypeCode ===
            "aliPay"),
          accountBankList:accounts.filter(item=>item.accountTypeCode ===
            "bankCard")
        })
      }
    },
    async getBankList(data, { user }: IRootState) {
      const res = await server.getBankList();
      if (Array.isArray(res)) {
        dispatch.payAccount.update({
          bankList: res.map((item) => {
            return { label: item.bankName, value: item.bankCodeInternal };
          }),
        });
      } else {
        dispatch.payAccount.update({ bankList: [] });
      }
    },
    async getAccountTypes(data, { user }: IRootState) {
      const res = await server.getAccountTypes();
      const types = res.map((k) => {
        return { label: k.name, value: k.code, disabled: k.exsisting };
      });
      dispatch.payAccount.update({ accountTypes: types });
    },
    async addAccount(data, { payAccount }: IRootState) {
      const bank = payAccount.bankList.find((item: LabelValueType) => {
        return item.value === data.bankCode;
      });
      await server.addAccount({ ...data, bankOfDeposit: bank?.label });
      dispatch.payAccount.getAccountList();
      dispatch.payAccount.getAccountTypes();
      showSuccess('添加成功');
    },

    async deleteAccount(data, { user }: IRootState) {
      await server.deleteAccount(data);
      dispatch.payAccount.getAccountList();
      dispatch.payAccount.getAccountTypes();
      showSuccess('删除成功');
    },
    // 获取线下收款列表
    async getRefundBankList() {
      const res = await server.getAccountRefundList('bankCard');
      if (Array.isArray(res.rows)) {
        const list =  res.rows.map((item, index) => {
          return { ...item, key: item.id };
        })
        dispatch.payAccount.update({
          refundBankList:list
        });
      } else {
        dispatch.payAccount.update({ refundAccountList: [] });
      }
    },
    async getRefundALiList() {
      const res = await server.getAccountRefundList('aliPay');
      if (Array.isArray(res.rows)) {
        const list =  res.rows.map((item, index) => {
          return { ...item, key: item.id };
        })
        dispatch.payAccount.update({
          refundAliList:list
        });
      } else {
        dispatch.payAccount.update({ refundAliList: [] });
      }
    },
    // 新增线下收款
    async addRefundAccount(data, { payAccount }: IRootState) {
      const bank = payAccount.bankList.find((item: LabelValueType) => {
        return item.value === data.bankCode;
      });
     await server.addRefundAccount({...data,bankName: bank?.label})
      dispatch.payAccount.getRefundAccount(data.type)
      showSuccess('添加成功');
    },
    async getRefundAccount(type) {
      if(type === 'bankCard'){
        dispatch.payAccount.getRefundBankList();
      }
      if(type === 'aliPay'){
        dispatch.payAccount.getRefundALiList();
      }
    },
    // 删除线下收款
    async deleteRefundAccount(data, { user }: IRootState) {
      await server.deleteRefundAccount(data.id);
      dispatch.payAccount.getRefundAccount(data.type)
      showSuccess('删除成功');
    },
    async signAccount(data, { user }: IRootState) {
      try {
        const res = await server.signAccount({ ...data, memberId: user.id, returnUrl: `${getBaseUrl()}/payAccount` });
        return res;
      } catch (error) {
        dispatch.payAccount.getAccountList();
      }
    },
  }),
};
