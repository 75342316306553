/*
 * @Date: 2022-04-07 13:52:14
 * @LastEditors: royce
 * @LastEditTime: 2022-06-27 10:05:31
 * @FilePath: /mall-web/src/models/goodslist.ts
 */
import { CategoryType, FilterItem } from './../types';
import server from '@/services/goods';
import catalog from '@/services/catalog';
import { DETAIL_BUY_TYPE, GoodListItem, GOODS_SORT_TYPE, HOME_TYPE } from '@/types';
import _ from 'lodash';
import { STORE_KEYS } from '@/const';

const PAGE_SIZE = 10;
export interface SearchState {
  goodsList: GoodListItem[];
  filterCategoryList: Object[];
  isShowSearch: boolean;
  pageIndex: number;
  orderType: HOME_TYPE;
  sortType: GOODS_SORT_TYPE;
  total: number;
  categorys: CategoryType[];
  keyWords: string;
  goodCategory: string;
  emptyKeyword: string;
  searchHistory: string[];
  searchParams: FilterItem[];
}
export default {
  state: {
    keyWords: '',
    goodsList: [] as GoodListItem[],
    filterCategoryList: [],
    isShowSearch: false,
    pageIndex: 1,
    orderType: HOME_TYPE.LAST_UPDATED_ON,
    sortType: GOODS_SORT_TYPE.DESC,
    total: 0,
    categorys: [],
    searchParams: [],
    goodCategory: '',
    emptyKeyword: '',
    minPrice: '',
    maxPrice: '',
    searchHistory: JSON.parse(sessionStorage.getItem(STORE_KEYS.SEARCH_HISTORY) || '[]'),
  },
  reducers: {
    update(prevState, payload) {
      if (payload.categorys) {
        sessionStorage.setItem('categorys', JSON.stringify(payload.categorys));
      }
      return { ...prevState, ...payload };
    },
    addSearchParam(prevState, payload) {
      const { searchParams } = prevState;
      return { ...prevState, searchParams: [...searchParams, payload] };
    },
    deleteSearchParam(prevState, payload) {
      const { searchParams } = prevState;
      return { ...prevState, searchParams: searchParams.filter((item) => item.id !== payload) };
    },
  },
  effects: (dispatch) => ({
    async getGoodsList(data, { goodslist }) {
      const param = _.pickBy({
        pageSize: PAGE_SIZE,
        channl: 'APP',
        filterHeader: true,
        ...data,
      });
      try {
        const res = await server.getHomeGoodsList(param);
        const totalCount = res.total;
        const state = { goodsList: res.rows, total: totalCount, ...data, emptyKeyword: data.keyWords || '' };

        if (data.keyWords) {
          state.searchHistory = Array.from(new Set([data.keyWords, ...goodslist.searchHistory]));
          sessionStorage.setItem(STORE_KEYS.SEARCH_HISTORY, JSON.stringify(state.searchHistory));
        }
        dispatch.goodslist.update(state);
      } catch (error) {
        dispatch.goodslist.update({ ...data, emptyKeyword: data.keyWords || '' });
      }
    },
    async getCatalogList(parentId) {
      const res = await catalog.getCatalogList(parentId);
      console.log(res, 'catalog');
      dispatch.goods.update({ preCategoryList: res.rows });
    },
    resetSearchHistory() {
      dispatch.goodslist.update({ searchHistory: [] });
      sessionStorage.setItem(STORE_KEYS.SEARCH_HISTORY, '[]');
    },
  }),
};
