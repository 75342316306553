/*
 * @Date: 2022-04-07 13:52:14
 * @LastEditors: royce
 * @LastEditTime: 2022-06-24 15:24:22
 * @FilePath: /mall-web/src/pages/order-detail/index.tsx
 */

import appStore from '@/store';
import {
  commonEnumDes,
  ORDER_STATUS,
  ORDER_TYPE,
  PAY_TYPE,
  RefundAccountType,
  REFUND_ACCOUNT_TYPE,
  REFUND_TYPE,
} from '@/types';
import { getBase64, scrollToTop, showWarning, uploadFormData } from '@/utils';

import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Select,
  Spin,
  Table,
  Tooltip,
  Upload,
  UploadFile,
  UploadProps,
} from 'antd';
import { getSearchParams, useHistory } from 'ice';
import _, { isArray } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import './index.less';
import { MAX_FILE_COUNT, MIN_SERVICE_FEE } from '@/const';
import global from '@/services/global';
import RefundGoods from '@/components/RefundGoods';
import { FromTitleView, RefundMoneyInfoView, ShopInfoView, TitleView } from '@/components/RefundItemView';
import { RefundCancelReason } from '@/models/refund';
import { PlusOutlined, QuestionCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { minus, plus } from 'number-precision';
import { LineH } from '@/components/Line';
import { RcFile } from 'antd/lib/upload';
import { aliPayRefundColumns, bankRefundColumns } from '@/elements';
import OfflineAccountAdd from '@/components/OfflineAccountAdd';

const { Option } = Select;

export default function OrderRefund() {
  const history = useHistory();
  const { id } = getSearchParams();
  console.log(getSearchParams(), 'getSearchParams');
  const [form] = Form.useForm();
  const refundAccountType = Form.useWatch('refundAccountType', form);
  const [refundAccount, setRefundAccount] = useState<RefundAccountType>();
  const applyRefundAmount = Form.useWatch('applyRefundAmount', form);
  const buyerApplyServiceAmount = Form.useWatch('buyerApplyServiceAmount', form);
  const refundType = Form.useWatch('refundType', form);
  const totalMoney = useMemo(() => {
    return plus(applyRefundAmount || 0, buyerApplyServiceAmount || 0);
  }, [applyRefundAmount, buyerApplyServiceAmount]);
  const [imageFiles, setImageFiles] = useState<UploadFile[]>([]);
  const [attachment, setAttachment] = useState<UploadFile[]>([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [userState] = appStore.useModel('user');
  const [refundState, refundActions] = appStore.useModel('refund');
  const isLoading = appStore.useModelEffectsLoading('refund').getDetailOrder;
  const { reasons, refundOrder, shopInfo } = refundState;
  const { status, orderType, payMethod, goodsList } = refundOrder;

  const canReturn = status === ORDER_STATUS.POSTED_OUT;
  const refundTypeOrigin = refundState.refundType;
  const payMoney = _.defaultTo(refundOrder.actualPayPrice, 0);
  console.log(payMoney, 'payMoneypayMoneypayMoneypayMoney');
  const [payState, payActions] = appStore.useModel('payAccount');
  const { accountTypes, refundAliList, refundBankList, shouldReturnAll } = payState;

  const platPriceTotal = _.defaultTo(refundOrder.platPriceTotal, 0);
  const orderPrice = minus(payMoney, platPriceTotal);
  const hasFee = orderType === ORDER_TYPE.CentralizedPurchase;
  useEffect(() => {
    scrollToTop();
    console.log(userState.id, 'userState.id', id);
    if (userState.id && id) {
      refundActions.getDetailOrder(id);
      refundActions.getCancelOrderReason();
      payActions.getRefundBankList();
      payActions.getRefundALiList();
      payActions.getBankList();
      payActions.getAccountTypes();
    }
  }, []);
  // useEffect(() => {
  //   form.setFieldValue('applyRefundAmount', _.defaultTo(orderPrice, 0));
  //   form.setFieldValue('buyerApplyServiceAmount', _.defaultTo(refundOrder.platPriceTotal, 0));
  // }, [refundOrder.actualPayPrice, refundOrder.platPriceTotal]);

  const validGoods = async () => {
    form.getFieldValue('');
    if (refundType === REFUND_TYPE.RETURN_REFUND) {
      const item = refundOrder.goodsList.find((goods) => goods.check);
      if (!item) {
        return Promise.reject('请选择退货商品');
      }
      const selected = refundOrder.goodsList.find((goods) => goods.check && !goods.selectCount);
      if (selected) {
        return Promise.reject('请选择输入退货数量');
      }
      const goods = refundOrder.goodsList.find((item) => item.check && item.selectCount! > item.count);
      if (goods) {
        return Promise.reject(`商品${goods?.goodsName}退货数量不能超过购买数量`);
      }
    }
    return Promise.resolve();
  };

  const onSubmitRefund = async () => {
    if (payMethod === PAY_TYPE.OFFLINE) {
      if (!refundAccount) {
        showWarning('请选择线下退款账号');
        return;
      }

      if (refundAccountType !== refundAccount?.type) {
        showWarning('请选择线下退款账号');
        return;
      }
    }
    // console.log(refundAccount, refundAccountType)
    const { orderId } = refundState.refundOrder;
    try {
      await validGoods();
      const goods = goodsList
        .filter((item) => item.check)
        .map((e) => {
          return { orderGoodsId: e.orderGoodsId, amount: e.selectCount };
        });
      const imgs = imageFiles.map((item) => {
        return {
          fileName: item.name,
          fileUrl: item.response,
        };
      });
      const attachments = attachment.map((item) => {
        return {
          fileName: item.name,
          fileUrl: item.response,
        };
      });

      await form.validateFields();
      const data = form.getFieldsValue();
      const { refundType, receivedGoods, applyRefundAmount, buyerApplyServiceAmount = 0, reasonType, reason } = data;
      // console.log('payMethod',payMethod !== PAY_TYPE.OFFLINE);
      if (payMethod !== PAY_TYPE.OFFLINE && platPriceTotal) {
        const isOverMoney = minus(platPriceTotal, buyerApplyServiceAmount) < MIN_SERVICE_FEE;
        if (isOverMoney) {
          showWarning('平台服务费不足，请重新填写需退服务费');
          return;
        }
      }
      refundActions.submitRefund({
        orderId,
        refundType,
        receivedGoods,
        applyRefundAmount,
        reasonType,
        reason,
        buyerApplyServiceAmount,
        goods: refundType === REFUND_TYPE.RETURN_REFUND ? goods : [],
        imgs,
        attachments,
        offlineAccountId: refundAccount?.id,
      });
    } catch (error) {
      if (typeof error === 'string') {
        showWarning(error);
      }
      console.log(error, 'errorerrorerror');
    }
  };
  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
  };

  const handleUpload = async (option) => {
    try {
      const file = option.file as File;

      const isLt2M = file.size / 1024 / 1024 < 40;
      if (!isLt2M) {
        showWarning(`文件大小不能超过${MAX_FILE_COUNT}MB`);
        option.onError();
        return;
      }
      const param = uploadFormData(file);
      console.log(param, 'param');
      const filePath = await global.uploadFile(param);
      console.log(filePath, 'result', option);
      // setFilePath(result);
      option.onSuccess(filePath);
      // purchaseActions.updateContractForShop({ shopId, filePath });
    } catch (error) {
      console.log(error, '<Upload ');
      option.onError();
    }
  };
  const handleUploadAttachment = async (option) => {
    try {
      const file = option.file as File;

      const isLt2M = file.size / 1024 / 1024 < 40;
      if (!isLt2M) {
        showWarning(`文件大小不能超过${MAX_FILE_COUNT}MB`);
        option.onError();
        return;
      }
      const param = uploadFormData(file);
      console.log(param, 'param');
      const filePath = await global.uploadFile(param);
      console.log(filePath, 'result', option);
      // setFilePath(result);
      option.onSuccess(filePath);
      // purchaseActions.updateContractForShop({ shopId, filePath });
    } catch (error) {
      console.log(error, '<Upload ');
      option.onError();
    }
  };

  const handleChangeAttachment: UploadProps['onChange'] = (data) => {
    setAttachment(data.fileList);
  };
  const handleChange: UploadProps['onChange'] = (data) => {
    setImageFiles(data.fileList);
  };
  if (isLoading) {
    return <Spin />;
  }
  const spans = [4, 4, 4, 5, 7];

  const row1 = [
    { title: '退货/款原因' },
    { title: '退款金额' },
    { title: '需退服务费' },
    { title: '退款总额' },
    { title: '问题描述' },
  ];
  const row2 = [{ title: '凭证照片' }, { title: '附件' }];
  const defaultOfflineAccount = refundBankList[0] || refundAliList[0];

  return (
    <div className="order_refund_container">
      {refundState.refundOrder && (
        <Form
          form={form}
          initialValues={{
            receivedGoods: false,
            refundType: refundTypeOrigin,
            buyerApplyServiceAmount: 0,
            refundAccountType: defaultOfflineAccount ? defaultOfflineAccount.type : REFUND_ACCOUNT_TYPE.UNION_ACCOUNT,
          }}
        >
          <div className="top-header">
            <FromTitleView title="退款类型" />
            <div className="form-view">
              <Form.Item
                style={{ marginTop: 20 }}
                rules={[{ required: true, message: '请选择退款类型' }]}
                name={'refundType'}
              >
                <Radio.Group>
                  <Radio value={REFUND_TYPE.ONLY_REFUND}> {commonEnumDes[REFUND_TYPE.ONLY_REFUND]} </Radio>
                  <Radio disabled={!canReturn} value={REFUND_TYPE.RETURN_REFUND}>
                    {commonEnumDes[REFUND_TYPE.RETURN_REFUND]}
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </div>
            {refundType === REFUND_TYPE.ONLY_REFUND && (
              <>
                <FromTitleView title="物流状态" />
                <div className="form-view">
                  <Form.Item
                    style={{ marginTop: 20 }}
                    name={'receivedGoods'}
                    rules={[{ required: true, message: '请选择物流状态' }]}
                  >
                    <Radio.Group>
                      <Radio value={false}>未收到货</Radio>
                      <Radio value={true}>已收到货</Radio>
                    </Radio.Group>
                  </Form.Item>
                </div>
              </>
            )}
          </div>
          <div className="info-view">
            <TitleView title="商品信息" />
            <RefundGoods shouldReturnAll={shouldReturnAll} goodsList={goodsList} refundType={refundType} />
            <LineH />
            <TitleView title="金额信息" />
            <RefundMoneyInfoView item={refundOrder} />
            <LineH />
            <TitleView title="店铺信息" />
            <ShopInfoView shopInfo={shopInfo} />
          </div>
          <div className="action-view">
            <TitleView title="退款操作" />
            <div className="refund-actions-container">
              <Row justify="center" align="middle" className="header">
                {row1.map((item, index) => {
                  return (
                    <Col key={item.title} className="col required" span={spans[index]}>
                      {item.title}
                    </Col>
                  );
                })}
              </Row>
              <Row justify="center" align="middle">
                <Col className="value" span={spans[0]}>
                  <Form.Item name={'reasonType'} rules={[{ required: true, message: '请选择退款原因' }]}>
                    <Select placeholder="请选择退款原因" style={{ width: 220 }}>
                      {reasons.map((reason: RefundCancelReason) => {
                        return (
                          <Option key={reason.code} value={reason.code}>
                            {reason.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col className="value" span={spans[1]}>
                  <Form.Item
                    rules={[
                      { required: true, message: '请输入退款金额' },
                      { pattern: new RegExp(/^(\d+)(.\d{0,2})?$/, 'g'), message: '请输入正确的金额' },
                      {
                        validator: (rule: any, value: string) => {
                          if (parseFloat(value) > orderPrice) {
                            return Promise.reject(`退款金额不能大于${orderPrice.toFixed(2)}`);
                          }

                          return Promise.resolve();
                        },
                      },
                    ]}
                    name={'applyRefundAmount'}
                  >
                    <InputNumber disabled={shouldReturnAll} placeholder="请输入退款金额" className="input" />
                  </Form.Item>
                </Col>
                <Col className="value" span={spans[2]}>
                  <Form.Item
                    name={'buyerApplyServiceAmount'}
                    rules={[
                      {required:!!platPriceTotal, message:'请输入需退服务费'},
                      { pattern: new RegExp(/^(\d+)(.\d{0,2})?$/, 'g'), message: '请输入正确的金额' },
                      // {
                      //   validator: totalValidor,
                      // },
                      {
                        validator: (rule: any, value: string) => {
                          if (parseFloat(value) > platPriceTotal) {
                            console.log(
                              value,
                              '需退服务费不能大于订单服务费',
                              parseFloat(value) > platPriceTotal,
                              platPriceTotal,
                            );
                            return Promise.reject(`需退服务费不能大于${platPriceTotal.toFixed(2)}`);
                          }

                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <InputNumber
                      className="input"
                      placeholder="请输入需退服务费"
                      disabled={!hasFee || shouldReturnAll}
                    />
                  </Form.Item>
                </Col>
                <Col className="value" span={spans[3]}>
                  <Form.Item name={'totalMoney'}>
                    <Tooltip title="退款金额+需退服务费">
                      <span className="price">
                        ¥{totalMoney.toFixed(2)} <QuestionCircleOutlined style={{ color: '#999' }} />
                      </span>
                    </Tooltip>
                    {totalMoney > payMoney ? (
                      <div style={{ color: '#ff4d4f' }}>{`退款总额不能大于实付金额${payMoney.toFixed(2)}`}</div>
                    ) : null}
                  </Form.Item>
                </Col>
                <Col className="value" span={spans[4]}>
                  <Form.Item name={'reason'}>
                    <Input.TextArea showCount maxLength={150} style={{ width: 250 }} placeholder="问题描述" rows={2} />
                  </Form.Item>
                </Col>
              </Row>
              <Row align="middle" className="header">
                <Col className="col-pic" span={8}>
                  {row2[0].title}
                </Col>
                <Col className="col-attachment" span={4}>
                  {row2[1].title}
                </Col>
              </Row>
              <Row align="middle">
                <Col className="value1" span={8}>
                  <Form.Item valuePropName="fileList">
                    <Upload
                      fileList={imageFiles}
                      listType="picture-card"
                      maxCount={3}
                      onChange={handleChange}
                      onPreview={handlePreview}
                      customRequest={handleUpload}
                      accept={'image/*'}
                    >
                      {imageFiles.length < 3 ? (
                        <div style={{ color: '#999' }}>
                          <PlusOutlined />
                          <div style={{ marginTop: 8 }}>上传凭证</div>
                          <div>{'(最多上传3张)'}</div>
                        </div>
                      ) : null}
                    </Upload>
                  </Form.Item>
                </Col>
                <Col className="value1" span={4}>
                  <Form.Item valuePropName="attachment">
                    <Upload
                      accept={'.xls,.doc,.txt,.pdf,.xlsx,.zip,.rar,.docx'}
                      fileList={attachment}
                      maxCount={1}
                      onChange={handleChangeAttachment}
                      customRequest={handleUploadAttachment}
                    >
                      <Button icon={<UploadOutlined />}>点击上传附件</Button>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </div>
          {payMethod === PAY_TYPE.OFFLINE && (
            <div className="info-view">
              <TitleView title="线下收款信息" />
              <div style={{ paddingLeft: 71, paddingRight: 71, paddingBottom: 30 }}>
                <Form.Item
                  style={{ marginTop: 20 }}
                  rules={[{ required: true, message: '线下收款账号' }]}
                  name={'refundAccountType'}
                >
                  <Radio.Group>
                    <Radio value={REFUND_ACCOUNT_TYPE.UNION_ACCOUNT}> {'企业银行卡'} </Radio>
                    <Radio value={REFUND_ACCOUNT_TYPE.ALIPAY_ACCOUNT}> {'企业支付宝'} </Radio>
                  </Radio.Group>
                </Form.Item>
                {refundAccountType === REFUND_ACCOUNT_TYPE.ALIPAY_ACCOUNT && (
                  <Table
                    rowSelection={{
                      type: 'radio',
                      onSelect: (record) => {
                        setRefundAccount(record);
                      },
                    }}
                    pagination={false}
                    columns={aliPayRefundColumns}
                    dataSource={refundAliList}
                  />
                )}
                {refundAccountType === REFUND_ACCOUNT_TYPE.UNION_ACCOUNT && (
                  <Table
                    rowSelection={{
                      type: 'radio',
                      onSelect: (record) => {
                        setRefundAccount(record);
                      },
                    }}
                    pagination={false}
                    columns={bankRefundColumns}
                    dataSource={refundBankList}
                  />
                )}
                {/* <div className="offline-account-title">
              <OfflineAccountAdd />
            </div> */}
              </div>
            </div>
          )}
          <div className="bottom-action-view">
            <Button className="action_btn" size="middle" type="primary" onClick={onSubmitRefund}>
              提交
            </Button>
          </div>
        </Form>
      )}
      <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </div>
  );
}
