/*
 * @Date: 2022-05-24 15:47:16
 * @LastEditors: royce
 * @LastEditTime: 2022-06-20 16:53:10
 * @FilePath: /mall-web/src/models/address.ts
 */
import server from '../services/purchase';
import { PurchaseItem, PurchaseOrderDetailType, PURCHASE_ORDER_STATUS } from './../types';
import _, { isArray } from 'lodash';
import qs from 'qs';
import { PurchaseStatusType } from './../types';
import { IRootState, IRootDispatch } from '@/store';
import { showSuccess, showWarning } from '@/utils';
import order from '@/services/order';

export const ALL_PURCHASE__STATUS = undefined;
export const PURCHASE_PAGE_SIZE = 10;
export default {
  state: {
    statusList: [] as PurchaseStatusType[],
    purchaseList: [] as PurchaseItem[],
    purchaseDetail: {
      totalGoodsAmount: null,
      totalPostFee: null,
      totalCommisionFee: null,
      totalAmount: null,
      purchaseOrder: {},
      detail: [],
    } as any as PurchaseOrderDetailType,
    status: undefined,
    startTime: '',
    endTime: '',
    total: 0,
    pageIndex: 1,
    pOrderId: '',
    orderType: '',
    orderBy: '',
    contractForShop: {},
    payTypeForShop: {},
    orderStatus: '',
  },
  reducers: {
    update(prevState, payload) {
      return { ...prevState, ...payload };
    },
    updateContractForShop(prevState, { shopId, filePath }) {
      prevState.contractForShop[shopId] = filePath;
    },
    updatePayTypeForShop(prevState, { shopId, payObj }) {
      prevState.payTypeForShop[shopId] = payObj;
    },
  },
  effects: (dispatch: IRootDispatch) => ({
    async getPurchaseListStatus(data, { user }: IRootState) {
      const res = await server.getPurchaseStatusList();
      if (isArray(res)) {
        dispatch.purchaseList.update({
          statusList: [{ label: '全部采购单', value: '' }].concat(
            res.map((item) => {
              return { label: item.statusName, value: item.status };
            }),
          ),
        });
      }


    },
    async getPurchaseList(data, { user, purchaseList }: IRootState) {
      try {
        const { pOrderId } = purchaseList;
        if (pOrderId) {
          data['pOrderId'] = pOrderId;
        }
        console.log(data, 'getpurchaseList');
        const res = await server.getPurchaseOrderList({ ...data, pageSize: PURCHASE_PAGE_SIZE });
        if (res && isArray(res.rows)) {
          const { status, pageIndex } = data;
          const query = { status, pageIndex };
          setTimeout(() => {
            dispatch.purchaseList.update({ purchaseList: res.rows, total: res.total, ...query });
          }, 300);

          if (location.pathname.indexOf('purchase-detail') < 0) {
            history && history.pushState({}, '', `${location.pathname}?${qs.stringify(query)}`);
          }
        } else {
          dispatch.purchaseList.update({ purchaseList: [], total: 0 });
        }
      } catch (error) {
        console.log(error, 'error');
        dispatch.purchaseList.update({ purchaseList: [], total: 0 });
      }
    },
    async getPurchaseDetail(pOrderId, { user, purchaseList }) {
      const { orderStatus } = purchaseList;
      const res = await server.getPurchaseOrderDetail(
        pOrderId,
        orderStatus !== PURCHASE_ORDER_STATUS.CANCELED && orderStatus !== PURCHASE_ORDER_STATUS.REJECTED,
      );
      console.log(res, 'res');
      dispatch.purchaseList.update({
        purchaseDetail: res,
        orderStatus: res.purchaseOrder.status,
      });
    },
    // 拒绝报价
    async rejectPurchase(id, { user, purchase }) {
      const { pageIndex, status } = purchase;
      await server.rejectPurchaseOrder(id);
      dispatch.purchaseList.getPurchaseDetail(id);
      dispatch.purchaseList.getPurchaseList({ pageIndex, status });
      showSuccess('报价已拒绝');
    },
    // 接受报价
    async acceptPurchase(id, { user, purchase }) {
      const { pageIndex, status } = purchase;
      await server.acceptPurchaseOrder(id);
      dispatch.purchaseList.getPurchaseDetail(id);
      dispatch.purchaseList.getPurchaseList({ pageIndex, status });
      // showSuccess('报价已接受');
    },
    // 取消报价
    async cancelPurchase(id, { user, purchase }) {
      const { pageIndex, status } = purchase;
      await server.cancelPurchaseOrder(id);
      dispatch.purchaseList.getPurchaseDetail(id);
      dispatch.purchaseList.getPurchaseList({ pageIndex, status });
      showSuccess('采购单已取消');
    },
    // 采购下单
    async onPurchaseOrder({ pOrderId, invoiceObj }, { purchaseList, user }: IRootState) {
      const { contractForShop, purchaseDetail, payTypeForShop, pageIndex, status } = purchaseList;

      // for (const [key, value] of Object.entries(contractForShop)) {
      //   console.log(`${key}: ${value}`);
      //   orderInfo.push({ shopid: key, contractUrl: value });
      // }
      const errorShop: string[] = [];
      const orderInfo = purchaseDetail.detail.map((item) => {
        const payobj = _.defaultTo(payTypeForShop[`${item.shopId}`], null);
        if (!item.shopName) {
          return {};
        }
        if (!payobj) {
          errorShop.push(item.shopName);
          return {};
        }
        return {
          shopId: item.shopId,
          contractUrl: contractForShop[`${item.shopId}`] || '',
          payInfo: {
            payMethod: payTypeForShop[`${item.shopId}`].payMethod,
            signType: payTypeForShop[`${item.shopId}`].signType,
          },
        };
      });
      console.log(errorShop, 'errorShop', orderInfo);
      if (errorShop.length) {
        showWarning(`请选择的支付方式 店铺:${errorShop[0]}`);
        return;
      }

      const submitData = {
        pOrderId, //集采采购单ID
        invoice: {},
        orderInfo: orderInfo.filter((item) => item.shopId),
      };
      if (purchaseDetail.purchaseOrder.invoiceNeed) {
        const {
          invoiceContent,
          invoiceTitleType,
          invoiceType,
          invoiceTaxNo,
          checkTakerTelephoneNo,
          name,
          checkTakerEmail,
        } = invoiceObj;

        submitData['invoice'] = {
          invoiceTitleType, //personal ; company
          invoiceTitle: name,
          invoiceType, //增值税普通发票 ：vat_ordinary；增值税专用发票： vat_special
          invoiceTaxpayerIdentifyNo: invoiceTaxNo, //税号；当抬头类型人个人时，非必填
          invoiceContent: invoiceContent, //detail ;
          invoiceTickerCollectorPhone: checkTakerTelephoneNo, //收票人电话
          invoiceTickerCollectorMail: checkTakerEmail, //收票人邮箱
        };
      }
      console.log(submitData, 'submitData');
      await dispatch.purchaseList.acceptPurchase(pOrderId);
      await server.purchaseOrder(submitData);
      showSuccess('下单成功');
      dispatch.purchaseList.getPurchaseDetail(pOrderId);
      dispatch.purchaseList.getPurchaseList({ pageIndex, status });
      // //批量绑定合同到订单
      // const tasks = res.map((item) => {
      //   const { orderId, orderCode, shopId } = item;
      //   return order.createContract({
      //     orderId,
      //     orderCode,
      //     type: 'PURCHASE_ORDER',
      //     contractType,
      //     userName: user.id,
      //     memberId: user.id,
      //     filePath: purchaseList.contractForShop[shopId],
      //   });
      // });
      // const res1 = Promise.all(tasks);
    },
    //
  }),
};
