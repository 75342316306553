/*
 * @Date: 2022-04-07 13:52:14
 * @LastEditors: royce
 * @LastEditTime: 2022-06-16 13:44:25
 * @FilePath: /mall-web/src/services/order.ts
 */

import { config, request } from 'ice';
import qs from 'qs';

const {
  BASE_URL,
} = config;

export default {
  /**
   *创建订单
   * @param orderId
   */
  async createOrder(data) {
    return await request.post(`${BASE_URL}/shm/order`, data);
  },
  async createEnquiryOrder(data) {
    return await request.post(`${BASE_URL}/shm/enquiry/order`, data);
  },
  async createContract(data) {
    return await request.post(`${BASE_URL}/shm/contract/file`, data);
  },
  async getContract(data) {
    const url = `${BASE_URL}/shm/contract?${qs.stringify(data)}`;
    return await request.get(url);
  },
  async createContractSign(data) {
    const { fileId } = data;
    return await request.post(`${BASE_URL}/shm/contract/${fileId}`, data);
  },
  async createContractSignA(data) {
    const { memberId } = data;
    return await request.post(`${BASE_URL}/shm/contract/${memberId}/sign`, data);
  },
  async  getOrderListApi(data) {
    return await request.get(`${BASE_URL}/shm/order?${qs.stringify(data)}`);
  },
  async  orderSearch(data) {
    return await request.get(`${BASE_URL}/shm/searchOrder?${qs.stringify(data)}`, data);
  },
  async orderCancelReason() {
    return await request.get(`${BASE_URL}/shm/order/cancelreason`);
  },
  async  orderCancel(data) {
    const { orderId } = data;
    return await request.post(`${BASE_URL}/shm/order/${orderId}/cancel`, data);
  },
  async  orderPayed(data) {
    const { orderCode } = data;
    return await request.post(`${BASE_URL}/shm/pay/${orderCode}/order`, data);
  },
  async orderReceive(data) {
    const { orderId } = data;
    const headers = { ContentType: 'application/x-www-form-urlencoded' };
    return await request.post(`${BASE_URL}/shm/order/${orderId}/receive`, qs.stringify(data), { headersProps: headers });
  },
  async  orderUpdate(data) {
    const { orderId } = data;
    return await request.post(`${BASE_URL}/shm/order/${orderId}/update`, data);
  },
  async  orderDelete(orderId) {
    return await request.delete(`${BASE_URL}/shm/order/${orderId}/delete`, { orderId });
  },
  async  orderEva(data) {
    const { memberId } = data;
    return await request.post(`${BASE_URL}/shm/comments/${memberId}/create`, data);
  },
  async submitPayDocument(data) {
    const url = `${BASE_URL}/shm/pay/voucher`;
    return await request.post(url, data);
  },
  async createOnline(data) {
    const url = `${BASE_URL}/shm/pay/online`;
    return await request.post(url, data);
  },
  async getOrderCost(data) {
    const url = `${BASE_URL}/shm/order/cost/detail`;
    return await request.post(url, data);
  },
  async getEnquiryOrderCost(data) {
    const url = `${BASE_URL}/shm/order/getCostById`;
    return await request.post(url, data);
  },
  async getShopInfo(data) {
    const { shopId } = data;
    return await request.get(`${BASE_URL}/shm/pay${shopId}/shop`, data);
  },
  async getShopAccount(data) {
    const { shopId } = data;
    console.log('getShopAccount',shopId)
    // console.log(`${BASE_URL}/shm/pay/${shopId}/shop`, 'getShopAccount');
    return await request.get(`${BASE_URL}/shm/pay/${shopId}/shop`);
  },
  async getShopPayMethod(shopId) {

    // console.log(`${BASE_URL}/shm/pay/${shopId}/shop`, 'getShopAccount');
    return await request.get(`${BASE_URL}/shm/order/payMethod?shopId=${shopId}`);
  },
  async  getShopWXAccount(data) {
    const { shopId } = data;
    return await request.get(`${BASE_URL}/shm/pay/${shopId}/wxAccount`, data);
  },
  async createWXPayDirect(data) {
    return await request.post(`${BASE_URL}/shm/pay/wxDirectPay`, data);
  },
  async getExpress(orderId) {
    return await request.get(`${BASE_URL}/shm/express/${orderId}/logistics/info`);
  },
  async getOfflinePayVoucher(paymentBillId) {
    return await request.get(`${BASE_URL}/shm/pay/voucher/list?paymentBillId=${paymentBillId}`);
  },
  async checkOfflinePayment(param) {
    return await request.post(`${BASE_URL}/shm/payVoucher/check`,param);
  },

};
