/*
 * @Date: 2022-05-23 16:53:19
 * @LastEditors: royce
 * @LastEditTime: 2022-06-20 16:32:39
 * @FilePath: /mall-web/src/services/address.ts
 */
import { config, request } from 'ice';

const { BASE_URL } = config;

export default {
  async getAccountTypes() {
    return await request.get(`${BASE_URL}/shm/account/types?qureyuseage=true`);
  },
  async getAccountList() {
    return await request.get(`${BASE_URL}/shm/account?type=enterprise`);
  },
  async addAccount(data) {
    return await request.post(`${BASE_URL}/shm/account`, data);
  },
  async deleteAccount(accountId) {
    return await request.delete(`${BASE_URL}/shm/account?accountId=${accountId}`);
  },
  async signAccount(data) {
    return await request.post(`${BASE_URL}/shm/pay/open/account`, data);
  },
  async getBankList() {
    return await request.get(`${BASE_URL}/shm/account/bankInfo?accType=Enterprise`);
  },
  async getAccountRefundList(type) {
    return await request.get(`${BASE_URL}/shm/offlineAccount/list?type=${type}&pageSize=100&pageIndex=1`);
  },
  async addRefundAccount(data) {
    return await request.post(`${BASE_URL}/shm/offlineAccount/add`,data);
  },
  async deleteRefundAccount(data) {
    return await request.delete(`${BASE_URL}/shm/offlineAccount/delete?id=${data}`);
  },
};
