import cate1 from '@/assets/cate1.png';
import cate2 from '@/assets/cate2.png';
import cate3 from '@/assets/cate3.png';
import cate4 from '@/assets/cate4.png';
import { BQSType } from '@/models/global';
import appStore from '@/store';
import { CategoryType } from '@/types';
import { addOrUpdateQuery, getBaseUrl } from '@/utils';
import { DownOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Space } from 'antd';
import { useHistory } from 'ice';
import qs from 'qs';
import { MenuInfo } from 'rc-menu/lib/interface';
import { useEffect, useState } from 'react';
import styles from './index.module.less';

export default function LayoutHeaderMenu() {
  const history = useHistory();
  const { location } = history;
  const isHome = location.pathname === '/';
  const isCategory = location.pathname === '/categories';
  const mockIcons = [cate1, cate2, cate3, cate4,cate4];
  const [level1Item, setLevel1Item] = useState<CategoryType>();
  const [userState, userDispatchers] = appStore.useModel('user');
  const [globalState, globalDispatchers] = appStore.useModel('global');
  const [goodsListState, goodsListDispatchers] = appStore.useModel('goodslist');
  const { orderType, sortType } = goodsListState;
  const { showAllCategory, showItemCategory, bqsList, subCategoryList, categoryList, activeMenu, bqsMoreList } =
    globalState;
  const search = qs.parse(location.search.slice(1));
  const cateListElement = document.getElementById('cate-item-class-list') as HTMLDivElement | null;
  useEffect(() => {
    if (search.bqsId && isCategory) {
      globalDispatchers.update({ activeMenu: search.bqsId });
      globalDispatchers.getBqsDetail(search.bqsId);
      console.log(bqsList, bqsMoreList, 'globalDispatchers');
    }
  }, [search.bqsId, bqsList]);

  let timer;
  let timerItem;
  const onOverClass = () => {
    if (isHome) {
      return;
    }
    clearTimeout(timerItem);
    timerItem = undefined;
    globalDispatchers.update({ showAllCategory: true });
  };
  const onMouseLeave = (event) => {
    timer = setTimeout(() => {
      globalDispatchers.update({ showAllCategory: false });
    }, 200);
  };
  const onMouseItemLeave = (event) => {
    console.log(event, 'event');

    timerItem = setTimeout(() => {
      globalDispatchers.update({ showAllCategory: false });
    }, 200);
  };
  const onOverItemClass = () => {
    clearTimeout(timer);
    timer = undefined;
    globalDispatchers.update({ showItemCategory: true });
    console.log('onOverItemClass');
  };
  const onOverTextClass = () => {
    clearTimeout(timerItem);
    timer = undefined;
    // globalDispatchers.update({ showItemCategory: true });
  };
  const onMouseTextLeave = (event) => {
    console.log(event, 'onMouseTextLeave');

    timer = setTimeout(() => {
      globalDispatchers.update({ showItemCategory: false, showAllCategory: false });
    }, 200);
  };
  const onOverItemList = (item) => {
    setLevel1Item(item);
    globalDispatchers.update({ subCategoryList: item.children, showItemCategory: true });
  };
  const onClickCategory = (category, parentCategory?: CategoryType) => {
    const { categoryId } = category;
    // 二级分类
    let categorys = [level1Item, category];
    if (parentCategory) {
      // 三级分类
      categorys = [level1Item, parentCategory, category];
    }
    onCategoryAction(categorys, categoryId);
  };
  const onMenuBtn = (category: BQSType) => {
    const { id } = category;
    globalDispatchers.update({ activeMenu: id, activeMenuName: category.name });
    globalDispatchers.getBqsDetail(id);
    if (cateListElement) {
      cateListElement.scrollTop = 0;
    }

    onBqsAction(id);
  };
  const onBqsAction = (bqsId: string) => {
    if (location.pathname === '/categories') {
      addOrUpdateQuery('bqsId', bqsId);
    } else {
      window.open(`${getBaseUrl()}/categories?bqsId=${bqsId}`);
      globalDispatchers.getBqsDetail(bqsId);
    }
  };
  const onCategoryAction = (categorys: CategoryType[], categoryId) => {
    goodsListDispatchers.update({ categorys, keyWords: '' });
    goodsListDispatchers.getCatalogList(categoryId);
    if (location.pathname === '/goods') {
      addOrUpdateQuery('goodCategory', categoryId);
      globalDispatchers.update({ showAllCategory: false, showItemCategory: false });
      goodsListDispatchers.getGoodsList({ pageIndex: 1, orderType, sortType, goodCategory: categoryId });
    } else {
      window.open(`${getBaseUrl()}/goods?goodCategory=${categoryId}`);
    }
  };

  const onMenuItem = (info: MenuInfo) => {
    const activeMenuName = bqsMoreList.find((item) => item.id === info.key)?.name;
    onBqsAction(info.key);
    globalDispatchers.update({
      activeMenu: info.key,
      activeMenuName,
    });
    if (cateListElement) {
      cateListElement.scrollTop = 0;
    }
    globalDispatchers.getBqsDetail(info.key);
    console.log(info, 'info.key');
  };

  const moreOptions = () => {
    if (!bqsMoreList.length) {
      return <Menu selectable items={[]} />;
    }
    const menu = (
      <Menu
        onClick={onMenuItem}
        items={bqsMoreList.map((item) => {
          return { key: item.id, label: item.name };
        })}
      />
    );
    return menu;
  };
  return (
    <div className={styles.menuContainer}>
      {/* <div className={styles.white} /> */}
      <div className={styles.menuBody}>
        <div className={styles.menuBtn}>
          <div onMouseEnter={isHome ? undefined : onOverClass} onMouseLeave={onMouseLeave} className={styles.classBtn}>
            <UnorderedListOutlined style={{ color: 'white', fontSize: 18 }} />
            <span className={styles.classTitle}>全部商品分类</span>
          </div>
          {showAllCategory && (
            <ul onMouseEnter={onOverItemClass} onMouseLeave={onMouseItemLeave} className={`${styles.allCategory}`}>
              {categoryList.slice(0, 5).map((item: CategoryType, index) => {
                return (
                  <li
                    onMouseEnter={() => {
                      onOverItemList(item);
                    }}
                    key={item.categoryId}
                    className={styles.itemList}
                  >
                    <div className={styles.itemContainer}>
                      <p className={styles.itemTitle}>
                        <img src={mockIcons[index]} className={styles.leftIcon} />
                        {item.categoryName}
                      </p>
                      <div>
                        {item.children &&
                          item.children.slice(0, 6).map((child: CategoryType) => {
                            return (
                              <a
                                onClick={() => {
                                  onClickCategory(child);
                                }}
                                className={styles.categoryText}
                              >
                                {child.categoryName}
                              </a>
                            );
                          })}
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
          {showItemCategory && (
            <div onMouseEnter={onOverTextClass} onMouseLeave={onMouseTextLeave} className={styles.itemClassList}>
              {subCategoryList.map((item: CategoryType) => {
                return (
                  <div key={item.categoryId} className={styles.itemClassItem}>
                    <a
                      onClick={() => {
                        onClickCategory(item);
                      }}
                      className={styles.itemClassItemTitle}
                    >
                      {`${item.categoryName} >`}
                    </a>
                    <div className={styles.itemClassItemText}>
                      {item.children &&
                        item.children.map((subItem: CategoryType) => {
                          return (
                            <a
                              onClick={() => {
                                onClickCategory(subItem, item);
                              }}
                              className={styles.itemClassItemA}
                            >
                              {subItem.categoryName}
                            </a>
                          );
                        })}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div className={styles.menuBtns}>
          <div>
            {bqsList.length
              ? bqsList.map((item: BQSType) => {
                  return (
                    <span
                      onClick={() => {
                        onMenuBtn(item);
                      }}
                      key={item.id}
                      className={`${styles.menuItem} ${activeMenu === item.id && !isHome ? styles.active : ''}`}
                    >
                      {item.name}
                    </span>
                  );
                })
              : null}
          </div>
          <Dropdown overlay={moreOptions}>
            <Space>
              <span className={styles.cateMore}>更多</span>
              <DownOutlined style={{ color: '#333333' }} />
            </Space>
          </Dropdown>
        </div>
      </div>
    </div>
  );
}
