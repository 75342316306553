import { refundListMock, wuliuMock } from './../mocks';
/*
 * @Date: 2022-04-07 13:52:14
 * @LastEditors: royce
 * @LastEditTime: 2022-06-24 15:14:28
 * @FilePath: /mall-web/src/models/order.ts
 */
import {
  ExpressInfo,
  MEASUE_METHOD,
  RefundStatusType,
  REFUND_ACCOUNT_TYPE,
  REFUND_ORDER_STATUS,
  REFUND_TYPE,
  REFUND_VOUCHER_STATUS,
  ShopInfo,
} from './../types';
import orderServer from '@/services/order';
import refundServer from '@/services/refund';
import { IRootDispatch, IRootState } from '@/store';
import { CONTRACT_STATUS, PAY_TYPE, ShopAccount } from '@/types';
import { getBaseUrl, showSuccess, sleep } from '@/utils';
import { times } from 'number-precision';
import qs from 'qs';
import _, { delay } from 'lodash';
import { history as iHistory } from 'ice';
import { OrderGoodsItem, OrderItem } from './order';
import { OrderRefundRequest, RefundListRequest } from '@/interface/request';
import shop from '@/services/shop';
import { shouldReturnAll } from '@/pages/orderRefund';
export const ORDER_PAGE_SIZE = 10;
export interface RefundCancelReason {
  code: string;
  name: string;
}
export interface FileItem {
  id: string;
  fileName: string;
  fileUrl: string;
}
export interface RefundGoodsItem {
  refundGoodsId: string; //退货明细记录ID
  goodsId: string; //商品id
  imgUrl: string;
  goodsName: string; //商品名
  specName: string; //商品规格
  price: number; //价格
  amount: number; //数量
  subtotal: number; //小计
  goodsAmount: number; //小计
  unitEach:number;
  priceUnit:number;//单价
  refundAmount: number; //退款金额
  buyerServiceAmount: number; //买家服务费
  goodsUnit:number;
  measureMethod: MEASUE_METHOD; //卖家服务费
  sellerServiceAmount: number; //卖家服务费
}
export interface RefundOrderItem {
  id: string; //退款单ID
  refundCode: string; //退款单编号
  payMethod: string; //支付方式
  refundType: REFUND_TYPE; //退款类型
  memberName: string; //用户名
  expressRemark: string; //物流备注
  shopName: string; //店铺名
  orderCode: string; //订单编号
  orderId: string;
  receivedGoods: boolean;
  realRefundAmount: number; //真实退款金额
  buyerApplyServiceAmount: number; //买家申请退服务费金额
  buyerRealServiceAmount: number; //买家真实退服务费金额
  buyerRealTotalAmount: number; //买家总退款金额
  imgUrl: string;
  sellerApplyServiceAmount: number; //卖家申请退服务费金额
  sellerRealServiceAmount: number; //卖家真实退服务费金额
  reasonType: string; //原因类型
  reason?: string; //原因
  expressCode?: string; //物流公司编号
  expressName?: string; //物流名称
  sendCode?: string; //物流单号
  expressLinkPerson?: string; //物流联系人
  expressLinkTel?: string; //物流联系电话
  province?: string; //省
  city?: string; //市
  area?: string; //区
  street?: string; //街道
  address?: string; //地址
  receiptLinkPerson?: string; //收件联系人
  receiptLinkTel?: string; //收件联系电话
  sellerRemark?: string; //卖家备注
  platRemark?: string; //平台备注
  refundStatus: REFUND_ORDER_STATUS; //退款状态
  goods?: RefundGoodsItem[];
  imgs?: FileItem[];
  attachments?: FileItem[];
  applyTime: string;
  remark?: string;
  postedOutTime?: string;
  offlineAccountId: string;
  offlineAccountName: string;
  offlineAccountNo: string;
  offlineAccountType: REFUND_ACCOUNT_TYPE;
  offlineBankCode: string;
  offlineBankName: string;
  payVoucherStatus: REFUND_VOUCHER_STATUS;
}

export default {
  state: {
    refundOrder: {} as OrderItem,
    refundList: [{}] as RefundOrderItem[],
    statusList: [] as RefundStatusType[],
    reasons: [] as RefundCancelReason[],
    searchIndex: 1,
    total: 0,
    keyword: '',
    pageIndex: 1,
    refundDetail: {} as RefundOrderItem,
    refundStatus: '',
    refundType: REFUND_TYPE.ONLY_REFUND,
    shopInfo: {} as ShopInfo,
    payVoucher: [] as FileItem[],
    express: {
      name: '',
      expressCode: '',
      received: false,
      sendOutTime: '',
      logistics: [],
    } as ExpressInfo,
  },
  reducers: {
    update(prevState, payload) {
      const state = { ...prevState, ...payload };
      return state;
    },
    updateGoods(state, { checked, goodsId }) {
      state.refundOrder.goodsList.forEach((item: OrderGoodsItem) => {
        if (item.goodsId === goodsId) {
          item.check = checked;
        }
      });
    },
    checkAll(state, { checked }) {
      state.refundOrder.goodsList.forEach((item: OrderGoodsItem) => {
        item.check = checked;
      });
    },
    updateCount(state, { selectCount, goodsId }) {
      state.refundOrder.goodsList.forEach((item: OrderGoodsItem) => {
        if (item.goodsId === goodsId) {
          item.selectCount = selectCount;
        }
      });
    },
  },
  effects: (dispatch: IRootDispatch) => ({
    async getRefundList(
      { refundStatus, pageIndex = 1, keyword }: { refundStatus?: string; pageIndex?: number; keyword?: string },
      { refund }: IRootState,
    ) {
      try {
        const param = {
          memberType: 'buyer',
          keyword,
          pageSize: ORDER_PAGE_SIZE,
          refundStatus,
          pageIndex,
        };
        console.log(param, 'param');
        if (!refund.statusList.length) {
          await dispatch.refund.getRefundStatus();
        }
        const params = _.pickBy(param);
        const data = await refundServer.getRefundList(params);
        const { rows, total } = data;
        let payloadObj = {
          refundList: [] as any,
          total,
          pageIndex,
        };
        if (Array.isArray(rows)) {
          payloadObj = { refundList: rows, total, pageIndex };
        }
        // debugger
        const query = { refundStatus, pageIndex, keyword };
        if (location.pathname.indexOf('refund-detail') < 0) {
          history && history.pushState({}, '', `${location.pathname}?${qs.stringify(query)}`);
        }
        dispatch.refund.update({
          ...payloadObj,
          refundStatus,
          pageIndex: typeof pageIndex === 'string' ? parseInt(pageIndex) : pageIndex,
          keyword,
        });

        // dispatch.refund.update({ refundList: refundListMock });
      } catch (error) {}
    },

    async getDetailRefund({ refundId }, { refund }) {
      if (!refund.statusList.length) {
        await dispatch.refund.getRefundStatus();
      }
      const res = await refundServer.getRefundDetail({ refundId });
      if (res) {
        dispatch.refund.update({
          refundDetail: res,
        });
        dispatch.refund.getShopInfo(res.shopId);
        const { sendCode, expressCode, expressName } = res;
        if (sendCode && expressCode && expressName) {
          dispatch.refund.getExpressInfo({ sendCode, expressCode, expressName });
        }
        if (res.payMethod === PAY_TYPE.OFFLINE) {
          dispatch.refund.getRefundPayAttachments(refundId);
        }
      }
    },
    async getCancelOrderReason() {
      try {
        const data = await refundServer.getRefundReasons();
        dispatch.refund.update({ reasons: data });
      } catch (error) {
        // return Promise.reject(error);
      }
    },
    async cancelRefund(refundId, { refund }: IRootState) {
      try {
        await refundServer.cancelRefund({ refundId });
        dispatch.refund.refreshOrder();
        showSuccess('退款取消成功');
      } catch (error) {
        // return Promise.reject(error);
      }
    },
    async checkOfflineRefund(data, { refund }: IRootState) {
      await refundServer.checkOfflineRefund(data);
      dispatch.refund.refreshOrder();
      showSuccess(`提交成功`);
    },

    async refreshOrder(_, { refund }: IRootState) {
      const { refundStatus, keyword, pageIndex } = refund;
      const param = {
        memberType: 'buyer',
        keyword,
        pageSize: ORDER_PAGE_SIZE,
        refundStatus,
        pageIndex,
      };
      dispatch.refund.getRefundList(param);
    },

    async submitRefund(data, { user, order }: IRootState) {
      console.log(data, 'submitRefund');
      await refundServer.submitRefund(data);
      showSuccess('退款申请成功');
      // dispatch.refund.refreshOrder();
      iHistory && iHistory.push('/workbench/refund');
    },
    async getDetailOrder(orderId, { user, refund }: IRootState) {
      const param = {
        memberId: user.id,
        orderId,
      };

      const res = await orderServer.getOrderListApi(param);
      if (res && Array.isArray(res.rows) && res.rows[0]) {
        let order = res.rows[0];
        if (shouldReturnAll) {
          order.goodsList = res.rows[0].goodsList.map((item) => {
            return {
              ...item,
              selectCount: item.count,
              check: true,
            };
          });
        }
        dispatch.refund.update({
          refundOrder: order,
        });
        const shopId = order.goodsList[0].shopId;
        dispatch.refund.getShopInfo(shopId);
      }
    },
    async getShopInfo(shopId, { user }) {
      const res = await shop.shopInfo(shopId);
      dispatch.refund.update({ shopInfo: res });
    },
    async getExpressInfo(data) {
      const res = await refundServer.getReturnExpressInfo(data);
      dispatch.refund.update({ express: res });
    },
    async getRefundStatus() {
      const res = await refundServer.getRefundStatus();
      dispatch.refund.update({ statusList: res });
    },
    async postRefundGoods(data) {
      try {
        await refundServer.postGoods(data);
        dispatch.refund.refreshOrder();
        return true;
      } catch (error) {
        return false;
      }
    },
    async getRefundPayAttachments(refundId) {
      const res = await refundServer.getRefundPayVoucher(refundId);
      if (_.isArray(res)) {
        dispatch.refund.update({ payVoucher: res });
      }
    },
  }),
};
// export default model;
