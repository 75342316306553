import {
  INVOICE_NEED_TYPE,
  EnquiryStep,
  EnquiryItem,
  ENQUIRY_STATUS,
  ACCOUNT_STATUS,
  PAY_TYPE,
  ORDER_STATE_MAP,
  PURCHASE_ORDER_STATUS,
  CategoryType,
  REFUND_ORDER_STATUS,
  REFUND_VOUCHER_STATUS,
  ORDER_OFFLINE_VOUCHER_STATUS,
  commonEnumDes,
} from './../types';
/*
 * @Date: 2022-05-23 09:59:11
 * @LastEditors: royce
 * @LastEditTime: 2022-06-23 19:32:24
 * @FilePath: /mall-web/src/utils/index.ts
 */
import { AUTH_TYPE, CartItemSku, GoodSkuItem, OrderStep, ORDER_STATUS } from '@/types';
import { message } from 'antd';
import { OrderItem } from '@/models/order';
import _ from 'lodash';
import { times, plus, minus } from 'number-precision';
import { RcFile } from 'antd/lib/upload';
import WaitIcon from '@/assets/wait_offer.png';
import WaitIconActivate from '@/assets/wait_offer_activate.png';
import EnquiryOffer from '@/assets/enquiry_offer.png';
import EnquiryOfferActivate from '@/assets/enquiry_offer_activate.png';
import EnquiryClose from '@/assets/enquiry_close.png';
import EnquiryCloseActivate from '@/assets/enquiry_close_activate.png';
import EnquiryOrder from '@/assets/enquiry_orderred.png';
import EnquiryOrderActivate from '@/assets/enquiry_orderred_activate.png';
import { PresetStatusColorType } from 'antd/es/_util/colors';
import { DataNode } from 'antd/lib/tree';
import QueryString from 'qs';
/**
 * 休眠
 * @param time
 */
export const sleep = (time = 10) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, time);
  });
};
export const formatCategory = (oldList) => {
  const list = _.map(oldList, (item) => {
    const data = {
      ...item,
      value: _.get(item, 'categoryId'),
      label: _.get(item, 'categoryName'),
    };
    return data;
  });
  return listToTree(list);
};

/**
 * 数组转树
 * @param list
 */
export const listToTree = (list) => {
  const result = [];
  const map = new Map();

  list.forEach((item) => {
    map[item.value] = item;
  });

  list.forEach((item) => {
    if (item.parentId !== null) {
      if (map[item.parentId]) {
        map[item.parentId]['children']
          ? map[item.parentId]['children'].push(item)
          : (map[item.parentId]['children'] = [item]);
      }
    } else {
      result.push(item as never);
    }
  });

  return result;
};
export const formatCartList = (cartObj) => {
  if (!cartObj) return [];
  const cartList = cartObj.map((item) => {
    return {
      shopId: item.tenantId,
      shopName: item.shopName,
      shopGoods: item.goodsList,
    };
  });
  // for (const key in cartObj) {
  //   const shoplist = cartObj[key];
  //   const arr = key.split(':');
  //   if (arr.length === 2) {
  //     const shopGoods = {
  //       shopId: arr[1],
  //       shopName: arr[0],
  //       shopGoods: shoplist,
  //     };
  //     cartList.push(shopGoods);
  //   }
  // }
  console.log('cartList', cartList);
  return cartList;
};
export function isObjectValueEqualNew(a, b) {
  const aProps = Object.getOwnPropertyNames(a);
  const bProps = Object.getOwnPropertyNames(b);
  console.log(a, b, 'isObjectValueEqualNew');
  if (aProps.length != bProps.length) {
    return false;
  }
  for (let i = 0; i < aProps.length; i++) {
    const propName = aProps[i];

    const propA = a[propName];
    const propB = b[propName];
    if (typeof propA === 'object') {
      if (this.isObjectValueEqualNew(propA, propB)) {
        return true;
      } else {
        return false;
      }
    } else if (propA !== propB) {
      return false;
    } else {
      //
    }
  }
  return true;
}
export function getAuthEnum({ pcer, pcon, cera, ccer, cerc }) {
  let status: AUTH_TYPE = AUTH_TYPE.UN_AUTH;
  let path = '';
  let des = '';

  if (!pcer) {
    status = AUTH_TYPE.UN_AUTH;
    path = '/pages/packages/auth/person/person';
    des = '前往';
  } else if (pcer && !pcon) {
    status = AUTH_TYPE.PERSON_AUDITING;
    path = '/pages/packages/auth/person/person';
    des = '实名审核中';
  } else if (pcer && pcon && !ccer) {
    status = AUTH_TYPE.PERSON_APPROVED;
    path = '/pages/packages/auth/company/company';
    des = '企业未认证';
  } else if (pcer && pcon && ccer && !cera && cerc) {
    status = AUTH_TYPE.ENTERPRISE_NOT_AUTH;
    path = '/pages/packages/auth/company/company';
    des = '企业法人未授权';
  } else if (pcer && pcon && ccer && !cera && !cerc) {
    status = AUTH_TYPE.ENTERPRISE_NOT_AUTH;
    path = '/pages/packages/auth/company/company';
    des = '企业法人未授权';
  } else if (pcer && pcon && ccer && cera && !cerc) {
    status = AUTH_TYPE.ENTERPRISE_AUDITING;
    path = '/pages/packages/auth/company/company';
    des = '企业认证审核中';
  } else if (pcer && pcon && ccer && cera && cerc) {
    status = AUTH_TYPE.ENTERPRISE_APPROVED;
    des = '企业已认证';
  }

  return { status, path: path ? `${path}?status=${status}` : '', des };
}

export const showToast = (msg) => {
  message.destroy();
  message.info(msg);
};
export const showErrorToast = (msg) => {
  message.destroy();
  message.error(msg);
};
export const showWarning = (msg) => {
  message.destroy();
  message.warning(msg);
};
export const showSuccess = (msg, duration?: number) => {
  message.destroy();
  message.success(msg, duration);
};

export const showLoading = (msg, duration = 0): Function => {
  const hide = message.loading(msg, duration);
  return hide;
};

export const addOrUpdateQuerys = (obj: Object) => {
  history.pushState({}, '', `${location.pathname}?${QueryString.stringify(obj)}`);
};
export const addOrUpdateQuery = (key, value, type = 'pushState') => {
  let url = type === 'location' ? location.href : location.hash;

  if (!url.includes('?')) {
    url = `${url}?${key}=${value}`;
  } else if (!url.includes(key)) {
    url = `${url}&${key}=${value}`;
  } else {
    const re = `(\\?|&|\#)${key}([^&|^#]*)(&|$|#)`;
    url = url.replace(new RegExp(re), `$1${key}=${value}$3`);
  }

  if (type === 'location') {
    location.href = url;
  }

  if (type === 'pushState') {
    history.pushState({}, '', url);
  }
};

export const addWindowsReload = () => {
  // window.onbeforeunload = () => {
  //   const rootState = appStore.getState();
  //   localStorage.setItem('initialState', JSON.stringify(rootState));
  //   console.log('addWindowsReload', event);
  // };
};

export const getLocalStorage = (key) => {
  const state = localStorage.getItem(key);
  return state ? JSON.parse(state) : {};
};

export const getOrderTotal = (skus: Array<GoodSkuItem & CartItemSku>, isTax: INVOICE_NEED_TYPE) => {
  let totalPrice = 0;
  let excludeTax = 0;
  let taxedPrice = 0;
  skus.forEach((sku) => {
    const { count, price } = sku;
    const excludeTaxPrice = _.defaultTo(sku.excludeTaxPrice, 0);
    const targetPrice = isTax === INVOICE_NEED_TYPE.YES ? price : excludeTaxPrice;
    const taxPrice = times(count, price || 0);
    const exclude = times(count, excludeTaxPrice || 0);
    taxedPrice = plus(taxedPrice, taxPrice);
    excludeTax = plus(excludeTax, exclude);
    totalPrice = plus(totalPrice, times(count, targetPrice || 0));
  });
  const tax = minus(taxedPrice, excludeTax);
  return { totalPrice, tax };
};
/**
 * 上传data封装
 * @param file
 */
export const uploadFormData = (file) => {
  const formData = new FormData();
  formData.append('files', file);
  // image/jpeg
  // formData.append('contentType', _.get(file, 'type'));
  const type = _.get(file, 'type');
  const indexOf = type.indexOf('image');
  return {
    formData,
    params: {
      contentType: type,
      fileDir: indexOf > -1 ? 'IMAGE' : 'OTHER',
    },
  };
};
export const convertMoney = (n) =>
  n.toFixed(0).replace(/(\d{1,4})((\d{4})*)$/, (a, b, c) => {
    const t = ['', '万', '亿', '万亿'][c.length / 4];
    return t ? `${b}.${c.slice(0, 2)}${t}` : b;
  });

export const getStepData = (orderItem?: OrderItem): OrderStep[] => {
  if (!orderItem) {
    return [];
  }
  const { contractId } = orderItem;
  const temp: OrderStep[] = [];

  if (contractId) {
    temp.push({ title: '下单', status: [ORDER_STATUS.ORDER], index: 0 });
    temp.push({ title: '签署', status: [ORDER_STATUS.SIGIN], index: 1 });
    temp.push({ title: '付款', status: [ORDER_STATUS.PAYING, ORDER_STATUS.PENGDING], index: 2 });
    temp.push({ title: '发货', status: [ORDER_STATUS.PAYED], index: 3 });
    temp.push({ title: '收货', status: [ORDER_STATUS.POSTED_OUT], index: 4 });
    temp.push({ title: '完成', status: [ORDER_STATUS.RECEIVED, ORDER_STATUS.CLOSED], index: 5 });
  } else {
    temp.push({ title: '下单', status: [ORDER_STATUS.ORDER], index: 0 });
    temp.push({ title: '付款', status: [ORDER_STATUS.PAYING, ORDER_STATUS.PENGDING], index: 1 });
    temp.push({ title: '发货', status: [ORDER_STATUS.PAYED], index: 2 });
    temp.push({ title: '收货', status: [ORDER_STATUS.POSTED_OUT], index: 3 });
    temp.push({ title: '完成', status: [ORDER_STATUS.RECEIVED, ORDER_STATUS.CLOSED], index: 4 });
  }

  return temp;
};

export const getEnquiryStepData = (item?: EnquiryItem): EnquiryStep[] => {
  if (!item) {
    return [];
  }
  const { enquiryStatus } = item;
  const temp: EnquiryStep[] = [];

  if (enquiryStatus === ENQUIRY_STATUS.CLOSED && item.price) {
    temp.push({
      title: '待报价',
      status: [ENQUIRY_STATUS.UN_QUOTED],
      index: 0,
      icon: WaitIcon,
      activeIcon: WaitIconActivate,
    });
    temp.push({
      title: '已报价',
      status: [ENQUIRY_STATUS.QUOTED],
      index: 1,
      icon: EnquiryOffer,
      activeIcon: EnquiryOfferActivate,
    });
    temp.push({
      title: '已关闭',
      status: [ENQUIRY_STATUS.CLOSED],
      index: 2,
      icon: EnquiryClose,
      activeIcon: EnquiryCloseActivate,
    });
  } else if (enquiryStatus === ENQUIRY_STATUS.CLOSED && !item.price) {
    temp.push({
      title: '待报价',
      status: [ENQUIRY_STATUS.UN_QUOTED],
      index: 0,
      icon: WaitIcon,
      activeIcon: WaitIconActivate,
    });
    temp.push({
      title: '已关闭',
      status: [ENQUIRY_STATUS.CLOSED],
      index: 1,
      icon: EnquiryClose,
      activeIcon: EnquiryCloseActivate,
    });
  } else {
    temp.push({
      title: '待报价',
      status: [ENQUIRY_STATUS.UN_QUOTED],
      index: 0,
      icon: WaitIcon,
      activeIcon: WaitIconActivate,
    });
    temp.push({
      title: '已报价',
      status: [ENQUIRY_STATUS.QUOTED],
      index: 1,
      icon: EnquiryOffer,
      activeIcon: WaitIconActivate,
    });
    temp.push({
      title: '已下单',
      status: [ENQUIRY_STATUS.ORDERED],
      index: 2,
      icon: EnquiryOrder,
      activeIcon: EnquiryOrderActivate,
    });
  }

  return temp;
};

export function looseEqual(a, b) {
  if (a === b) {
    // 如果是绝对相等就直接返回true
    return true;
  }
  // 如果不是绝对相等就哦按的他们是否有相同的形状
  const isObjectA = isObject(a);
  const isObjectB = isObject(b);
  if (isObjectA && isObjectB) {
    // 两个均是对象
    try {
      const isArrayA = Array.isArray(a);
      const isArrayB = Array.isArray(b);
      if (isArrayA && isArrayB) {
        // 如果都是数组
        if (a.length === b.length) {
          // 如果长度相等
          return a.every((e, i) => {
            // 用every和递归来比对a数组和b数组的每个元素，并返回
            return looseEqual(e, b[i]);
          });
        }
        // 长度都不等直接返回false
        return false;
      } else if (a instanceof Date && b instanceof Date) {
        // 如果是Date 则直接getTime 比较
        return a.getTime() === b.getTime();
      } else if (!isArrayA && !isArrayB) {
        // 对象的比较
        // 拿到两个对象的key
        const keysA = Object.keys(a);
        const keysB = Object.keys(b);
        if (keysA.length === keysB.length) {
          // 如果keys相等
          return keysA.every((key) => {
            // 用every和递归来比对a对象和b对象的每个元素值，并返回
            return looseEqual(a[key], b[key]);
          });
        }
        // 长度都不等直接返回false
        return false;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  } else if (!isObjectA && !isObjectB) {
    // 如果都不是对象则按String来处理
    return String(a) === String(b);
  } else {
    return false;
  }
}

function isObject(obj) {
  return obj !== null && typeof obj === 'object';
}

export function getBaseUrl() {
  const curWwwPath = window.document.location.href;
  const pathName = window.document.location.pathname;
  const pos = curWwwPath.indexOf(pathName);
  const localhostPaht = curWwwPath.substring(0, pos);
  const baseUrl = localhostPaht;

  return `${baseUrl}/buyer`;
}

export function getGoodsCover(item = {}) {
  const { picJson, goodsPic, thumb } = item;
  let cover;
  if (Array.isArray(picJson) && picJson.length) {
    cover = picJson[0];
  } else if (goodsPic && goodsPic.length) {
    const arr = JSON.parse(goodsPic);
    cover = arr[0];
  } else if (Array.isArray(thumb) && thumb.length) {
    cover = thumb[0];
  }
  return cover;
}
export function initIndexDB() {
  // var request = window.indexedDB.open(databaseName, version);
}

export function scrollToTop(className?: string) {
  if (className) {
    const div = document.getElementsByClassName(className)[0];
    console.log(div, 'div');
    div.scrollTop = 0;
  }
  window.scrollTo({
    top: 0,
    behavior: 'auto',
  });
}
export const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export function getSignBadgeStatus(signStatus: ACCOUNT_STATUS): PresetStatusColorType {
  if (signStatus === ACCOUNT_STATUS.SUCCEED) {
    return 'success';
  }
  if (signStatus === ACCOUNT_STATUS.FIAIL) {
    return 'error';
  }
  if (signStatus === ACCOUNT_STATUS.NOT_START) {
    return 'warning';
  }
  if (signStatus === ACCOUNT_STATUS.PROCESSING) {
    return 'processing';
  }
  return 'warning';
}

export const getOrderStatus = ({
  status,
  payMethod,
  payVoucherStatus,
}: {
  status: string;
  payMethod: string;
  payVoucherStatus?: REFUND_VOUCHER_STATUS;
}) => {
  if (status === ORDER_STATUS.AB_CLOSE) {
    return '已全额退款';
  }
  if (payMethod === PAY_TYPE.OFFLINE && status === ORDER_STATUS.PAYING) {
    if (payVoucherStatus) {
      return commonEnumDes[payVoucherStatus];
    } else {
      return '卖家审核中';
    }
  }
  return ORDER_STATE_MAP[status];
};

export const sliceArrayTo = (n = 4, baseArray: Array<any>): Array<Array<any>> => {
  let len = baseArray.length;
  let lineNum = len % n === 0 ? len / n : Math.floor(len / n + 1);
  let res: any[][] = [];

  for (let i = 0; i < lineNum; i++) {
    // slice() 方法返回一个从开始到结束（不包括结束）选择的数组的一部分浅拷贝到一个新数组对象。且原始数组不会被修改。
    let temp = baseArray.slice(i * n, i * n + n);
    res.push(temp);
  }
  return res;
};
export const getPurchaseStatusColor = (status: PURCHASE_ORDER_STATUS): 'light' | 'normal' | 'diable' => {
  switch (status) {
    case PURCHASE_ORDER_STATUS.PENDING_PROCESS:
    case PURCHASE_ORDER_STATUS.REJECTED:
      return 'light';

    case PURCHASE_ORDER_STATUS.QUOTED:
    case PURCHASE_ORDER_STATUS.ACCEPTED:
    case PURCHASE_ORDER_STATUS.ORDERED:
    case PURCHASE_ORDER_STATUS.FINISHED:
      return 'normal';
    case PURCHASE_ORDER_STATUS.CANCELED:
    case PURCHASE_ORDER_STATUS.REJECTED:
      return 'diable';

    default:
      return 'normal';
  }
};
export const getRefundStatusColor = (status: REFUND_ORDER_STATUS): 'light' | 'normal' | 'diable' => {
  switch (status) {
    case REFUND_ORDER_STATUS.RefundApply:
    case REFUND_ORDER_STATUS.SellerRejected:
    case REFUND_ORDER_STATUS.RefundApply:
    case REFUND_ORDER_STATUS.PlatUntreated:
    case REFUND_ORDER_STATUS.PlatRejected:
      return 'light';

    case REFUND_ORDER_STATUS.RefundSuccess:
      return 'normal';
    case REFUND_ORDER_STATUS.RefundFailed:
    case REFUND_ORDER_STATUS.RefundClosed:
      return 'diable';

    default:
      return 'normal';
  }
};

export const formatNullPrice = (price: number | null) => {
  if (price === undefined || price === null) {
    return '-';
  }
  return `¥${price.toFixed(2)}`;
};
export const downloadFile = (href, fileName = '') => {
  return new Promise((resolve, reject) => {
    if (!href) return Promise.reject('herf文件下载地址不能为空');
    fetch(href, { method: 'POST' })
      .then((response) => {
        if (response.ok) {
          return response.blob(); //转bolb
        } else {
          return reject(response.statusText);
        }
      })
      .then((blob) => {
        let a = document.createElement('a');
        a.href = URL.createObjectURL(blob);
        a.download = fileName;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(a.href); // 释放URL 对象
        a.remove();
        return resolve(true);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};
