import appStore from '@/store';
import { AUTH_TYPE, commonEnumDes, COMPANY_AUTH_TYPE } from '@/types';
import { showWarning } from '@/utils';
import { Space } from 'antd';
import { useHistory } from 'ice';
import { useMemo } from 'react';
import { Popover } from 'zent';
import styles from './index.module.less';

export default function LayoutHeaderTop() {
  const history = useHistory();
  const [userState, userDispatchers] = appStore.useModel('user');
  const { authState, auth, lastApplyInfo } = userState;
  const [globalState, globalDispatchers] = appStore.useModel('global');
  const showPersonAuth = authState === AUTH_TYPE.UN_AUTH && !!userState.id;
  const showCompanyAuth = authState > AUTH_TYPE.PERSON_AUDITING;
  const displayName = useMemo(() => userState.userName || userState.nickName, [userState.userName, userState.nickName]);

  const onLogin = () => {
    globalDispatchers.showLogin();
  };
  const onLogout = () => {
    userDispatchers.resetUser();
  };

  const onCart = async () => {
    const { flag, msg } = await globalDispatchers.checkLogin();
    if (!flag) {
      showWarning(msg);
      return;
    }
    history.push('/cart');
  };
  const authDes = useMemo(() => {
    const isLastFail =
      lastApplyInfo.checkStatus === COMPANY_AUTH_TYPE.FAIL || lastApplyInfo.checkStatus === COMPANY_AUTH_TYPE.CHECKING;
    const isCompanyAuth = authState === AUTH_TYPE.ENTERPRISE_APPROVED;
    if (isLastFail && !isCompanyAuth) {
      return commonEnumDes[lastApplyInfo.checkStatus];
    }
    return commonEnumDes[authState];
  }, [authState, lastApplyInfo]);
  const onPersonAuth = async () => {
    history.push('/workbench/personAuth');
  };
  const onCompany = async () => {
    history.push('/workbench/auth');
  };
  const onOrder = async () => {
    const { flag, msg } = await globalDispatchers.checkLogin();
    if (!flag) {
      showWarning(msg);
      return;
    }
    history.push('/workbench/order');
  };
  const onEnquiry = async () => {
    const { flag, msg } = await globalDispatchers.checkLogin();
    if (!flag) {
      showWarning(msg);
      return;
    }
    history.push('/workbench/enquiryList');
  };
  const onPurchaseCenter = async () => {
    const { flag, msg } = await globalDispatchers.checkLogin();
    if (!flag) {
      showWarning(msg);
      return;
    }
    history.push('/purchaseCenter');
  };
  const onAddress = async () => {
    const { flag, msg } = await globalDispatchers.checkLogin();
    if (!flag) {
      showWarning(msg);
      return;
    }
    history.push('/workbench/address');
  };
  const onContract = async () => {
    const { flag, msg } = await globalDispatchers.checkLogin();
    if (!flag) {
      showWarning(msg);
      return;
    }
    history.push('/workbench/contract');
  };
  const onPayAccount = async () => {
    const { flag, msg } = await globalDispatchers.checkLogin();
    if (!flag) {
      showWarning(msg);
      return;
    }
    history.push('/workbench/payAccount');
  };
  const onWorkBench = async () => {
    const { flag, msg } = await globalDispatchers.checkLogin();
    if (!flag) {
      showWarning(msg);
      return;
    }
    history.push('/workbench/order');
  };
  const onHome = async () => {
    history.push('/');
    globalDispatchers.update({ activeMenu: '' });
  };
  const onGoodslist = async () => {
    history.push('/goods');
  };
  return (
    <div className={styles.headerContainer}>
      <div className={styles.header}>
        <Space size={30}>
          <span className={styles.headerTitle} onClick={onHome}>
            首页
          </span>
          {!userState.id ? (
            <span className={styles.headerTitleMain} onClick={onLogin}>
              您好,请登录
            </span>
          ) : (
            <Popover position={Popover.Position.BottomLeft}>
              <Popover.Trigger.Hover>
                <div className={styles.headerActionContainer}>
                  {' '}
                  <span className={styles.headerTitleMain}>您好 {displayName}</span>
                </div>
              </Popover.Trigger.Hover>
              <Popover.Content>
                <div className={styles.userPop}>
                  <span className={styles.headerlogout} onClick={onLogout}>
                    {'退出登录'}
                  </span>
                </div>
                {/* <UserInfoPop /> */}
              </Popover.Content>
            </Popover>
          )}
          {showPersonAuth ? (
            <span className={styles.headerTitle} onClick={onPersonAuth}>
              {commonEnumDes[authState]}
            </span>
          ) : null}
          {showCompanyAuth ? (
            <span className={styles.headerTitle} onClick={onCompany}>
              {authDes}
            </span>
          ) : null}
        </Space>
        <Space size={30}>
          <span className={styles.headerTitle} onClick={onCart}>
            购物车
          </span>
          {/* <span className={styles.headerTitle} onClick={onGoodslist}>
          商品列表
        </span> */}
          <span className={styles.headerTitle} onClick={onPurchaseCenter}>
            采购中心
          </span>
          <span className={styles.headerTitle} onClick={onOrder}>
            我的订单
          </span>
          <span className={styles.headerTitle} onClick={onEnquiry}>
            我的询价单
          </span>
          <span className={styles.headerTitle} onClick={onAddress}>
            收货地址
          </span>
          <span className={styles.headerTitle} onClick={onPayAccount}>
            支付管理
          </span>
          <span className={styles.headerTitle} onClick={onWorkBench}>
            工作台
          </span>
        </Space>
      </div>
    </div>
  );
}
