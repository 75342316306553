
/*
 * @Date: 2022-05-24 15:47:16
 * @LastEditors: royce
 * @LastEditTime: 2022-06-20 16:53:10
 * @FilePath: /mall-web/src/models/address.ts
 */
import server from '../services/purchase';
import { DELIVERY_TYPE, INVOICE_NEED_TYPE } from './../types';
import _, { isArray } from 'lodash';
import { showSuccess } from './../utils/index';
import { history } from 'ice';

import { IRootState, IRootDispatch } from '@/store';
import global from '@/services/global';

export default {
  state: {
    units: [], // 计量单位
    goodsCategory: [], // 类别
    goodsSpec: [],// 规格
  },
  reducers: {
    update(prevState, payload) {
      return { ...prevState, ...payload };
    },
    updateGoodsCategory(prevState, payload) {
      prevState.goodsSpec[payload.tableLength] = prevState.goodsSpec[payload.fieldIndex]
    },
    // 替换复制一条数据
    changeGoodsCategory(prevState, payload) {
      prevState.goodsSpec[payload.fieldIndex] = payload.dataSource;
    },
    deleteGoodsCategory(prevState, payload) {
      prevState.goodsSpec.splice(payload, 1);
    }
  },
  effects: (dispatch: IRootDispatch) => ({
    async submitPurchase(data, { user }: IRootState) {
      const res = await server.submitPurchse(data);
      showSuccess('采购单提交成功');
      setTimeout(() => {
        history && history.push('/workbench/purchaseList?status=');
      }, 1000)

    },
    /* 获取商品类型 */
    async getGoodsCategory(data, { user }: IRootState) {
      const res = await global.getCategoryList();
      dispatch.purchase.update({ goodsCategory: res });
    },
    /* 获取规格 */
    async getSpecCategory(data) {
      const res = await server.getSpecMatrix(data.categoryId);
      dispatch.purchase.changeGoodsCategory({dataSource: isArray(res) ? res : [], fieldIndex: data.fieldIndex});
    },
    /* 获取计量单位 */
    async getUnit() {
      const res = await global.getUnitsByType();
      dispatch.purchase.update({ units: res === true ? [] : res });
    }

  }),
};
