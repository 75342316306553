/*
 * @Date: 2022-05-23 13:28:10
 * @LastEditors: royce
 * @LastEditTime: 2022-06-24 16:05:40
 * @FilePath: /mall-web/src/components/SMSCounter/index.tsx
 */
import { useEffect, useMemo, useRef, useState } from 'react';
import { Button } from 'antd';
import './index.less';
import { showWarning } from '@/utils';

export let intervalTime: any = null;
export default function SMSCounter(props) {
  const { onSendSMS, phone } = props;
  const [smsStatus, setsmsStatus] = useState(0);
  const [smsTick, setsmsTick] = useState(60);
  const [smsLoading, setsmsLoading] = useState(false);
  const sendText = useMemo(() => {
    let text = '发送验证码';
    if (smsStatus === 1) {
      text = `${smsTick}s已发送`;
    } else if (smsStatus === 2) {
      text = '重新发送';
    }
    console.log(smsTick, 'smsTick');
    return text;
  }, [smsStatus, smsTick]);
  const onAction = () => {
    if (!onSendSMS) {
      return;
    }
    if (!phone || phone.length !== 11) {
      return showWarning('请输入正确的手机号');
    }
    onSendSMS();
    setsmsLoading(true);

    startIntervalTime();
  };
  useEffect(() => {
    return () => {
      intervalTime && clearInterval(intervalTime);
    };
  }, []);
  const startIntervalTime = () => {
    console.log('startIntervalTime');
    clearInterval(intervalTime);
    intervalTime = setInterval(() => {
      console.log('setInterval');
      setsmsLoading(false);
      // const tick = myRef. - 1;
      // if (tick < 0) {
      //   clearInterval(intervalTime);
      //   setsmsTick(60);
      //   return;
      // }
      // console.log('tick', tick);
      setsmsTick((v) => {
        if (v < 0) {
          clearInterval(intervalTime);
          return 60;
        }
        setsmsStatus(v === 0 ? 2 : 1);
        return v - 1;
      });
      // setsmsStatus(tick === 0 ? 2 : 1);
    }, 1000);
  };

  return (
    <Button disabled={smsStatus === 1} onClick={onAction} loading={smsLoading}>{sendText}</Button>
  );
}
