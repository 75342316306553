/*
 * @Date: 2022-04-07 13:52:14
 * @LastEditors: royce
 * @LastEditTime: 2022-06-17 16:17:57
 * @FilePath: /mall-web/src/services/goods.ts
 */

import { config, request } from 'ice';
import _ from 'lodash';
import qs from 'qs';

const { BASE_URL } = config;

export default {
  async getGoodsSpecs(goodsId) {
    return await request.get(`${BASE_URL}/shm/goods/${goodsId}/specs`);
  },
  async getHomeGoodsList(data) {
    const param = _.pickBy(data, _.identity);
    return await request.get(`${BASE_URL}/shm/goods?${qs.stringify(param)}`);
  },
  async getHomeStaticData() {
    return await request.get(`${BASE_URL}/shm/goods/salesStatistics`);
  },
  async getGoodsSearch(payload) {
    return await request.get(`${BASE_URL}/shm/goods/searchList${qs.stringify(payload)}`);
  },
  async getGoodsDetail(goodsId) {
    return await request.get(`${BASE_URL}/shm/goods/${goodsId}/detail`);
  },
  async getTopCategory() {
    return await request.get(`${BASE_URL}/shm/marketing/topQualityCategoryList`);
  },
  async addCart(data) {
    const { memberId, skus } = data;
    const headers = { ContentType: 'application/x-www-form-urlencoded' };
    // console.log(qs.stringify(data), 'qs.stringify(data)', data);
    return await request.post(`${BASE_URL}/shm/shoppingCart/${memberId}`, skus);
  },
  async getCatalogList(parentId) {
    let url = `${BASE_URL}/shm/categoryList?channl=PC`;
    if (parentId) {
      url += `&parentId=${parentId}`;
    }
    return request.get(url);
  },
};
