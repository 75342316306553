/*
 * @Date: 2022-04-07 13:52:14
 * @LastEditors: royce
 * @LastEditTime: 2022-05-27 10:59:59
 * @FilePath: /mall-web/src/models/goods.ts
 */
import server from '@/services/goods';
import shop from '@/services/shop';
import { IRootState } from '@/store';
import { GoodListItem, GOODS_SORT_TYPE, HOME_TYPE } from '@/types';
import _ from 'lodash';

const PAGE_SIZE = 10;

export default {
  state: {
    goodsList: [] as GoodListItem[],
    pageIndex: 1,
    orderType: HOME_TYPE.LAST_UPDATED_ON,
    sortType: GOODS_SORT_TYPE.DESC,
    total: 0,
    shopName: '',
    shopId: '',
  },
  reducers: {
    update(prevState, payload) {
      return { ...prevState, ...payload };
    },
  },
  effects: (dispatch) => ({
    async getGoodsListHome(data, { shop }: IRootState) {
      const param = {
        pageSize: PAGE_SIZE,
        channl: 'PC',
        shopId: shop.shopId,
        ...data,
      };
      delete param.shopName;
      const params = _.pickBy(param);
      try {
        const res = await server.getHomeGoodsList(params);
        const totalCount = res.total;
        const state = {
          goodsList: res.rows,
          total: totalCount,
          ...data,
        };
        dispatch.shopIndex.update(state);
      } catch (error) {
        dispatch.shopIndex.update({ ...data, emptyKeyword: data.keyWords || '' });
      }
    },
  }),
};
