

/*
 * @Date: 2022-05-24 15:47:16
 * @LastEditors: royce
 * @LastEditTime: 2022-06-27 13:41:57
 * @FilePath: /mall-web/src/models/cart.ts
 */
import server from '@/services/cart';
import { CartItemSku, DELIVERY_TYPE } from '@/types';
import { formatCartList } from '@/utils';
import { showWarning } from '@/utils/index';
import { history } from 'ice';

export interface ShopCartModel {
  shopName: string;
  shopId: string;
  tenantId:string;
  selected: boolean;
  shopGoods: CartItemSku[];
}
export interface CartState {
  price: number;
  count: number;
  cartList: ShopCartModel[];
  pageIndex: number;
  hasNextPage: boolean;
  checkALL: boolean;
}
const PAGE_SIZE = 20;

export default {
  state: {
    price: 122.44,
    count: 23,
    hasNextPage: true,
    pageIndex: 1,
    cartList: [] as ShopCartModel[],
    checkALL: false,
  },
  reducers: {
    update(prevState, payload) {
      return { ...prevState, ...payload };
    },

    updateSku: (state, payload) => {
      state.cartList.forEach((shopItem) => {
        if (shopItem.shopId === payload.shopId) {
          shopItem.shopGoods.forEach((sku) => {
            if (sku.mainGoodsId === payload.sku.mainGoodsId) {
              if (sku.cartId === payload.sku.cartId) {
                sku.count = payload.value;
              }
            }
          });
        }
      });
    },

    onSelectAll: (state: CartState, _) => {
      state.cartList.forEach((item) => {
        item.shopGoods.forEach((sku: CartItemSku) => {
          if (sku.inventoryAmount) {
            sku.selected = !state.checkALL;
          }
        });
        item.selected = !state.checkALL;
      });
      state.checkALL = !state.checkALL;
    },
    onSelectAction: (state: CartState, payload) => {
      state.cartList.forEach((item) => {
        let shopCount = 0;
        let shopSelectCount = 0;
        shopCount += item.shopGoods.length;
        item.shopGoods.forEach((sku: CartItemSku) => {
          if (sku.mainGoodsId === payload.sku.mainGoodsId) {
            if (sku.cartId === payload.sku.cartId) {
              sku.selected = payload.selected;
            }
          }
        });
        shopSelectCount += item.shopGoods.filter((e) => e.selected).length;
        item.selected = shopSelectCount === shopCount;
        // item.selected = item.shopGoods.filter(item => item.selected).length === item.shopGoods.length
      });
      state.checkALL = state.cartList.filter((item) => item.selected).length === state.cartList.length;
    },

    onSelectShopAction: (state: CartState, payload) => {
      state.cartList.forEach((shopItem) => {
        if (shopItem.shopId === payload.shopId) {
          shopItem.shopGoods.forEach((sku: CartItemSku) => {

            if (sku.inventoryAmount) {
              sku.selected = payload.selected;
            }
          });
          shopItem.selected = payload.selected;
        }
        
      });
      state.checkALL = state.cartList.filter((item) => item.selected).length === state.cartList.length;
    },
  },
  effects: (dispatch) => ({
    async getCartList(data?, rootState?) {
      try {
        const { user } = rootState;
        const res = await server.getCart({
          pageIndex: 1,
          pageSize: PAGE_SIZE,
          memberId: user.id,
          ...data,
        });
        const cartList = formatCartList(res);

        const temp = [];
        dispatch.cart.update({ cartList, checkALL: false });
        console.log(cartList, 'cartList');
        dispatch.global.getCartAllList();
      } catch (error) {
        dispatch.cart.update({ cartList: [], checkALL: false });
        dispatch.global.getCartAllList();
      }
    },


    async onUpdateSku(data, rootStore) {
      const { user } = rootStore;
      const { cartId } = data.sku;
      await server.cartUpdate({ cartId, count: data.value, memberId: user.id });
      dispatch.cart.updateSku(data);
    },

    selectItem(data) {
      dispatch.cart.onSelectAction(data);
    },

    async deleteSelectedItem(_, rootState) {
      const { cart } = rootState;
      const { user } = rootState;
      const cartIdArray: string[] = [];

      cart.cartList.forEach((shopItem) => {
        const skuids = shopItem.shopGoods.filter((sku) => sku.selected).map((item) => item.cartId);
        cartIdArray.push(...skuids);
      });
      console.log(cartIdArray, 'cartIdArray');
      const res = await server.cartDelete({ cartIdArray: cartIdArray.join(','), memberId: user.id });
      dispatch.cart.getCartList();
      console.log(res, 'res');
    },
    async deleteItem({ cartId, memberId }) {
      try {
        await server.cartDelete({ cartIdArray: [cartId].join(','), memberId });
        dispatch.cart.getCartList();
      } catch (error) {
      }
    },
    async submitOrder(_, rootStore) {
      const { cart } = rootStore;
      const skus: CartItemSku[] = [];
      const ids: string[] = [];
      const shops: Array<{ shopName: string; shopId: string }> = [];
      cart.cartList.forEach((shopItem) => {
        const sku = shopItem.shopGoods.filter((s) => s.selected);
        const id = sku.map((item) => item.cartId);
        if (sku.length) {
          shops.push({ shopName: shopItem.shopName, shopId: shopItem.shopId });
          skus.push(...sku);
          ids.push(...id);
        }
      });
      const uniq = new Set(shops.map((item) => item.shopId));
      if (uniq.size > 1) {
        showWarning('请选择同一个店铺的商品结算' );
        return
      }
      const { shopId, shopName } = shops[0];
      const data = { skus, cartIds: ids, shopId, shopName, loading: false, deliveryMethod: DELIVERY_TYPE.BY_SELLER };
      console.log(data, 'datasubmitData');
      sessionStorage.removeItem('submitData');
      sessionStorage.setItem('submitData', JSON.stringify(data));
      dispatch.submit.update(data);
      history && history.push('/submit');
    },
  }),


};
