/*
 * @Date: 2022-04-07 13:52:14
 * @LastEditors: owenhe owenhe@msn.cn
 * @LastEditTime: 2024-06-03 09:56:32
 * @FilePath: /mall-web/src/store.ts
 */
import { IStoreModels, IStoreDispatch, IStoreRootState ,createStore} from 'ice';
// import { createStore } from '@ice/store';
import user from '@/models/user';
import global from '@/models/global';
import shop from '@/models/shop';
import shopIndex from '@/models/shopIndex';
import goods from '@/models/goods';
import order from '@/models/order';
import cart from './models/cart';
import address from './models/address';
import goodslist from './models/goodslist';
import home from './models/home';
import submit from './models/submit';
import enquiry from './models/enquiry';
import contract from './models/contract';
import payAccount from './models/payAccount';
import purchase from './models/purchase';
import purchaseCenter from './models/purchaseCenter';
import purchaseList from './models/purchaseList';
import refund from '@/models/refund';
interface IAppStoreModels extends IStoreModels {
  user: typeof user;
  global: typeof global;
  shop: typeof shop;
  goods: typeof goods;
  shopIndex: typeof shopIndex;
  order: typeof order;
  cart: typeof cart;
  address: typeof address;
  goodslist: typeof goodslist;
  home: typeof home;
  submit: typeof submit;
  enquiry: typeof enquiry;
  contract: typeof contract;
  payAccount: typeof payAccount;
  purchase: typeof purchase;
  purchaseList: typeof purchaseList;
  purchaseCenter: typeof purchaseCenter;
  refund: typeof refund;
}

export type IRootDispatch = IStoreDispatch<typeof appModels>;
export type IRootState = IStoreRootState<typeof appModels>;
const appModels: IAppStoreModels = {
  user,
  global,
  shop,
  goods,
  order,
  shopIndex,
  cart,
  address,
  goodslist,
  home,
  submit,
  enquiry,
  contract,
  payAccount,
  purchase,
  purchaseList,
  purchaseCenter,
  refund
};
const store = createStore(appModels);

export default store;



