/*
 * @Date: 2022-04-07 13:52:14
 * @LastEditors: royce
 * @LastEditTime: 2022-06-23 21:30:38
 * @FilePath: /mall-buyer-pc/src/routes.ts
 */
import Layout from '@/Layouts/BasicLayout';
import WorkLayout from '@/Layouts/Workbench';
import { IRouterConfig, lazy } from 'ice';
// import WrapperPage from '@/components/WrapperPage';
// import LoginWrapper from '@/components/LoginWrapper';

const NotFound = lazy(() => import('@/components/NotFound'));
const Home = lazy(() => import('@/pages/Home'));
const Categories = lazy(() => import('@/pages/categories'));
const Cart = lazy(() => import('@/pages/Cart'));
const Order = lazy(() => import('@/pages/order'));
const Address = lazy(() => import('@/pages/address'));
const PayAccount = lazy(() => import('@/pages/payAccount/index'));
const Contract = lazy(() => import('@/pages/contract'));
const Goods = lazy(() => import('@/pages/goods'));
const Shop = lazy(() => import('@/pages/shop'));
const ShopIndex = lazy(() => import('@/pages/shopIndex'));
const Auth = lazy(() => import('@/pages/auth'));
const AccountInfo = lazy(() => import('@/pages/accountInfo'));
const ReceiptAccount = lazy(() => import('@/pages/receiptAccount'));
const PersonAuth = lazy(() => import('@/pages/personAuth'));
const GoodsDetail = lazy(() => import('@/pages/goods-detail'));
const EnquiryDetail = lazy(() => import('@/pages/enquiry-detail'));
const OrderDetail = lazy(() => import('@/pages/order-detail'));
const Submit = lazy(() => import('@/pages/submit'));
const Enquiry = lazy(() => import('@/pages/enquiry'));
const PurchasePost = lazy(() => import('@/pages/purchasePost'));
const PurchaseCenter = lazy(() => import('@/pages/purchaseCenter'));
const PurchaseDetail = lazy(() => import('@/pages/purchase-detail'));
const PurchaseList = lazy(() => import('@/pages/purchaseList'));
const Refund = lazy(() => import('@/pages/refundList'));
const OrderRefund = lazy(() => import('@/pages/orderRefund'));
const RefundDetail = lazy(() => import('@/pages/refund-detail'));
const EnquiryList = lazy(() => import('@/pages/enquiryList'));
const SignedResult = lazy(() => import('@/pages/signedResult'));
const routerConfig: IRouterConfig[] = [
  {
    path: '/workbench',
    component: WorkLayout,
    children: [
      {
        path: '/purchaseList',
        exact: true,
        component: PurchaseList,
      },
      {
        path: '/purchase-detail',
        exact: true,
        component: PurchaseDetail,
      },
      {
        path: '/order',
        exact: true,
        component: Order,
      },
      {
        path: '/orderRefund',
        exact: true,
        component: OrderRefund,
      },
      {
        path: '/refund',
        exact: true,
        component: Refund,
      },
      {
        path: '/refund-detail',
        exact: true,
        component: RefundDetail,
      },
      {
        path: '/order-detail',
        exact: true,
        component: OrderDetail,
      },
      {
        path: '/enquiryList',
        exact: true,
        component: EnquiryList,
      },
      {
        path: '/enquiry-detail',
        exact: true,
        component: EnquiryDetail,
      },
      {
        path: '/personAuth',
        exact: true,
        component: PersonAuth,
      },
      {
        path: '/auth',
        exact: true,
        component: Auth,
      },
      {
        path: '/accountInfo',
        exact: true,
        component: AccountInfo,
      },
      {
        path: '/address',
        exact: true,
        component: Address,
      },
      {
        path: '/payAccount',
        exact: true,
        component: PayAccount,
      },
      {
        path: '/receiptAccount',
        exact: true,
        component: ReceiptAccount,
      },
      {
        path: '/contract',
        exact: true,
        component: Contract,
      },
    ],
  },
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '/',
        exact: true,
        component: Home,
      },
      {
        path: '/cart',
        exact: true,
        component: Cart,
      },
      {
        path: '/goods',
        exact: true,
        component: Goods,
      },
      {
        path: '/categories',
        exact: true,
        component: Categories,
      },
      {
        path: '/shop',
        exact: true,
        component: Shop,
      },
      {
        path: '/shopIndex',
        exact: true,
        component: ShopIndex,
      },

      {
        path: '/purchase',
        exact: true,
        component: PurchasePost,
      },
      {
        path: '/purchaseCenter',
        exact: true,
        component: PurchaseCenter,
      },

      {
        path: '/enquiry',
        exact: true,
        component: Enquiry,
      },

      {
        path: '/goods-detail',
        exact: true,
        component: GoodsDetail,
      },
      {
        path: '/submit',
        exact: true,
        component: Submit,
      },
      {
        path: '/signedResult',
        exact: true,
        component: SignedResult,
      },
      {
        component: NotFound,
      },
    ],
  },
];

export default routerConfig;
