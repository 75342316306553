/*
 * @Date: 2022-04-07 13:52:14
 * @LastEditors: royce
 * @LastEditTime: 2022-06-21 10:26:05
 * @FilePath: /mall-web/src/services/user.ts
 */
import { config, request } from 'ice';
import qs from 'qs';

const {
  BASE_URL,
} = config;

export default {

  /**
   * 企业信息
   * @param id
   */
  async getUserInfo(id) {
    return await request(`${BASE_URL}/shm/member/${id}`);
  },

  async userLogin(data) {
    //  const param = `?password=${password}&type=member&username=${username}`
    return await request.post(`${BASE_URL}/shm/sys/longin?${qs.stringify(data)}`, { noToken: true });
  },
  async getCode({ mobileNumber, isAuth }) {
    //  判断是否登录用户接口不一样
    return await request.post(`${BASE_URL}${isAuth ? '/shm/sms' : '/shm/system/mobileCode'}/${mobileNumber}`);
  },
  async checkCode(data) {
    // 检查验证码
    const { mobileNumber } = data;
    return await request.get(`${BASE_URL}/shm/sms/${mobileNumber}/check?${qs.stringify(data)}`);
  },
  async legalAuth(data) {
    return await request.post(`${BASE_URL}/shm/enterprise/${data.memberId}/authorization`, data);
  },
  userRegister(data) {
    return request.post(`${BASE_URL}/shm/member?${qs.stringify(data)}`);
  },
  async resetPwd(data) {
    return await request.post(`${BASE_URL}/shm/member/password?${qs.stringify(data)}`, data);
  },
  async personAuth(data) {
    return await request.post(`${BASE_URL}/shm/member/${data.memberId}/certification`, data);
  },
  async personSign(data) {
    return await request.post(`${BASE_URL}/shm/contract/${data.memberId}/member`, data);
  },
  async companySign(data) {
    return await request.post(`${BASE_URL}/shm/contract/${data.memberId}/company`, data);
  },
  async companyAuth(data) {
    return request.post(`${BASE_URL}/shm/enterprise/${data.memberId}`, data);
  },
  async getCompanyAuth(data) {
    return request.get(`${BASE_URL}/shm/enterprise/${data.memberId}`, data);
  },
  async getLastApply(memberId) {
    return request.get(`${BASE_URL}/shm/company/getLastApply?memberId=${memberId}`);
  },
  async manualApply(data) {
    return request.post(`${BASE_URL}/shm/company/apply`,data);
  },
  // 参数场景
  async getRepo(id) {
    return await request(`/api/repo/${id}`);
  },
  // 格式化返回值
  async getDetail(params) {
    const data = await request({
      url: '/api/detail',
      params,
    });

    return data.map((item) => {
      return {
        ...item,
        price: item.oldPrice,
        text: item.status === '1' ? '确定' : '取消',
      };
    });
  },
};
