import { ColumnsType } from 'antd/lib/table';
import {
  RefundAccountType,
} from '@/types';
export const aliPayRefundColumns: ColumnsType<RefundAccountType> = [
  {
    title: '户名',
    dataIndex: 'accountName',
    key: 'accountName',
    render: (text) => <span>{text}</span>,
  },
  {
    title: '账户',
    dataIndex: 'accountNo',
    key: 'accountNo',
    render: (text) => <span>{text}</span>,
  },
];
export const bankRefundColumns: ColumnsType<RefundAccountType> = [
  {
    title: '企业名称（户名）',
    dataIndex: 'accountName',
    key: 'accountName',
    render: (text) => <span>{text}</span>,
  },
  {
    title: '企业银行卡卡号',
    dataIndex: 'accountNo',
    key: 'accountNo',
    render: (text) => <span>{text}</span>,
  },
  {
    title: '开户行',
    dataIndex: 'bankName',
    key: 'bankName',
    render: (text) => {
      return <span>{text}</span>;
    },
  },
];
