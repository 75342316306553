/*
 * @Author: Royce
 * @Date: 2021-12-20 16:08:56
 * @LastEditors: royce
 * @LastEditTime: 2022-06-27 10:05:09
 * @FilePath: /mall-web/src/types.ts
 */

import { PurchaseStatus } from '@/components/PurchaseStatus';
import { PayMethodObj } from './models/submit';
export enum REFUND_TYPE {
  ONLY_REFUND = 'OnlyRefund',
  RETURN_REFUND = 'ReturnRefund',
}
export enum REFUND_ACCOUNT_TYPE {
  ALIPAY_ACCOUNT = 'aliPay',
  UNION_ACCOUNT = 'bankCard',
}

export enum REFUND_VOUCHER_STATUS {
  WAIT = 'wait_seller_upload',
  WAIT_SELLER_CHECK= 'wait_seller_check',
  SUCCESS = 'success',
  FAIL = 'failed',
}

export class GoodListItem {
  goodsId: string;

  name: string;

  pic: string;
  shopId: string;

  price: number;

  payedCount: number;

  mainGoodsId: number;

  shopName: string;

  inventoryAmount: number;

  title: string;

  thumb: string[];

  specTitle: string;

  height: number;

  count: number;

  width: number;

  spec: Object;

  shiftedOn: string;
  goodsUnit: string;
}

export enum ORDER_TYPE {
  CentralizedPurchase = 'CentralizedPurchase',
  Normal = 'Normal',
  SimpleEnquiry = 'SimpleEnquiry',
}

export class CartItemSku {
  mainGoodsId: string;
  goodsUnit: string;
  count: number;
  excludeTaxPrice: number;
  goodsId: string;
  goodsName: string;
  pic: string;
  picJson: string[];
  price: number;
  unitEach: number;
  specTitle: Object;
  inventoryAmount: number;
  selected: boolean;
  cartId: string;
  priceUnit: number;
  unit: string;
  shopName: string;
  tenantId: string;
  measureMethod:MEASUE_METHOD;
}
export enum ExpressType {
  ONLINE_SEND = 'online',
  OFFLINE_SEND = 'offline',
}
export class EnquiryItemSku {
  amount: number;
  url: string;
  goodsName: string;
  spec: Object;
  goodsUnit?: string;
  unitEach: number;
  measureMethod:MEASUE_METHOD;
}

export class ShopInfo {
  companyId: string;
  id: string;
  shopName: string;
  shopStatus: string;
  managerAddress: string;
  managerContent: string;
  accountId: string;
  shopIntroduce: string;
  logoImage: string;
  account: string;
  accountName: string;
  bankName: string;
  bankNo: string;
  openBank: string;
  shopId: string;
  companyName: string;
  address: string;
}
export class CartItemModel {
  goodsList: CartItemSku[];
  mainGoodsId: string;
}

// {
//   "sunGoodsId": "3bb0a30e-4371-418f-8512-2265fb699487",
//   "inventoryAmount": 200,
//   "advanceSalePrice": 0,
//   "marketPrice": 0,
//   "afterTaxPrice": 1
//   "originalPrice": 0,
//   "costPrice": 0,
//   "goodSn": "",
//   "goodBarcode": "",
//   "goodWeight": 0,
//   "orderSeq": "1",
//   "mkPrice": 0,
//   "spec": {
//     "大小": "25mm",
//     "颜色": "红色"
//   }
// }
export enum MEASUE_METHOD {
  CACULATE = 'weight_by_calculate',
  WEIGHT = 'weight_by_weight',
  MARK = 'weight_by_mark',
}
export class GoodSkuItem {
  goodsId: string;
  mainGoodsId: string;
  goodsName: string;
  specTitle: string;
  price: number;
  marketPrice: number;
  excludeTaxPrice: number;
  inventoryAmount: number;
  picJson?: string[];
  pec: string;
  count: number;
  sunGoodsId: string;
  spec: Object;
  selected: boolean;
  goodsUnit: string;
  measureMethod: MEASUE_METHOD;
  unitEach: number;
  priceUnit: number;
}

export class OfflinePayItem {
  accountName: string;
  account: string;
  bankName: string;
  bankNo: string;
  openBank: string;
  accountId: string;
  accountTypeName: string;
}

export interface CategoryType {
  categoryId: string;
  categoryName: string;
  level: number;
  parentId: string | null;
  children?: CategoryType[];
  icon?: string;
}
export interface ExpressCompany {
  comCode: string; //物流公司编码
  name: string; //物流公司名称
}
export enum GOODS_SORT_TYPE {
  ASC = 'ASC',
  DESC = 'DESC',
}
export enum ORDER_SORT_TYPE {
  ASC = 'asc',
  DESC = 'desc',
}

export enum ORDER_COST_TYPE {
  XJD = 'XJD',
  JCD = 'JCD',
}

export enum UPLOAD_DIR {
  OTHER = 'OTHER',
  IMAGE = 'IMAGE',
  AGREEMENT = 'AGREEMENT',
}

export enum HOME_TYPE {
  LAST_UPDATED_ON = 4,
  SOLD_OUT_TALLY = 5,
  MIN_PRICE = 6,
}
export enum CONTRACT_TYPE {
  COMPANY_TYPE = 'COMPANY_TYPE',
  PERSON_TYPE = 'PERSON_TYPE',
}
export enum AUTH_TYPE {
  UN_AUTH = 0,
  PERSON_AUDITING = 1,
  PERSON_APPROVED = 2,
  ENTERPRISE_NOT_AUTH = 4,
  ENTERPRISE_AUDITING = 5,
  ENTERPRISE_APPROVED = 6,
}

export enum COMPANY_AUTH_TYPE {
  NONE = 0,
  CHECKING = 1,
  SUCCEED = 2,
  FAIL = 3,
}
export enum DETAIL_BUY_TYPE {
  CART = 'cart',
  BUY = 'buy',
  CHOOSE = 'choose',
}

export enum ENQUIRY_STATUS {
  UN_QUOTED = 'wait_offer',
  QUOTED = 'offer',
  ORDERED = 'ordered',
  CLOSED = 'offer_close',
}

export interface PurchaseItem {
  id: string; //集采采购单ID
  orderTime: string; //下单时间
  qunotationDeadline: string; //报价截止日
  status: 'pending'; //集采单状态Code
  statusName: '待处理'; //集采单状态
  totalGoods: 3; //所含商品种类
  quoted: 1; //已报价商品种类书
  totalAmount: 12346; //报价总金额
  totalExpectedAmount: 15555; //预期价格总额
  invoiceNeed: 'string';
}

export interface PurchaseSubmitItem {
  type: string;
  goodsName: string;
  spec: object;
  price: number;
  amount: number;
  goodsUnit: string;
}
export interface EnquiryItem {
  enquiryCode: string;
  goodsId: string;
  goodsName: string;
  addressId: string;
  priceUnit:number;
  url: string;
  spec: Object;
  amount: number;
  postFee: number;
  price: number;
  shopName: string;
  shopCode?: string;
  latestDeliveryDate: string;
  deliveryMethod: DELIVERY_TYPE;
  enquiryTime: string;
  enquiryStatus: ENQUIRY_STATUS;
  orderId: string;
  shopId: string;
  invoiceNeed: INVOICE_NEED_TYPE;
  picJson: string[];
  address: string;
  area: string;
  province: string;
  city: string;
  receiveName: string;
  receivePhone: string;
  goodsUnit: string;
  unitEach:number;
  measureMethod:MEASUE_METHOD
}
export enum ORDER_STATUS {
  ORDER = 'order',
  SIGIN = 'signing',
  PENGDING = 'pending',
  PAYED = 'payed',
  POSTED_OUT = 'posted_out',
  RECEIVED = 'received',
  CANCELED = 'canceled',
  CLOSED = 'closed',
  PAYING = 'paying',
  AB_CLOSE='abnormal_closed'
}
export enum PURCHASE_ORDER_STATUS {
  PENDING_PROCESS = 'PENDING_PROCESS',
  QUOTED = 'QUOTED',
  ACCEPTED = 'ACCEPTED',
  ORDERED = 'ORDERED',
  REJECTED = 'REJECTED',
  CANCELED = 'CANCELED',
  FINISHED = 'FINISHED',
}
export enum REFUND_ORDER_STATUS {
  RefundApply = 'RefundApply',
  SellerRejected = 'SellerRejected',
  PlatUntreated = 'PlatUntreated',
  PlatRejected = 'PlatRejected',
  PlatApproved = 'PlatApproved',
  Undelivered = 'Undelivered',
  Delivered = 'Delivered',
  Received = 'Received',
  RefundBegin = 'RefundBegin',
  Refunding = 'Refunding',
  RefundSuccess = 'RefundSuccess',
  RefundFailed = 'RefundFailed',
  RefundClosed = 'RefundClosed',
}
export const ORDER_STATE_MAP = {
  signing: '签署中',
  pending: '待支付',
  payed: '待发货',
  posted_out: '待收货',
  paying: '支付中',
  received: '已完成',
  closed: '已关闭',
  canceled: '已取消',
};


export const PAYT_YPE_MAP = {
  offline: '线下支付',
  wechat: '微信支付',
};

export interface ContractType {
  bestsignContractId: string;
  contractContent: string;
  contractCode: string;
  contractName: string;
  contractStatus: string;
  id: string;
  orderCode: string;
  partybSignedDate: string;
  partyaUrl: string;
  partyaSignedDate: string;
  partyaSigner: string;
  partybSigner: string;
}
export enum CONTRACT_STATUS {
  CREATED = 'created',
  PARTYA_TO_BE_SIGNED = 'partyA_to_be_signed',
  PARTYB_TO_BE_SIGNED = 'partyB_to_be_signed',
  CONTRACT_VOID = 'contract_void',
  COMPLETED = 'completed',
}
export enum PAY_TYPE {
  LIANLIAN = 'lianlianPay',
  ALIPAY = 'aliPay',
  WECAHT = 'wxPay',
  OFFLINE = 'offlinePay',
  BANK_CARD = 'bankCard',
  EBANK_B2B = 'eBankB2B',
}
export enum DELIVERY_TYPE {
  BY_SELLER = 'supplier_delivery',
  BY_BUYER = 'self_get',
}

export enum INVOICE_NEED_TYPE {
  YES = 'yes',
  NO = 'no',
}
export interface AddressItem {
  id: string;
  receiveName: string;
  province: string;
  city: string;
  area: string;
  receivePhone: string;
  addressId: string;
  street?: string;
  address: string;
  def?: boolean;
}
export interface AddressItem {
  id: string;
  receiveName: string;
  province: string;
  city: string;
  area: string;
  receivePhone: string;
  addressId: string;
  street?: string;
  address: string;
  def?: boolean;
}
export enum PAY_ACCOUNT_TYPE {
  ALIPAY_ACCOUNT = 'alipay_account',
}
export interface PayAccountType {
  accountId: string;
  accountNo: string;
  accountName: string;
  accountTypeCode: string;
  paymentAccount: PaymentAccount[];
}
export interface RefundAccountType {
  id: string; //线下账户ID
  type: string; //类型 企业支付宝 aliPay  企业银行卡 bankCard
  accountName: string; //户名
  accountNo: string; //账号
  bankName: string; //开户行
  bankCode: string; //开户行编码
}
export enum ACCOUNT_STATUS {
  NOT_ENABLE = 'not_enabled',
  FIAIL = 'open_failed',
  SUCCEED = 'normal',
  PROCESSING = 'advance_open',
  NOT_START = 'not_start',
  CANCELLATION = 'cancellation',
  UNAVALABEL = 'unavailable',
  ADVANCE_CANCELLATION = 'advance_cancellation',
}
export interface AccountSignType {
  name: string;
  code: string;
}

export interface PaymentAccount {
  paymentAccountId: string;
  signType: AccountSignType;
  signStatus: ACCOUNT_STATUS;
}

export interface ShopAccount {
  account: string;
  accountId: string;
  accountName: string;
  accountStatus: string;
  bankName: string;
  openBank: string;
  payMethod: string;
  reservedMobile: string;
  shopId: string;
  shopName: string;
  status: string;
}

export enum RESULTE_TYPE {
  SIGNED = 'signed',
  PAYED = 'payed',
}
export enum GOODS_SHIFT {
  ON_SHIFT = 'on_shift',
  OFF_SHIFT = 'un_shift',
}
export interface OrderStep {
  status: ORDER_STATUS[];
  title: string;
  index: number;
}

export interface EnquiryStep {
  status: ENQUIRY_STATUS[];
  title: string;
  index: number;
  icon?: any;
  activeIcon?: any;
}

export interface FilterItem {
  name: string;
  id: string;
  parentId: string;
}
export interface ExpressTrack {
  context: string;
  ftime: string;
  location: string;
  status: string;
  statusCode: string;
  time: string;
}

export interface ExpressInfo {
  expressCode: string;
  name: string;
  sendOutTime: string;
  received: boolean;
  logistics: ExpressTrack[];
}
export interface LabelValueType {
  label: string;
  value: string;
}

export interface BannerType {
  imageUrl: string;
  path?: string;
}

export interface StatisticType {
  label: string;
  value: string;
  unit: string;
}
export interface PurchaseStatusType {
  value: PURCHASE_ORDER_STATUS;
  label: string;
}
export interface RefundStatusType {
  code: REFUND_ORDER_STATUS;
  name: string;
}
export interface PurchaseGoodsType {
  id: null | string;
  categoryId: string;
  categoryName: string;
  goodsName: string;
  spec: string;
  expectedPrice: number;
  quntity: number;
  unit: null | string;
  marketPrice: null;
  price: null | number;
  commisionRate: null | number;
  commisionFee: null | number;
}
export interface PurchaseOrderType {
  id: null | string; //集采采购单ID
  orderTime?: string; //下单时间
  qunotationDeadline: string; //报价截止日
  insertedOn: string; //报价截止日
  status: PURCHASE_ORDER_STATUS; //集采单状态Code
  goodsCount: number;
  quotedPriceGoodsCount: number;
  totalquotedPriceAmount: number | null;
  statusName: string; //集采单状态
  totalGoods: number; //所含商品种类
  quoted: number; //已报价商品种类书
  totalAmount: number; //报价总金额
  totalExpectedAmount: number; //预期价格总额
  pickUpDeadline: null | number;
  comments: null | string;
  invoiceNeed: 'true' | 'false';
}

export interface PurchaseOrderDetailType {
  purchaseOrder: {
    deliveryMethod: string;
    deliveryAddress: null | string;
    selfGetAddress: null | string;
    invoiceNeed: true;
    qunotationDeadline: string;
    deliverDeadline: string | string;
    pickUpDeadline: null | string;
    comments: null | string;
    status: PURCHASE_ORDER_STATUS;
    statusName: string;
  };
  goodNum: number | null;
  quoteGoodNum: number | null;
  totalGoodsAmount: number | null;
  totalPostFee: number | null;
  totalCommisionFee: number | null;
  totalAmount: number | null;
  detail: {
    shopId: null | string;
    shopName: null | string;
    totalGoodsAmount: number;
    totalPostFee: null | number;
    totalCommisionFee: number;
    totalAmount: number;
    goods: PurchaseGoodsType[];
    additionalInfo: { payMethod: PayMethodObj[] };
  }[];
}

export interface PurchaseDynamicType {
  goods: [
    {
      goodsName: string; //商品名称
      goodsCode: string; //商品代码
      quantity: string; //购买数量
      unit: string; //购买数量单位
      marketPrice: string; //市场价
      price: string; //集采价
      priceUnit: string; //单价单位
    },
  ]; //商品信息（最多返回2个）
  totalSavings: string; //总计节省金额
  orderTime: string; //下单时间
  title: string; //描述
}

export const commonEnumDes = {
  [PAY_TYPE.ALIPAY]: '支付宝支付',
  [PAY_TYPE.WECAHT]: '微信支付',
  [PAY_TYPE.OFFLINE]: '线下支付',
  [PAY_TYPE.OFFLINE]: '线下支付',
  [PAY_TYPE.EBANK_B2B]: '企业网银支付',
  [DELIVERY_TYPE.BY_BUYER]: '买家自提',
  [DELIVERY_TYPE.BY_SELLER]: '商家配送',
  [ENQUIRY_STATUS.UN_QUOTED]: '待报价',
  [ENQUIRY_STATUS.QUOTED]: '已报价',
  [ENQUIRY_STATUS.ORDERED]: '已下单',
  [ENQUIRY_STATUS.CLOSED]: '已关闭',
  [AUTH_TYPE.UN_AUTH]: '前往实名认证',
  [AUTH_TYPE.PERSON_AUDITING]: '实名认证中',
  [COMPANY_AUTH_TYPE.CHECKING]: '企业认证审核中',
  [COMPANY_AUTH_TYPE.SUCCEED]: '企业已认证',
  [COMPANY_AUTH_TYPE.FAIL]: '企业认证审核失败',
  [AUTH_TYPE.PERSON_APPROVED]: '已实名认证,点击前往企业认证',
  [AUTH_TYPE.ENTERPRISE_NOT_AUTH]: '企业法人未授权',
  [AUTH_TYPE.ENTERPRISE_AUDITING]: '企业认证审核中',
  [AUTH_TYPE.ENTERPRISE_APPROVED]: '企业已认证',
  [CONTRACT_STATUS.CREATED]: '等待甲方签署',
  [CONTRACT_STATUS.PARTYA_TO_BE_SIGNED]: '等待甲方签署',
  [CONTRACT_STATUS.PARTYB_TO_BE_SIGNED]: '等待乙方签署',
  [CONTRACT_STATUS.CONTRACT_VOID]: '已作废',
  [CONTRACT_STATUS.COMPLETED]: '签署完成',
  [ACCOUNT_STATUS.NOT_START]: '未签约',
  [ACCOUNT_STATUS.PROCESSING]: '审核中',
  [ACCOUNT_STATUS.FIAIL]: '签约失败',
  [ACCOUNT_STATUS.SUCCEED]: '签约成功',
  [REFUND_TYPE.ONLY_REFUND]: '仅退款',
  [REFUND_TYPE.RETURN_REFUND]: '退货退款',
  [ExpressType.OFFLINE_SEND]: '',
  [ExpressType.ONLINE_SEND]: '',
  [REFUND_ACCOUNT_TYPE.ALIPAY_ACCOUNT]: '企业支付宝',
  [REFUND_ACCOUNT_TYPE.UNION_ACCOUNT]: '企业银行卡',
  [REFUND_VOUCHER_STATUS.WAIT]:'待买家上传',
  [REFUND_VOUCHER_STATUS.WAIT_SELLER_CHECK]:'卖家审核中',
  [REFUND_VOUCHER_STATUS.SUCCESS]:'审核成功',
  [REFUND_VOUCHER_STATUS.FAIL]:'支付凭证审核失败',
  [MEASUE_METHOD.CACULATE]:'理计',
  [MEASUE_METHOD.MARK]:'抄码',
  [MEASUE_METHOD.WEIGHT]:'过磅',


};
