/*
 * @Author: your name
 * @Date: 2022-02-16 11:03:35
 * @LastEditTime: 2022-06-27 14:27:36
 * @LastEditors: royce
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /mall-web/src/mocks.ts
 */

const homeStaticData = [
  {
    categoryId: '00000000-0000-0000-0000-100000001021',
    parentId: '00000000-0000-0000-0000-100000000021',
    categoryName: '混凝土',
    icon: 'https://shm-1256288681.cos.ap-nanjing.myqcloud.com/ZPqFy4i2mOTu5d862643ba3f4c9b336dd4e0c973dde12022021416370483993628.png',
  },
  {
    categoryId: '00000000-0000-0000-0000-100000000010',
    parentId: '00000000-0000-0000-0000-100000000000',
    categoryName: '结构材料',
    icon: 'https://shm-1256288681.cos.ap-nanjing.myqcloud.com/ZPqFy4i2mOTu5d862643ba3f4c9b336dd4e0c973dde12022021416370483993628.png',
  },
  {
    categoryId: '00000000-0000-0000-0000-100000000023',
    parentId: '00000000-0000-0000-0000-100000000001',
    categoryName: '主体材料',
    icon: 'https://shm-1256288681.cos.ap-nanjing.myqcloud.com/ZPqFy4i2mOTu5d862643ba3f4c9b336dd4e0c973dde12022021416370483993628.png',
  },
  {
    categoryId: '00000000-0000-0000-0000-100000000031',
    parentId: '00000000-0000-0000-0000-100000000001',
    categoryName: '模板',
    icon: 'https://shm-1256288681.cos.ap-nanjing.myqcloud.com/ZPqFy4i2mOTu5d862643ba3f4c9b336dd4e0c973dde12022021416370483993628.png',
  },
];
const homeGoodsMockData = [
  {
    goodsId: '2',
    pic: null,
    thumb: [''],
    name: '',
    price: 0,
    payedCount: 0,
    unshiftedOn: null,
    shiftedOn: '2022-01-26 17:14:04',
    inventoryAmount: 22,
    goodsStatus: 'on_shift',
  },
  {
    goodsId: '3',
    pic: null,
    thumb: [null],
    name: '',
    price: 0,
    payedCount: 0,
    unshiftedOn: null,
    shiftedOn: '2022-01-26 18:27:58',
    inventoryAmount: 23,
    goodsStatus: 'on_shift',
  },
  {
    goodsId: '4',
    pic: null,
    thumb: [''],
    name: '',
    price: 0,
    payedCount: 0,
    unshiftedOn: null,
    shiftedOn: '2022-01-26 18:31:51',
    inventoryAmount: 2222,
    goodsStatus: 'on_shift',
  },
  {
    goodsId: '5',
    pic: null,
    thumb: [''],
    name: '',
    price: 0,
    payedCount: 0,
    unshiftedOn: null,
    shiftedOn: '2022-01-26 18:31:36',
    inventoryAmount: 3444,
    goodsStatus: 'on_shift',
  },
];
export const StoreCacheType = {
  HomeGoodsData: 'homegoods_mock',
  HomeStaticData: 'homestatics_mock',
};
export function getCache(type: string) {
  if (type === StoreCacheType.HomeGoodsData) {
    return localStorage.getItem('homegoods_mock') || homeGoodsMockData;
  }
  if (type === StoreCacheType.HomeStaticData) {
    return localStorage.getItem('homestatics_mock') || homeStaticData;
  }
}
export const mockSkus = [
  {
    sunGoodsId: '0866720d-c4e1-46ae-aea4-dc6b5f4ab5b1',
    inventoryAmount: 0,
    advanceSalePrice: 0.0,
    marketPrice: 0.0,
    originalPrice: 0.0,
    costPrice: 0.0,
    goodSn: 'string',
    goodBarcode: 'string',
    goodWeight: 0.0,
    spec1: null,
    spec2: null,
    spec3: null,
    orderSeq: null,
    commissionRate1: null,
    commissionRate2: null,
    mkPrice: 0.0,
    spec: {
      大小: '25mm',
      颜色: '蓝色',
      规格: '规格1',
    },
  },
  {
    sunGoodsId: 'e4034512-67ab-4887-b1d9-2aab90610dc3',
    inventoryAmount: 0,
    advanceSalePrice: 0.0,
    marketPrice: 0.0,
    originalPrice: 0.0,
    costPrice: 0.0,
    goodSn: 'string',
    goodBarcode: 'string',
    goodWeight: 0.0,
    spec1: null,
    spec2: null,
    spec3: null,
    orderSeq: null,
    commissionRate1: null,
    commissionRate2: null,
    mkPrice: 0.0,
    spec: {
      大小: '10mm',
      颜色: '绿色',
      规格: '规格2',
    },
  },
  {
    sunGoodsId: 'e4034512-67ab-4887-b1d9-2aab90610dc3',
    inventoryAmount: 0,
    advanceSalePrice: 0.0,
    marketPrice: 0.0,
    originalPrice: 0.0,
    costPrice: 0.0,
    goodSn: 'string',
    goodBarcode: 'string',
    goodWeight: 0.0,
    spec1: null,
    spec2: null,
    spec3: null,
    orderSeq: null,
    commissionRate1: null,
    commissionRate2: null,
    mkPrice: 0.0,
    spec: {
      大小: '5mm',
      颜色: '黑色',
      规格: '规格3',
    },
  },
];

export const mockAliPay = `<form name="punchout_form" method="post" action="https://openapi.alipay.com/gateway.do?charset=GBK&method=alipay.trade.page.pay&sign=tXR6yxB9Uowu7tfbXVPHBoQXeyOqP2JXvo%2Fzmz%2BIA08aWin63h1%2FF7gHNN7I2K%2FnTV3ZYtvPkILu%2FS8uPmzpsAv1SJev0UNN2AbLDIGvbd%2BWn5neyIUqKcu5ySP1S8Bt4fbnvkMgnlijR25obVi5aFbj99JBZDrpeJ5cB9uq7Ccx7nX54%2F7coNv26PFD%2FPirlIKVVr2avD2w%3D%3D&return_url=http%3A%2F%2Fwww.shanshiwangluo.com%2F%23%2FpayNotify&notify_url=http%3A%2F%2Fwww.shanshiwangluo.com%2Fssmall%2Fportal%2Forder%2Fpay%2FaliCallback&version=1.0&app_id=2018062260383877&sign_type=RSA2&timestamp=2018-12-15+17%3A13%3A32&alipay_sdk=alipay-sdk-java-dynamicVersionNo&format=json">
<input type="hidden" name="biz_content" value="{    &quot;out_trade_no&quot;:&quot;1812141933252566&quot;,    &quot;product_code&quot;:&quot;FAST_INSTANT_TRADE_PAY&quot;,    &quot;total_amount&quot;:0.02,    &quot;subject&quot;:&quot; 订单：1812141933252566&quot;,    &quot;extend_params&quot;:{    &quot;sys_service_provider_id&quot;:&quot;2018062211454921&quot;    }  }">
<input type="submit" value="立即支付" style="display:none" >
</form>
<script>document.forms[0].submit();</script>`;
export const mockPayUrl =
  'https://openapi.alipay.com/gateway.do?charset=GBK&method=alipay.trade.page.pay&sign=tXR6yxB9Uowu7tfbXVPHBoQXeyOqP2JXvo%2Fzmz%2BIA08aWin63h1%2FF7gHNN7I2K%2FnTV3ZYtvPkILu%2FS8uPmzpsAv1SJev0UNN2AbLDIGvbd%2BWn5neyIUqKcu5ySP1S8Bt4fbnvkMgnlijR25obVi5aFbj99JBZDrpeJ5cB9uq7Ccx7nX54%2F7coNv26PFD%2FPirlIKVVr2avD2w%3D%3D&return_url=http%3A%2F%2Fwww.shanshiwangluo.com%2F%23%2FpayNotify&notify_url=http%3A%2F%2Fwww.shanshiwangluo.com%2Fssmall%2Fportal%2Forder%2Fpay%2FaliCallback&version=1.0&app_id=2018062260383877&sign_type=RSA2&timestamp=2018-12-15+17%3A13%3A32&alipay_sdk=alipay-sdk-java-dynamicVersionNo&format=json';

export const ENQUIRY_DETAIL_MOCK = {
  enquiryCode: 'XJ202207191623250000025',
  goodsId: 'b215cc80-0e14-4bb1-8d8d-d11fdf8535b1',
  goodsName: '含税价',
  url: 'https://shm-1256288681.cos.ap-nanjing.myqcloud.com/image/00000000-0000-0000-0000-000000000001/%E6%88%AA%E5%B1%8F2022-07-08%2012.48.33%20%282%292022071815555697491826.png',
  spec: {
    材质: '304',
    长度: '100',
  },
  amount: 1,
  price: 333333333,
  latestDeliveryDate: '2022-07-19 00:00:00.0',
  enquiryTime: '2022-07-19 16:23:25.089',
  enquiryStatus: 'offer_close',
  enquiryOrderStatus: null,
  orderId: null,
  picJson: [
    'https://shm-1256288681.cos.ap-nanjing.myqcloud.com/image/00000000-0000-0000-0000-000000000001/%E6%88%AA%E5%B1%8F2022-07-08%2012.48.33%20%282%292022071815555697491826.png',
  ],
  shopName: '工路商城',
  buyerName: '何晓文',
  specText: null,
  unit: '吨',
  invoiceNeed: 'yes',
  province: '河北省',
  city: '邯郸市',
  area: '复兴区',
  address:
    '浙江省杭州市临平区乔司街道三胜街 239 号 1 幢 709 室sdlfjs;ladfj收代理费;加速度;老费劲;沙拉的房间;落实到积分;落实到积分;落实到积分;落实到积分;猎杀对决发;猎杀对决发;猎杀对决发;猎杀对决;付了多久是;链接撒旦法;落实到积分;第十六届发;领导事假发;里睡大觉',
  receiveName: '工路（杭州）信息技术有限公司 许涛',
  receivePhone: '13322223232',
  remark: '红红火火恍恍惚惚或所多或付或所多或付或扩所多或付扩',
  shopId: '7aba43ca-bdec-4c9d-9d3d-188cb2b8c550',
  deliveryMethod: 'supplier_delivery',
  invoice: true,
  receiveInfo:
    '{"area":"复兴区","city":"邯郸市","houseNumber":"浙江省杭州市临平区乔司街道三胜街 239 号 1 幢 709 室sdlfjs;ladfj收代理费;加速度;老费劲;沙拉的房间;落实到积分;落实到积分;落实到积分;落实到积分;猎杀对决发;猎杀对决发;猎杀对决发;猎杀对决;付了多久是;链接撒旦法;落实到积分;第十六届发;领导事假发;里睡大觉","province":"河北省","receiverName":"工路（杭州）信息技术有限公司 许涛","receiverPhone":"13322223232"}',
  postFee: 0,
};

export const wuliuMock = {
  expressCode: '78644688762286',
  name: '中通快递',
  sendOutTime: '2022-12-22 14:23:04',
  received: true,
  logistics: [
    {
      time: '2022-12-23 16:07:05',
      context:
        '【杭州市】 您的快递已签收, 签收人在【菜鸟的杭州梧桐蓝山北门941号商铺店】领取。如有疑问请电联:（17367088010）, 投诉电话:（17758028249,）, 您的快递已经妥投。风里来雨里去, 只为客官您满意。上有老下有小, 赏个好评好不好？【请在评价快递员处帮忙点亮五颗星星哦~】',
      ftime: '2022-12-23 16:07:05',
      areaCode: 'CN330100000000',
      areaName: '浙江,杭州市',
      status: '签收',
      location: '',
      areaCenter: '120.15507,30.274084',
      areaPinYin: 'hang zhou shi',
      statusCode: '3',
    },
    {
      time: '2022-12-22 11:14:36',
      context:
        '【杭州市】 包裹已到达【菜鸟的杭州梧桐蓝山北门941号商铺店】，即将送货上门，如有疑问请联系（17758028249,）。',
      ftime: '2022-12-22 11:14:36',
      areaCode: null,
      areaName: null,
      status: '在途',
      location: '',
      areaCenter: null,
      areaPinYin: null,
      statusCode: '0',
    },
    {
      time: '2022-12-22 07:00:19',
      context:
        '【杭州市】 【杭州临平新城】 的XC新城周鑫发（17367088010） 正在第1次派件, 请保持电话畅通,并耐心等待（95720为中通快递员外呼专属号码，请放心接听）',
      ftime: '2022-12-22 07:00:19',
      areaCode: 'CN330110001000',
      areaName: '浙江,杭州市,余杭区,临平',
      status: '派件',
      location: '',
      areaCenter: '120.303038,30.419659',
      areaPinYin: 'lin ping jie dao',
      statusCode: '5',
    },
    {
      time: '2022-12-22 07:00:06',
      context: '【杭州市】 快件已经到达 【杭州临平新城】',
      ftime: '2022-12-22 07:00:06',
      areaCode: 'CN330100000000',
      areaName: '浙江,杭州市',
      status: '在途',
      location: '',
      areaCenter: '120.15507,30.274084',
      areaPinYin: 'hang zhou shi',
      statusCode: '0',
    },
    {
      time: '2022-12-22 04:47:01',
      context: '【嘉兴市】 快件离开 【浙江中通立通分拨】 已发往 【杭州临平新城】',
      ftime: '2022-12-22 04:47:01',
      areaCode: 'CN330000000000',
      areaName: '浙江',
      status: '在途',
      location: '',
      areaCenter: '120.152791,30.267446',
      areaPinYin: 'zhe jiang sheng',
      statusCode: '0',
    },
    {
      time: '2022-12-22 04:46:40',
      context: '【嘉兴市】 快件已经到达 【浙江中通立通分拨】',
      ftime: '2022-12-22 04:46:40',
      areaCode: 'CN330400000000',
      areaName: '浙江,嘉兴市',
      status: '在途',
      location: '',
      areaCenter: '120.755486,30.746129',
      areaPinYin: 'jia xing shi',
      statusCode: '0',
    },
    {
      time: '2022-12-22 00:14:59',
      context: '【上海市】 快件离开 【上海浦西转运中心】 已发往 【浙江中通立通分拨】',
      ftime: '2022-12-22 00:14:59',
      areaCode: 'CN310000000000',
      areaName: '上海',
      status: '干线',
      location: '',
      areaCenter: '121.473701,31.230416',
      areaPinYin: 'shang hai',
      statusCode: '1002',
    },
    {
      time: '2022-12-22 00:13:52',
      context: '【上海市】 快件已经到达 【上海浦西转运中心】',
      ftime: '2022-12-22 00:13:52',
      areaCode: 'CN310000000000',
      areaName: '上海',
      status: '干线',
      location: '',
      areaCenter: '121.473701,31.230416',
      areaPinYin: 'shang hai',
      statusCode: '1002',
    },
    {
      time: '2022-12-21 22:14:12',
      context: '【上海市】 快件离开 【上海沪西接驳站】 已发往 【浙江中通立通分拨】',
      ftime: '2022-12-21 22:14:12',
      areaCode: 'CN310000000000',
      areaName: '上海',
      status: '在途',
      location: '',
      areaCenter: '121.473701,31.230416',
      areaPinYin: 'shang hai',
      statusCode: '0',
    },
    {
      time: '2022-12-21 22:11:11',
      context: '【上海市】 快件已经到达 【上海沪西接驳站】',
      ftime: '2022-12-21 22:11:11',
      areaCode: 'CN310000000000',
      areaName: '上海',
      status: '在途',
      location: '',
      areaCenter: '121.473701,31.230416',
      areaPinYin: 'shang hai',
      statusCode: '0',
    },
    {
      time: '2022-12-21 22:11:10',
      context: '【上海市】 快件离开 【宝山刘行】 已发往 【上海沪西接驳站】',
      ftime: '2022-12-21 22:11:10',
      areaCode: null,
      areaName: null,
      status: '在途',
      location: '',
      areaCenter: null,
      areaPinYin: null,
      statusCode: '0',
    },
    {
      time: '2022-12-21 22:11:09',
      context: '【上海市】 【宝山刘行】（021-31080012） 的 自动分拣线（021-31080550） 已揽收',
      ftime: '2022-12-21 22:11:09',
      areaCode: null,
      areaName: null,
      status: '揽收',
      location: '',
      areaCenter: null,
      areaPinYin: null,
      statusCode: '1',
    },
  ],
};
export const mockPurchaseStatus = [
  {
    label: '全部采购单',
    value: '',
  },
  {
    label: '待处理',
    value: 'PENDING_PROCESS',
  },
  {
    label: '已报价',
    value: 'QUOTED',
  },
  {
    label: '已接受',
    value: 'ACCEPTED',
  },
  {
    label: '已下单',
    value: 'ORDERED',
  },
  {
    label: '已拒绝',
    value: 'REJECTED',
  },
  {
    label: '已取消',
    value: 'CANCELED',
  },
  {
    label: '已完成',
    value: 'FINISHED',
  },
];

export const refundListMock = [
  {
    id: '00000000-0000-0000-0000-000000000001',
    refundCode: '3434343434',
    refundType: 'refund_type',
    memberId: '买家id',
    memberName: '买家名',
    memberTel: '买家联系电话',
    shopId: 'shopId',
    shopName: 'shop_name',
    orderCode: 'SC202209231455100011743',
    applyRefundAmount: 3,
    realRefundAmount: 3,
    buyerApplyServiceAmount: 1,
    buyerRealServiceAmount: 1,
    buyerRealTotalAmount: 4,
    sellerApplyServiceAmount: 0,
    sellerRealServiceAmount: 0,
    reasonType: 'reason_type',
    reason: 'reason',
    isReceivedGoods: 'false',
    expressCode: 'express_code',
    expressName: 'express_name',
    sendCode: 'send_code',
    expressLinkPerson: '先生',
    expressLinkTel: '13445336678',
    province: '浙江省',
    city: '杭州市',
    area: '临平区',
    street: '乔司街道',
    address: '算力小镇100号',
    receiptLinkPerson: '李先生',
    receiptLinkTel: '13345336678',
    sellerRemark: 'seller_remark',
    platRemark: 'plat_remark',
    platIsUpdate: 'false',
    refundStatus: 'refund_status',
    goods: [
      {
        refundGoodsId: '00000000-0000-0000-0000-000000000001',
        goodsId: '00123456-0000-0000-0000-000000000004',
        imgUrl: 'http://...',
        goodsName: '螺纹钢',
        specName: '{"尺寸": "20*30"}',
        price: 75,
        amount: 1,
        subtotal: 75,
        refundAmount: 333,
        buyerServiceAmount: 22,
        sellerServiceAmount: 0,
      },
    ],
    imgs: [
      {
        fileName: '支付凭证',
        fileUrl: 'http://....',
      },
    ],
    attachments: [
      {
        fileName: '支付凭证',
        fileUrl: 'http://....',
      },
    ],
  },
];
