import { createElement, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'ice';
import _ from 'lodash';
import logo from '@/assets/min_gonglu.logo.png';
import mine_order_icon from '@/assets/mine_order_icon.png';
import mine_password_icon from '@/assets/mine_password_icon.png';
import mine_address_icon from '@/assets/mine_address_icon.png';
import mine_info_icon from '@/assets/mine_info_icon.png';
import navi_back_icon from '@/assets/navi_back_icon.png';
import avatar from '@/assets/profilepicture-01.png';
import { Popover, Avatar, Menu, Image, MenuProps, Modal, Button } from 'antd';
import appStore from '@/store';
import { SettingOutlined, MailOutlined, CalendarOutlined } from '@ant-design/icons';
import './index.less';
import { AUTH_TYPE } from '@/types';

type MenuItem = Required<MenuProps>['items'][number];
function getItem(
  label: React.ReactNode,
  key?: React.Key | null,
  icon?: React.ReactNode,
  children?: MenuItem[],
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const loopMenuItem = (menus) =>
  menus.map(({ icon, children, ...item }) => ({
    ...item,
    icon: createElement(icon),
    children: children && loopMenuItem(children),
  }));

const paths = [
  { path: '/workbench/purchase-detail', title: '采购单详情', showBack: true },
  { path: '/workbench/purchaseList', title: '我的采购单', showBack: false },
  { path: '/workbench/order', title: '我的订单', showBack: false },
  { path: '/workbench/order-detail', title: '订单详情', showBack: true },
  { path: '/workbench/orderRefund', title: '申请退款', showBack: true },
  { path: '/workbench/enquiryList', title: '我的询价单', showBack: false },
  { path: '/workbench/personAuth', title: '实名认证', showBack: false },
  { path: '/workbench/refund', title: '退换货/售后', showBack: false },
  { path: '/workbench/auth', title: '企业认证', showBack: false },
  { path: '/workbench/address', title: '收货地址', showBack: false },
  { path: '/workbench/payAccount', title: '付款账号', showBack: false },
  { path: '/workbench/receiptAccount', title: '线下收款账号', showBack: false },
  { path: '/workbench/refund-detail', title: '退款单详情', showBack: false },
];
const openKeys = ['mine', 'order', 'address', 'pay'];
export default function WorkbenchLayout({ children }) {
  const history = useHistory();
  const location = useLocation();
  console.log(location, 'location.pathnanme');
  const [purchaseListState, purchaseListActions] = appStore.useModel('purchaseList');
  const [userState, userActions] = appStore.useModel('user');

  const [refundstate, refundActions] = appStore.useModel('refund');
  const { refundOrder, reasons, refundDetail } = refundstate;
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [subTitle, setSubTitle] = useState('');
  const [showBack, setShowBack] = useState(false);
  useEffect(() => {
    userActions.getUserInfo(userState.id);
    userActions.getCompanyAuth();
    if (!purchaseListState.statusList.length) {
      purchaseListActions.getPurchaseListStatus();
    }
    if (!reasons.length) {
      refundActions.getCancelOrderReason();
    }
  }, []);
  useEffect(() => {
    const subTitle = paths.find((item) => item.path === location.pathname)?.title;
    const isShowBack = paths.find((item) => item.path === location.pathname)?.showBack;
    console.log(subTitle, 'subTitle', location.pathname);

    setSubTitle(subTitle || '');
    setShowBack(isShowBack || false);
    const path = location.pathname;
    if (location.pathname.indexOf('orderRefund') > 0) {
      setSelectedKeys(['/workbench/order']);
    } else if (path == '/workbench/order-detail') {
      setSelectedKeys(['/workbench/order']);
    } else if (path == '/workbench/refund-detail') {
      setSelectedKeys(['/workbench/refund']);
    } else {
      setSelectedKeys([location.pathname]);
    }
  }, [location]);
  const onHome = () => {
    history?.push('/');
  };
  const onBack = () => {
    history.goBack();
  };

  const loginOut = () => {
    userActions.resetUser();
  };
  const menus = useMemo(() => {
    const items: MenuItem[] = [
      getItem('个人信息', 'mine', <img src={mine_info_icon} />, [
        // getItem('个人资料', '/workbench/accountInfo'),
        getItem('实名认证', '/workbench/personAuth'),
        userState.authState !== AUTH_TYPE.UN_AUTH && userState.authState !== AUTH_TYPE.PERSON_AUDITING
          ? getItem('企业认证', '/workbench/auth')
          : null,
      ]),
    ];
    if (userState.authState === AUTH_TYPE.ENTERPRISE_APPROVED) {
      items.push(
        getItem('我的订单', 'order', <img src={mine_order_icon} />, [
          getItem('我的订单', '/workbench/order'),
          getItem('我的采购单', '/workbench/purchaseList'),
          getItem('我的询价单', '/workbench/enquiryList'),
          getItem('退换货/售后', '/workbench/refund'),
        ]),
        getItem('收货地址', 'address', <img src={mine_address_icon} />, [
          getItem('我的收货地址', '/workbench/address'),
        ]),
        getItem('支付管理', 'pay', <img src={mine_password_icon} />, [
          getItem('在线支付账号', '/workbench/payAccount'),
          getItem('线下收款账号', '/workbench/receiptAccount'),
        ]),
      );
    }
    return items;
  }, [userState.authState]);
  const content = (
    <div>
      <div className="pop-item" onClick={loginOut}>
        <SettingOutlined />
        退出登录
      </div>
    </div>
  );

  return (
    <div className={'workbench_container'}>
      <div className={'left'}>
        <div onClick={onHome} className="workbench-title">
          <Image preview={false} onClick={onHome} src={logo} />
        </div>
        <div className="menu">
          <Menu
            mode={'inline'}
            theme="light"
            style={{ width: 300 }}
            items={menus}
            selectedKeys={selectedKeys}
            openKeys={openKeys}
            onClick={({ key }) => {
              history.push(key);
            }}
          />
        </div>
      </div>
      <div className="content-container">
        <div className="navi">
          <div>
            <span className="navi-title">{subTitle}</span>
            {location.pathname === '/workbench/orderRefund' && (
              <span className="order-code">{`订单号${refundOrder.orderCode}`}</span>
            )}
            {location.pathname === '/workbench/refund-detail' && (
              <span className="order-code">{`退款编号${refundDetail.refundCode}`}</span>
            )}
          </div>
          <Popover content={content}>
            <div className="navi-avatar">
              <img src={avatar} />
            </div>
          </Popover>
        </div>

        <div className="content">
          {showBack && (
            <div onClick={onBack} className="navi-back">
              <img src={navi_back_icon} />
              <span className="navi-back__text">返回</span>
            </div>
          )}
          {children}
        </div>
      </div>
    </div>
  );
}
