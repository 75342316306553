/*
 * @Date: 2022-05-23 13:28:10
 * @LastEditors: royce
 * @LastEditTime: 2022-05-23 19:01:37
 * @FilePath: /mall-web/src/components/LoginView/index.tsx
 */
import { useEffect } from 'react';
import { Input, Space } from 'antd';
import appStore from '@/store';
import styles from './index.module.less';
import { PRIVACY_POLICY_URL, TERMS_OF_SERVICE_URL } from '@/const';
import { LockOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import { LoginModalType } from '@/models/global';
import SMSCounter from '../SMSCounter';

export default function LoginView({ changeUsername, changePassword, username, changeSMS }) {
  const [userState, userDispatchers] = appStore.useModel('user');
  const [globalState, globalDispatchers] = appStore.useModel('global');
  const { type } = globalState;
  useEffect(() => {}, []);
  const onSwitchLogin = () => {
    globalDispatchers.update({ type: LoginModalType.LOGIN });
    changeUsername({ target: { value: '' } });
    changePassword({ target: { value: '' } });
  };
  const onGetSMS = () => {
    userDispatchers.getSMSCode({ mobile: username, isAuth: false });
  };
  const onSwitchRegister = () => {
    changeUsername({ target: { value: '' } });
    changePassword({ target: { value: '' } });
    globalDispatchers.update({ type: LoginModalType.REGISTER });
  };
  const onSwitchReset = () => {
    changeUsername({ target: { value: '' } });
    changePassword({ target: { value: '' } });
    globalDispatchers.update({ type: LoginModalType.RESET_PASSWORD });
  };
  const onAgreeMent = () => {
    window.open(TERMS_OF_SERVICE_URL);
  };
  const onPrivacy = () => {
    window.open(PRIVACY_POLICY_URL);
  };

  return (
    <div className={styles.loginview}>
      <Space direction="vertical">
        <Input
          maxLength={11}
          key={`${type}phone`}
          onChange={changeUsername}
          prefix={<UserOutlined className="site-form-item-icon" />}
          placeholder="请输入手机号"
        />

        <Input
          key={`${type}password`}
          onChange={changePassword}
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          maxLength={128}
          placeholder={type === LoginModalType.RESET_PASSWORD ? '请输入新密码' : '请输入密码'}
        />
        {type !== LoginModalType.LOGIN ? (
          <div>
            <Input.Group compact>
              <Input
                maxLength={6}
                style={{ width: 'calc(100% - 125px)' }}
                onChange={changeSMS}
                prefix={<MailOutlined className="site-form-item-icon" />}
                placeholder="请输入验证码"
              />
              <SMSCounter phone={username} onSendSMS={onGetSMS} />
            </Input.Group>
          </div>
        ) : null}
      </Space>

      <div className={styles.modalChooseType}>
        {type !== LoginModalType.LOGIN && <span />}
        {type === LoginModalType.LOGIN && (
          <span>
            <span onClick={onSwitchRegister} className={styles.goLogin}>
              注册
            </span>
          </span>
        )}
        {type !== LoginModalType.LOGIN && (
          <span>
            <span onClick={onSwitchLogin} className={styles.goLogin}>
              去登录
            </span>
          </span>
        )}
        {type === LoginModalType.LOGIN && (
          <span>
            <span onClick={onSwitchReset} className={styles.goLogin}>
              忘记密码
            </span>
          </span>
        )}
      </div>
      <div className={styles.loginLink}>
        登录即同意
        <span onClick={onAgreeMent} className={styles.rule}>
          《工路网用户协议》
        </span>
        <span onClick={onPrivacy} className={styles.rule}>
          《工路网平台隐私政策》
        </span>
      </div>
    </div>
  );
}
