import { showSuccess, sleep } from '@/utils';

/*
 * @Date: 2022-05-24 15:47:16
 * @LastEditors: royce
 * @LastEditTime: 2022-06-20 16:53:10
 * @FilePath: /mall-web/src/models/address.ts
 */
import server from '../services/address';
import _ from 'lodash';

export interface AddressItem {
  receiveName: string;
  province: string;
  city: string;
  area: string;
  receivePhone: string;
  addressId?: string;
  street?: string;
  address: string;
  def?: boolean;
}

export default {
  state: {
    addressList: [],
    currentAddress: null,
    pageIndex: 1,
    hasNextPage: true,
    source: 'mine',
  },
  reducers: {
    update(prevState, payload) {
      return { ...prevState, ...payload };
    },
  },
  effects: (dispatch) => ({
    async getAddress(_, { user }) {

      const data = await server.getAddress({
        memberId: user.id,
        pageIndex: 1,
        pageSize: 100,
      });

      if (data.rows) {
        const res = data.rows.map((item: AddressItem) => {
          return {
            ...item,
            ssq: item.province + item.city + item.area,
          };
        });
        dispatch.address.update({
          addressList: res,
        });
      }
    },
    async updateAddress(data, { user }) {
      const res = await server.updateAddress({ memberId: user.id, ...data });

      await sleep(500);
      showSuccess('设置成功');
      dispatch.address.getAddress(user.id);
      // console.log(res, 'res');
    },
    async deleteAddress(addressId, { user }) {
      const res = await server.deleteAddress({ memberId: user.id, addressId });
      await sleep(500);
      showSuccess('删除成功');
      dispatch.address.getAddress(user.id);
    },
    async addAddress(data, { user }) {
      await server.addAddress({
        memberId: user.id,
        ...data,
      });
      await sleep(500);
      dispatch.address.getAddress(user.id);
      showSuccess('新增成功');
    },
  }),
};
