/*
 * @Date: 2022-04-07 13:52:14
 * @LastEditors: royce
 * @LastEditTime: 2022-06-14 13:27:14
 * @FilePath: /mall-web/src/services/global.ts
 * @LastEditTime: 2022-06-09 22:36:10
 * @FilePath: /mall-buyer-pc/src/services/global.ts
 */
import { config, request } from 'ice';
import _ from 'lodash';
import qs from 'qs';

const { BASE_URL } = config;

export default {
  async uploadFile(fileParams) {
    const params = _.get(fileParams, 'params');
    const data = _.get(fileParams, 'formData');
    return await request.post(`${BASE_URL}/shm/system/uploadTencentCloud?${qs.stringify(params)}`, data);
  },
  async getCityList() {
    return await request.get(`${BASE_URL}/shm/common/getLocation`);
  },
  //只获取省：PROVINCE ； 为空： 返回所有的地点
  async getCityLocations(params?: { type: string }) {
    return await request.get(`${BASE_URL}/shm/common/locations?${qs.stringify(params)}`);
  },
  async getYearSummery() {
    return await request.get(`${BASE_URL}/shm/marketing/getYearSummery?year=2022`);
  },
  async getMenuBqs() {
    return await request.get(`${BASE_URL}/shm/bqs`);
  },
  async getMenuBqsDetail(id) {
    return await request.get(`${BASE_URL}/shm/bqs/detail?bqsid=${id}`);
  },
  //获取商品分类列表
  async getCategoryList() {
    return await request.get(`${BASE_URL}/shm/category/list`);
  },
   //获取物流公司列表
   async getExpressCompany() {
    return await request.get(`${BASE_URL}/shm/express/companyList`);
  },
  //按计量单位类型获取单位
  async getUnitsByType(params?: { type?: 'Quantity' | 'Weight' | 'Volume' | 'Length' }) {
    return await request.get(`${BASE_URL}/shm/goods/getUnitsByType?${qs.stringify(params)}`);
  },
  // 获取banner
  async getBanner(params: { userId: string; bannerType: 'pc_index' | 'pc_centralizedPurchase_index' }) {
    return await request.get(`${BASE_URL}/shm/banner?${qs.stringify(params)}`);
  },
};
