/*
 * @Date: 2022-05-23 13:28:10
 * @LastEditors: royce
 * @LastEditTime: 2022-06-22 19:01:08
 * @FilePath: /mall-web/src/components/CartHeader/index.tsx
 */
import logo from '@/assets/logo.png';
import appStore from '@/store';
import { addOrUpdateQuery, showWarning } from '@/utils';
import { Button, Image, Input } from 'antd';
import { useHistory } from 'ice';
import { trim } from 'lodash';
import { useMemo, useState } from 'react';
import { Popover } from 'zent';
import styles from './index.module.less';

export interface AeraOption {
  value: string;
  label: string;
  children?: AeraOption[];
}

export default function CartHeader(props) {
  const [keyWords, setKeyWords] = useState('');
  const [globalState, globalDispatchers] = appStore.useModel('global');
  const [cartState, _] = appStore.useModel('cart');
  const [goodslist, goodslistDispatchers] = appStore.useModel('goodslist');
  const { searchHistory, orderType, sortType } = goodslist;
  const history = useHistory();
  const [showHistory, setShowHistory] = useState(false);
  const countTotal = useMemo(() => {
    if (cartState.cartList.length > 0) {
      return cartState.cartList.reduce((total, item) => {
        console.log(item, 'item.shopGoods');
        return total + item.shopGoods.length;
      }, 0);
    }
    return 0;
  }, [cartState.cartList]);
  const onChange = (value: string[], option) => {
    if (option && option.length) {
      globalDispatchers.update({
        currentArea: [option[0].name, option[1].name, option[2].name],
      });
    }
  };

  const onHome = async () => {
    history.push('/');
  };
  const searchAll = () => {
    if (!keyWords) {
      showWarning('请输入搜索条件');
      return;
    }
    history.push(`/goods?keyWords=${trim(keyWords)}`, { searchfrom: '/cart' });
    goodslistDispatchers.update({ categorys: [], goodCategory: '' });
  };


  const onChangeSearch = (event) => {
    setKeyWords(event.target.value);
  };
  const onHistoryItem = (item: string) => {
    setShowHistory(false);
    if (location.pathname === '/goods') {
      addOrUpdateQuery('keyWords', item);
      goodslistDispatchers.getGoodsList({ pageIndex: 1, orderType, sortType, keyWords: item });
    } else {
      history.push(`/goods?keyWords=${item}`, { searchfrom: '/' });
    }
    goodslistDispatchers.update({ categorys: [] });
  };
  const onClearSearchHistory = () => {
    goodslistDispatchers.resetSearchHistory();
  };


  return (
    <div className={styles.searchContainer}>

      <div className={styles.searchBody}>
        <div className={styles.logoContainer}>
          <Image preview={false} onClick={onHome} className={styles.logo} src={logo} />
          <span className={styles.logoTitle}>工路</span>
          <span className={styles.jsTitle}>{'购物车'}</span>
        </div>


        <div className={styles.searchInp}>
          <div className={styles.searchRow}>
            <Popover
              onVisibleChange={(v) => {
                setShowHistory(v);
              }}
              visible={showHistory}
              position={Popover.Position.BottomLeft}
            >
              <Popover.Trigger.Click>
                <Input
                  size="large"
                  className={styles.queryInp}
                  placeholder="请输入商品名称"
                  onPressEnter={searchAll}
                  onChange={onChangeSearch}
                />
              </Popover.Trigger.Click>
              <Popover.Content >
                {searchHistory.length !== 0 ?
                  (
                    <div className={styles.searchHistory}>
                      <div className={styles.historyTitle}>
                        <span>搜索记录</span>
                        <span className={styles.historyTitleRight} onClick={onClearSearchHistory}>清空历史记录</span>
                      </div>
                      <ul className={styles.historyContent}>
                        {searchHistory.map((item) => {
                          return (
                            <li
                              className={styles.historyItem}
                              onClick={() => {
                                onHistoryItem(item);
                              }}
                              key={item}
                              title={item}
                            >{item}
                            </li>
                          );
                        })}
                      </ul>
                    </div>)
                  : null
                  }
              </Popover.Content>
            </Popover>
            <Button onClick={searchAll} className={styles.searchBtn} size="large" type="primary">
              搜索
            </Button>

          </div>

          {/* <div>{'热词'}</div> */}
        </div>
      </div>
      <div className={styles.tLine}>
        <span className={styles.headerTolal} >{`全部商品 ${countTotal}`}</span>
        <div className={`${styles.sendTo} flex_r_c`}>
        </div>
      </div>
    </div>
  );
}

