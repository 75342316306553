import LayoutHeaderSearch from '@/components/LayoutHeaderSearch';
import ShopHeader from '@/components/ShopHeader';
import CartHeader from '@/components/CartHeader';
import SimpleHeader from '@/components/SimpleHeader';
export enum URL_PATH {
  HOME = '/',
  GOODS = '/goods',
  ENQUIRY = '/enquiry',
  PURCHASE = '/purchase',
  PURCHASE_CENTER = '/purchaseCenter',
  PURCHASE_LIST = '/purchaseList',
  CATEGORIES = '/categories',
  GOODS_DETAIL = '/goods-detail',
  SHOP = '/shop',
  SHOP_INDEX = '/shopIndex',
  CART = '/cart',
  SUBMIT = '/submit',
  ORDER = '/order',
  ADDRESS = '/address',
  AUTH = '/auth',
  PERSON_AUTH = '/personAuth',
  PAY_ACCOUNT = '/payAccount',
  CONTRACT = '/contract',
  ENQUIRY_LIST = '/enquiryList',
  ORDER_DETAIL = 'order-detail',
  ENQUIRY_DETAIL = 'enquiry-detail',
  PURCHASE_DETAIL = 'purchase-detail',
}

const showMenuPaths = [URL_PATH.HOME, URL_PATH.GOODS, URL_PATH.CATEGORIES, URL_PATH.ENQUIRY];

const renderTitleView = (pathname: URL_PATH) => {
  let node: any = null;
  switch (pathname) {
    case URL_PATH.HOME:
    case URL_PATH.GOODS:
    case URL_PATH.ENQUIRY:
    case URL_PATH.CATEGORIES:
    case URL_PATH.PURCHASE:
      node = <LayoutHeaderSearch />;
      break;
    case URL_PATH.SHOP:
    case URL_PATH.GOODS_DETAIL:
    case URL_PATH.SHOP_INDEX:
      node = <ShopHeader />;
      break;
    case URL_PATH.CART:
      node = <CartHeader />;
      break;
    case URL_PATH.SUBMIT:
      node = <SimpleHeader />;
      break;
    default:
      break;
  }
  return node;
};
const isShowMenu = (pathname: string) => {
  return !!showMenuPaths.find((path) => path === pathname);
};
export { renderTitleView, isShowMenu };
