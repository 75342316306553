/*
 * @Date: 2022-04-07 13:52:14
 * @LastEditors: royce
 * @LastEditTime: 2022-06-23 12:25:31
 * @FilePath: /mall-web/src/config.ts
 */
// https://app-mobile.gonglu.info:8763
const config = {
  // 本地调试
  dev: {
    appId: 'dev-idqq',
    // eslint-disable-next-line @iceworks/best-practices/no-http-url
    //94 82 18
    BASE_URL: 'http://192.168.1.94:8761',
  },
  // Gitlab集成开发BASE_URL: 'http://192.168.1.200:2222 https://app-mobile.gonglu.info:8763
  cicd: {
    BASE_URL: 'http://192.168.1.222:8761',
  },
  // Jenkins集成测试
  beta: {
    BASE_URL: 'http://192.168.1.200:2222',
    env:'release'
  },
  prod: {
    BASE_URL: 'https://shm.gonglu.info',
    env:'release'
  },
  default: {
    appId: 'default-id',
    sercet: 'hahjhjhj',
  },
};

export default config;
