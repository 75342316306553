/*
 * @Date: 2022-05-23 18:41:08
 * @LastEditors: royce
 * @LastEditTime: 2022-06-27 11:31:26
 * @FilePath: /mall-web/src/services/cart.ts
 */
import { config, request } from 'ice';
import qs from 'qs';


const {
  BASE_URL,
} = config;

export default {
  async addCart(data) {
    const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
    return request.post(`${BASE_URL}/shm/shoppingCart/${data.memberId}`, data, { headers });
  },
  async getCart(data) {
    return request.get(`${BASE_URL}/shm/shoppingCart/${data.memberId}/shop?${qs.stringify(data)}`, data);
  },
  async getAllCart(data) {
    return request.get(`${BASE_URL}/shm/shoppingCart/${data.memberId}?${qs.stringify(data)}`, data);
  },
  async cartUpdate(data) {
    return request.put(`${BASE_URL}/shm/shoppingCart/${data.memberId}`, data);
  },
  async cartDelete(data) {
    return request.delete(`${BASE_URL}/shm/shoppingCart/${data.memberId}`, { data });
  },
};
