/*
 * @Date: 2022-04-07 13:52:14
 * @LastEditors: royce
 * @LastEditTime: 2022-06-22 15:44:53
 * @FilePath: /mall-web/src/models/goods.ts
 */
import server from '@/services/goods';
import { IRootDispatch } from '@/store';
import { DETAIL_BUY_TYPE, GoodSkuItem } from '@/types';
import { htdb } from '@/utils/htdb';
import { showSuccess } from '@/utils/index';

export default {
  state: {
    goodsResult: {
      category: [],
      goodStatus: 'on_shift',
      status: '1',
      goodsUnit: '',
      goodsId: '',
      excludeTaxPrice: 0,
      marketPrice: 0,
      name: '',
      banner: [],
      goodsDescList: [{ url: '' }],
      shopName: '',
      shopId: '',
      shopAddress: '',
      thumb: [],
      price: 0,
    },
    buyType: DETAIL_BUY_TYPE.CHOOSE,
    selectedSpecs: {
      spec1: '',
      spec2: '',
      spec3: '',
    },
    skulist: [] as GoodSkuItem[],
    specList: [],
  },
  reducers: {
    update(prevState, payload) {
      return { ...prevState, ...payload };
    },
    updateSku: (state, payload) => {
      state.skulist.forEach((sku: GoodSkuItem) => {
        if (sku.sunGoodsId === payload.sunGoodsId) {
          sku.count = payload.count;
        }
      });
    },
    resetSku: (state) => {
      state.skulist.forEach((sku: GoodSkuItem) => {
        sku.count = 0;
      });
    },
    resetSkuEnquiry: (state) => {
      state.skulist.forEach((sku: GoodSkuItem) => {
        sku.selected = false;
      });
    },
    updateSkuSelected: (state, payload) => {
      state.skulist.forEach((sku: GoodSkuItem) => {
        sku.selected = sku.sunGoodsId === payload.sunGoodsId;
      });
    },
    reset() {
      return {
        goodsResult: { name: '', banner: [], goodsDescList: [{ url: '' }], shopName: '', shopId: '', thumb: [] },
        buyType: DETAIL_BUY_TYPE.CHOOSE,
        selectedSpecs: {
          spec1: '',
          spec2: '',
          spec3: '',
        },
        skulist: [],
        specList: [],
      };
    },
  },
  effects: (dispatch: IRootDispatch) => ({
    async getGoodsDetail(goodsId, { user }) {
      const res = await server.getGoodsDetail(goodsId);
      dispatch.goods.update({
        goodsResult: res,
        skulist: res.sunGoodsList.map((item) => {
          const { unitEach, priceUnit, marketPrice } = item;
          item.count = 0;
          if (!unitEach) {
            item.unitEach = 1;
          }
          if (!priceUnit) {
            item.priceUnit = marketPrice;
          }
          return item;
        }),
        specList: res.specList,
      });
      dispatch.shop.getCategoryListByShopId(res.shopId);
      dispatch.shop.update({ shopName: res.shopName, shopId: res.shopId });
      const obj = await htdb.goods.get({ goodsId, userId: user.id });
      if (obj) {
        obj.time = new Date().getTime();
        htdb.goods.put(obj);
      } else {
        htdb.goods.add({
          goodsId: res.goodsId,
          name: res.name,
          price: res.price,
          image: res.thumb[0],
          userId: user.id,
          time: new Date().getTime(),
          goodsUnit: res.goodsUnit,
        });
      }
    },
    async addGoodToCart(data) {
      try {
        await server.addCart(data);
        dispatch.global.getCartAllList();

        showSuccess('添加成功');
        console.log(data, 'data');
      } catch (error) {
        // showErrorToast(error);
      }
    },
  }),
};
