/*
 * @Date: 2022-05-23 18:38:56
 * @LastEditors: royce
 * @LastEditTime: 2022-06-07 17:14:57
 * @FilePath: /mall-web/src/services/catalog.ts
 */

import { request, config } from 'ice';


const {
  BASE_URL,
} = config;

export default {
  async getCatalogList(parentId) {
    let url = `${BASE_URL}/shm/categoryList?channl=PC`;
    if (parentId) {
      url += `&parentId=${parentId}`;
    }
    return request.get(url);
  },

};
