import { useEffect, useState } from 'react';
import testqrcode from '@/assets/testqrcode.png';
import styles from './index.module.less';
import { BEI_AN1, BEI_AN2, BEI_AN_ICP } from '@/const';

const footerContent = [
  {
    title: '帮助中心',
    values: [
      { text: '购买流程', url: '#' },
      { text: '常见问题', url: '#' },
      { text: '联系客服', url: '#' },
    ],
  },
  {
    title: '售后服务',
    values: [
      { text: '退换货说明', url: '#' },
      { text: '签收说明', url: '#' },
      { text: '投标建议', url: '#' },
    ],
  },
  {
    title: '关于我们',
    values: [
      { text: '在线客服', url: '#' },
      { text: '联系我们', url: '#' },
      { text: '关于我们', url: '#' },
    ],
  },
  {
    title: '生态链体系',
    values: [
      { text: '供应链', url: '#' },
      { text: '产品链', url: '#' },
      { text: '服务链', url: '#' },
    ],
  },
];

export default function LayoutFooter() {
  const onWangAn = () => {
    window.open('https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33011302000219');
  };
  const onIcpRecords = () => {
    window.open('https://beian.miit.gov.cn');
  };
  const onIcpLicence = () => {
    window.open('https://static.gonglu.info/common/icp.png');
  };
  return (
    <div className={styles.footer}>
      <div className={styles.footerContainer}>
        <div className={styles.footerContent}>
          <div className={styles.footerList}>
            {footerContent.map((item, index) => {
              return (
                <ul key={item.title} className={styles.footerListitem}>
                  <li className={styles.footerItemTitle}>{item.title}</li>
                  {item.values.map((value, idx) => {
                    return (
                      <li key={value.text} className={styles.footerTextItem}>
                        {value.text}
                      </li>
                    );
                  })}
                </ul>
              );
            })}
            <div className={styles.footerQrCodeImage}>
              {/* <div className={styles.footerQrCodeImageItem}>
                <span className={styles.qrText}>官方微信</span>
                <img src={testqrcode} className={styles.footerQrCode} />
              </div> */}
              <div className={styles.footerQrCodeImageItem}>
                <span className={styles.qrText}>官方APP</span>
                <img src={testqrcode} className={styles.footerQrCode} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.beianContainer}>
        <p>
          工路（杭州）信息技术有限公司
          <a target="_blank" href={BEI_AN1} className={styles.icp}>
            浙公网安备 33011302000219号
          </a>
          <a target="_blank" href={BEI_AN2} className={styles.icp}>
            浙ICP备2021022176号-2
          </a>
          <a target="_blank" href={BEI_AN_ICP} className={styles.icp}>
            增值电信业务经营许可证:浙B2-20220516
          </a>
        </p>
        <p>© 2021-2022 杭州工路 版权所有</p>
      </div>
    </div>
  );
}
