import appStore from '@/store';
import { AUTH_TYPE, commonEnumDes, COMPANY_AUTH_TYPE } from '@/types';
import { showWarning } from '@/utils';
import logo from '@/assets/purchase_logo.png';
import mineIcon from '@/assets/mine_icon.png';
import { Popover } from 'antd';
import { useHistory, useLocation } from 'ice';
import { useEffect, useMemo } from 'react';
import styles from './index.module.less';
import { SettingOutlined } from '@ant-design/icons';
const menuList = [
  { title: '首页', path: '/' },
  { title: '商品', path: '/goods' },
  { title: '集采', path: '/purchaseCenter' },
  { title: '个人中心', path: '/workbench/order' },
];
export default function LayoutHeaderV2() {
  const history = useHistory();
  const location = useLocation();
  const [userState, userDispatchers] = appStore.useModel('user');
  const { authState, auth, lastApplyInfo } = userState;
  const [globalState, globalDispatchers] = appStore.useModel('global');
  const { activeMenuV2 } = globalState;
  const showPersonAuth = authState === AUTH_TYPE.UN_AUTH && !!userState.id;
  const showCompanyAuth = authState > AUTH_TYPE.PERSON_AUDITING;
  const displayName = useMemo(() => userState.userName || userState.nickName, [userState.userName, userState.nickName]);

  useEffect(() => {
    const menu = menuList.find((item) => item.path === location.pathname);
    if (menu) {
      globalDispatchers.update({ activeMenuV2: menu.title });
    }
    console.log(menu, 'menu', location.pathname);
  }, [location.pathname]);
  const onLogin = () => {
    globalDispatchers.showLogin();
  };
  const onLogout = () => {
    userDispatchers.resetUser();
  };

  const onMine = async () => {
    const { flag, msg } = await globalDispatchers.checkLogin();
    if (!flag) {
      showWarning(msg);
      return;
    }
    history.push('/workbench/order');
  };
  // const authDes = useMemo(() => {
  //   const isLastFail =
  //     lastApplyInfo.checkStatus === COMPANY_AUTH_TYPE.FAIL || lastApplyInfo.checkStatus === COMPANY_AUTH_TYPE.CHECKING;
  //   const isCompanyAuth = authState === AUTH_TYPE.ENTERPRISE_APPROVED;
  //   if (isLastFail && !isCompanyAuth) {
  //     return commonEnumDes[lastApplyInfo.checkStatus];
  //   }
  //   return commonEnumDes[authState];
  // }, [authState, lastApplyInfo]);

  const onHome = async () => {
    history.push('/');
    globalDispatchers.update({ activeMenu: '' });
  };

  const onMenuBtn = (item) => {
    const { title } = item;
    globalDispatchers.update({ activeMenuV2: title });
    history.push(item.path);
  };
  const content = (
    <div>
      <div className={styles.popItem} onClick={onMine}>
        个人中心
      </div>
      <div className={styles.popItem} onClick={onLogout}>
        退出登录
      </div>
    </div>
  );

  return (
    <div className={styles.headerContainer}>
      <div className={styles.content}>
        <img onClick={onHome} src={logo} />
        <div className={styles.menuBtns}>
          {menuList.map((item) => {
            return (
              <span
                onClick={() => {
                  onMenuBtn(item);
                }}
                key={item.title}
                className={`${styles.menuItem} ${activeMenuV2 === item.title ? styles.active : ''}`}
              >
                {item.title}
              </span>
            );
          })}
        </div>
        <Popover content={content}>
          <div className={styles.mineInfo}>
            <img src={mineIcon} />
          </div>
        </Popover>
      </div>
    </div>
  );
}
