/*
 * @Author: your name
 * @Date: 2022-01-18 11:07:20
 * @LastEditTime: 2022-06-23 18:55:57
 * @LastEditors: royce
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /mall-web/src/components/OrderItemView/index.tsx
 */
import { Col, Image, Row, Tooltip } from 'antd';
import appStore from '@/store';
import { OrderGoodsItem } from '@/models/order';
import { Checkbox, NumberInput } from 'zent';
import { times } from 'number-precision';
import { useMemo } from 'react';
import _ from 'lodash';
import './index.less';
import { commonEnumDes, REFUND_TYPE } from '@/types';
import { QuestionCircleOutlined } from '@ant-design/icons';

export default function RefundGoods(props: {
  goodsList?: OrderGoodsItem[];
  refundType: REFUND_TYPE;
  shouldReturnAll: boolean;
}) {
  const { goodsList, refundType, shouldReturnAll } = props;
  const isReturnGoods = refundType === REFUND_TYPE.RETURN_REFUND;
  const refundActions = appStore.useModel('refund')[1];
  const goodsTitles = [
    { span: 2, title: '商品图片' },
    { span: 4, title: '商品名称' },
    { span: 4, title: '商品规格' },
    { span: 3, title: '单价' },
    { span: 2, title: '件数' },
    { span: 2, title: '总数' },
    { span: 4, title: '商品小计' },
    { span: 2, title: '退货件数' },
  ];
  console.log(goodsList, 'goodsList');
  const valueSpans = [2, 4, 4, 3, 2, 2, 4, 2];
  const allChecked = useMemo(() => {
    return goodsList && !goodsList.find((item) => !item.check);
  }, [goodsList]);

  return (
    <div className="refund-goods-container">
      <Row justify="center" align="middle" className="order-refund-goods-header">
        <Col className="col" span={1}>
          {shouldReturnAll
            ? null
            : isReturnGoods && (
                <Checkbox
                  checked={allChecked}
                  onChange={(e) => {
                    refundActions.checkAll({ checked: e.target.checked });
                  }}
                />
              )}
        </Col>
        <>
          {goodsTitles.map((item) => {
            if (item.title === '商品小计') {
              return (
                <Tooltip title={'商品小计 = 商品单价 x 商品数量'}>
                  <Col key={item.title} className="col" span={item.span}>
                    {item.title} <QuestionCircleOutlined style={{ color: '#999' }} />
                  </Col>
                </Tooltip>
              );
            }
            return (
              <Col key={item.title} className="col" span={item.span}>
                {item.title}
              </Col>
            );
          })}
        </>
      </Row>
      {goodsList &&
        goodsList.map((goods) => {
          const { goodsName, price, count, check, selectCount, goodsId, goodsUnit, measureMethod } = goods;
          const priceUnit = _.defaultTo(goods.priceUnit, price);
          const unitEach = _.defaultTo(goods.unitEach, 1);

          const skuTotal = times(price, count).toFixed(2);
          let img = '';
          if (goods.goodsPic && goods.goodsPic.length) {
            img = JSON.parse(goods.goodsPic)[0];
          }
          const specTitle = _.defaultTo(goods.specTitle, {});
          const specKeys = Object.keys(specTitle);
          return (
            <Row key={goodsId} justify="center" align="middle">
              <Col className="goods-value" span={1}>
                {shouldReturnAll
                  ? null
                  : isReturnGoods && (
                      <Checkbox
                        checked={check}
                        onChange={(e) => {
                          refundActions.updateGoods({ checked: e.target.checked, goodsId });
                        }}
                      />
                    )}
              </Col>
              <Col className="goods-value" span={valueSpans[0]}>
                {<Image preview={false} style={{ width: 60, height: 60 }} src={img} />}
              </Col>
              <Col className="goods-value" span={valueSpans[1]}>
                {goodsName}
              </Col>
              <Col className="goods-value" span={valueSpans[2]}>
                <span>
                  {`${unitEach}${goodsUnit}/件 `}
                  {measureMethod ? commonEnumDes[measureMethod] : ''}
                  {specKeys.map((k) => {
                    return (
                      <span key={`${k}`}>
                        {k}: {specTitle[k]}
                      </span>
                    );
                  })}
                </span>
              </Col>
              <Col className="goods-value" span={valueSpans[3]}>
                ¥{priceUnit.toFixed(2)}/{goodsUnit}
              </Col>
              <Col className="goods-value" span={valueSpans[4]}>
                {count}
              </Col>
              <Col className="goods-value" span={valueSpans[5]}>
                {times(count, unitEach)}/{goodsUnit}
              </Col>
              <Col className="goods-value" span={valueSpans[6]}>
                {skuTotal}
              </Col>
              <Col className="goods-value" span={valueSpans[7]}>
                {isReturnGoods && (
                  <NumberInput
                    className="input"
                    disabled={shouldReturnAll}
                    value={selectCount}
                    max={count}
                    min={1}
                    placeholder="请输入退货数量"
                    showTooltip
                    onChange={(value) => {
                      let c = parseInt(value);
                      if (c > count) {
                        c = count;
                      }
                      if (c < 0) {
                        c = 1;
                      }
                      refundActions.updateCount({ selectCount: c, goodsId });
                    }}
                  />
                )}
              </Col>
            </Row>
          );
        })}
    </div>
  );
}
