import userConfig from '@/config';

interface Config {
  readonly [propName: string]: any;
}

interface IUserConfig {
  readonly default?: any;
  readonly [key: string]: any;
}
//
const globalPolyfill =
  typeof globalThis !== 'undefined'
    ? globalThis
    : typeof window !== 'undefined'
    ? window
    : typeof global !== 'undefined'
    ? global
    : typeof self !== 'undefined'
    ? self
    : {};
const config: Config = {
  ...((userConfig as IUserConfig).default || {}),
  ...(userConfig[
    (globalPolyfill as any).__app_mode__ || process.env.APP_MODE
  ] || {}),
};

const APP_MODE = (globalPolyfill as any).__app_mode__ || process.env.APP_MODE;

export { config, APP_MODE };
