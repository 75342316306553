import { OrderRefundRequest, RefundDetailRequest, RefundListRequest, RefundPostGoodsRequest } from '@/interface/request';
import { config, request } from 'ice';
import { pickBy } from 'lodash';
import qs from 'qs';

const { BASE_URL } = config;

export default {
  async submitRefund(param:OrderRefundRequest) {
    return await request.post(`${BASE_URL}/shm/refund/apply`,param);
  },
  async returnGoods(param:OrderRefundRequest) {
    return await request.post(`${BASE_URL}/shm/refund/buyer/returned`,param);
  },
  async cancelRefund(param) {
    return await request.post(`${BASE_URL}/shm/refund/close`,param);
  },
  async getRefundReasons() {
    return await request.get(`${BASE_URL}/shm/refund/getReasonType`);
  },
  async getRefundStatus() {
    return await request.get(`${BASE_URL}/shm/refund/getStatus`);
  },
  async getRefundDetail(param:RefundDetailRequest) {
    return await request.get(`${BASE_URL}/shm/refund/detail?${qs.stringify(param)}`);
  },
  async getReturnExpressInfo(param) {
    return await request.get(`${BASE_URL}/shm/express/logistics/info?${qs.stringify(param)}`);
  },
  async getRefundList(param) {
    return await request.get(`${BASE_URL}/shm/refund/list?${qs.stringify(param)}`);
  },
   postGoods(param:RefundPostGoodsRequest) {
    return  request.post(`${BASE_URL}/shm/refund/buyer/returned`,param);
  },
  async getRefundPayVoucher(refundId) {
    return await request.get(`${BASE_URL}/shm/payVoucher/list?refundId=${refundId}`);
  },

  async checkOfflineRefund(param) {
    return await request.post(`${BASE_URL}/shm/payVoucher/check`,param);
  },
}
