import { openPath } from './../const';

/*
 * @Date: 2022-04-07 13:52:14
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-06-27 18:56:38
 * @FilePath: /mall-web/src/models/user.ts
 */
import server from '@/services/user';
import { IRootState } from '@/store';
import { history } from 'ice';
import { AUTH_TYPE, CONTRACT_TYPE } from './../types';
import { getAuthEnum, showErrorToast, showSuccess, showToast } from './../utils/index';

export interface AuthState {
  personCertificate: boolean;
  personConfirm: boolean;
  enterpriseAuthorize: boolean;
  enterpriseCertificate: boolean;
  enterpriseConfirm: boolean;
  businessLicensePhoto: string;
  companyAddress: string;
  companyName: string;
  legalIdNo: string;
  legalMobile: string;
  legalName: string;
  mobile: string;
  unifiedSocialCreditCode: string;
  realName: string;
}
export interface UserState {
  token: string;
  id: string;
  nickName: string;
  userName: string;
  signed: boolean;
  auth: AuthState;
  authState: AUTH_TYPE;
  contractType: string;
  wxCode: string;
}

export default {
  state: {
    token: localStorage.getItem('token'),
    id: localStorage.getItem('memberId') || '',
    userName: '',
    nickName: '',
    signed: false,
    authState: AUTH_TYPE.UN_AUTH,
    contractType: CONTRACT_TYPE.PERSON_TYPE,
    wxCode: '',
    tenantId:'',
    auth: {
      personCertificate: false,
      personConfirm: false,
      enterpriseAuthorize: false,
      enterpriseCertificate: false,
      enterpriseConfirm: false,
      businessLicensePhoto: '',
      companyAddress: '',
      companyName: '',
      legalIdNo: '',
      legalMobile: '',
      legalName: '',
      unifiedSocialCreditCode: '',
      mobile: '',
      realName: '',
    },
    lastApplyInfo: {
      legalMobile: '',
      legalIdNo: '',
      legalName: '',
      companyName: '',
      unifiedSocialCreditCode: '',
      businessLicensePhoto: '',
      companyAddress: '',
      type: '',
      checkStatus: 0,
      memo: '',
    },
  },
  reducers: {
    update(prevState, payload) {
      const state = { ...prevState, ...payload };
      return state;
    },
  },
  effects: (dispatch) => ({
    async userLogin(params) {
      try {
        const res = await server.userLogin({ type: 'member', ...params });
        console.log('userLogin', res);
        const token = `${res.jwt.token_type} ${res.jwt.access_token}`;
        const userObj = {
          token,
          id: res.user.id,
          userName: res.user.userName,
        };
        dispatch.user.update(userObj);
        localStorage.setItem('token', token);
        localStorage.setItem('memberId', res.user.id);
        showSuccess('登录成功');
        location.reload();
      } catch (error) {
        return error;
      }
    },
    async getSMSCode({ mobile, isAuth }) {
      try {
        await server.getCode({ mobileNumber: mobile, isAuth });
        showSuccess('验证码已发送');
      } catch (error) {
        showErrorToast('验证码发送失败');
      }
    },
    async getUserInfo(id) {
      const res = await server.getUserInfo(id);
      console.log(res, 'getUserInfo');
      dispatch.user.update(res);
    },
    // 个人认证
    async personAuth(data, rootState) {
      try {
        const { user } = rootState;
        await server.personAuth({ memberId: user.id, ...data, verifyFlag: false });
        dispatch.user.getUserInfo(user.id);
        dispatch.user.getCompanyAuth();
        history && history.push('/workbench/auth');
        showToast('认证成功');
      } catch (error) {
        // showErrorToast(error.message);
      }
    },
    // 企业认证
    async companyAuth(data, rootState) {
      try {
        const { user } = rootState;
        const res = await server.companyAuth({ memberId: user.id, ...data });
        if (res) {
          dispatch.user.getUserInfo(user.id);
          dispatch.user.getCompanyAuth();
          showToast('提交成功');
        }
        dispatch.user.getLastApply();
        return res;
      } catch (error) {
        dispatch.user.getLastApply();
      }
    },
    async getCompanyAuth(_, rootState) {
      const { user } = rootState;
      if (!user.token) {
        return;
      }

      const res = await server.getCompanyAuth({ memberId: user.id });

      dispatch.user.update({
        auth: res,
      });
      const { personCertificate, personConfirm, enterpriseAuthorize, enterpriseCertificate, enterpriseConfirm } = res;
      const { status } = getAuthEnum({
        pcer: personCertificate,
        pcon: personConfirm,
        cera: enterpriseAuthorize,
        ccer: enterpriseCertificate,
        cerc: enterpriseConfirm,
      });
      dispatch.user.update({
        authState: status,
      });
      if (status === AUTH_TYPE.ENTERPRISE_APPROVED) {
        await server.companySign({ memberId: user.id });
        dispatch.user.update({ contractType: CONTRACT_TYPE.COMPANY_TYPE });
      } else if (status >= AUTH_TYPE.PERSON_APPROVED) {
        await server.personSign({ memberId: user.id });
        dispatch.user.update({ contractType: CONTRACT_TYPE.PERSON_TYPE });
      }
    },
    // 法人授权
    async legalAuth(data, { user }) {
      try {
        // await server.checkCode(data);
        await server.legalAuth({ memberId: user.id });
        dispatch.user.getUserInfo(user.id);
        dispatch.user.getCompanyAuth();
        showSuccess('认证成功');
      } catch (error) {}
    },

    // 注册
    async register(data) {
      try {
        const res = await server.userRegister(data);
        console.log('register', res);
        showToast('注册成功');
        setTimeout(() => {
          dispatch.user.userLogin({ username: data.phone, password: data.password });
        }, 1000);
      } catch (error) {
        return error;
      }
    },
    // 重置密码
    async resetPwd(data) {
      try {
        await server.resetPwd(data);

        showToast('重置成功,请重新登录');
      } catch (error) {
        return error;
      }
    },
    async userLoginByPassword(data) {
      try {
        const { username, password } = data;
        const res = await server.userRegister({ username, password });
        const token = `${res.data.jwt.token_type} ${res.data.jwt.access_token}`;
        const userObj = {
          access_token: token,
          id: res.data.user.id,
          userName: res.data.user.userName,
        };

        dispatch.user.update({
          user: userObj,
        });
        localStorage.setItem('token', token);
        localStorage.setItem('memberId', res.data.user.id);
        showToast('登录成功');

        location.reload();
      } catch (error) {
        showErrorToast('验证码发送失败');
      }
    },

    async sendVerCode(phone) {
      const data = await server.getUserInfo(phone);
      return data;
    },
    async getLastApply(_, { user }) {
      const data = await server.getLastApply(user.id);
      if (data instanceof Object) {
        const info = {
          ...data,
          legalMobile:data.legalPersonMobile,
          legalName: data.legalPerson
        }
        dispatch.user.update({ lastApplyInfo: info });
        return info
      }
    },
    async manualApply(data, { user }:IRootState) {
      const res = await server.manualApply({ ...data, type: 'manual', memberId: user.id ,checkStatus:1});
      dispatch.user.getLastApply()
      console.log(res, 'manualApply');
    },

    resetUser() {
      dispatch.user.update({
        token: '',
        id: '',
        userName: '',
        nickName: '',
        signed: false,
        authState: AUTH_TYPE.UN_AUTH,
        contractType: CONTRACT_TYPE.PERSON_TYPE,
        wxCode: '',
        tenantId:'',
        auth: {
          personCertificate: false,
          personConfirm: false,
          enterpriseAuthorize: false,
          enterpriseCertificate: false,
          enterpriseConfirm: false,
          businessLicensePhoto: '',
          companyAddress: '',
          companyName: '',
          legalIdNo: '',
          legalMobile: '',
          legalName: '',
          unifiedSocialCreditCode: '',
          mobile: '',
          realName: '',
        },
        lastApplyInfo: {
          legalMobile: '',
          legalIdNo: '',
          legalName: '',
          companyName: '',
          unifiedSocialCreditCode: '',
          businessLicensePhoto: '',
          companyAddress: '',
          type: '',
          checkStatus: 0,
          memo: '',
        },
      });
      localStorage.removeItem('token');
      localStorage.removeItem('memberId');
      showToast('退出成功');
      const path = history?.location.pathname;
      if (path && openPath.indexOf(path) > -1) {
        location.reload();
      } else {
        history && history.push('/');
      }
    },
  }),
};
