import { IRootDispatch } from '@/store';
/*
 * @Date: 2022-05-24 15:47:16
 * @LastEditors: royce
 * @LastEditTime: 2022-06-13 16:10:57
 * @FilePath: /mall-web/src/models/constract.ts
 */
import server from '@/services/order';
import { ContractType, CONTRACT_TYPE } from '@/types';
import _ from 'lodash';
import qs from 'qs';

export const ALL_CONTRACT_STATUS = ['partyA_to_be_signed', 'partyB_to_be_signed', 'created','contract_void','completed'];
export const CONTRACT_PAGE_SIZE = 10;
export default {
  state: {
    dataList:[],
    status: ALL_CONTRACT_STATUS,
    startTime: '',
    endTime: '',
    total: 0,
    pageIndex: 1,
    searchCode: '',
    dateRange: [] as any,
    contractType: CONTRACT_TYPE.PERSON_TYPE,

  },
  reducers: {
    update(prevState, payload) {
      return { ...prevState, ...payload };
    },
  },
  effects: (dispatch:IRootDispatch) => ({
    async getList(data, rootState) {
      const { contract } = rootState;
      const { user } = rootState;
      const { dateRange, searchCode } = contract;
      if (Array.isArray(dateRange) && dateRange.length) {
        data['startTime'] = contract.dateRange[0].format('YYYY-MM-DD');
        data['endTime'] = contract.dateRange[1].format('YYYY-MM-DD');
      }
      if (searchCode) {
        data['keyWords'] = searchCode;
      }
      const param = {
        memberId: user.id,
        pageIndex: data.pageIndex,
        pageSize: CONTRACT_PAGE_SIZE,
        signType: 'a_',
        contractType: contract.contractType,
        status: data.status ? data.status.join(',') : contract.status.join(','),
      };

      const res = await server.getContract(param);
      const {rows,total } = res
      dispatch.contract.update({
        dataList:rows,
        total
      })
      const query = { status: data.status, pageIndex: data.pageIndex };
      history && history.pushState({}, '', `${location.pathname}?${qs.stringify(query)}`);

    },
  }),
};

