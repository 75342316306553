import { config, request } from 'ice';
import qs from 'qs';

const { BASE_URL } = config;

export default {
  async getShopInfo(id) {
    return await request(`${BASE_URL}/backend/enterprise/${id}/store?storeId=${id}`);
  },
  async openShop(data) {
    return await request.post(`${BASE_URL}/backend/enterprise/open`, data);
  },
  async editShop(data) {
    return await request.post(`${BASE_URL}/backend/enterprise/${data.storeId}/store`, data);
  },

  async payMargin(data) {
    return await request.post(`${BASE_URL}/backend/enterprise/payMargin`, data);
  },

  async shopList(data) {
    return await request.get(`${BASE_URL}/backend/manager/store?${qs.stringify(data)}`);
  },

  async shopAudit(id) {
    return await request.post(`${BASE_URL}/backend/manager/${id}/apply?storeId=${id}`);
  },
  async shopInfo(shopId) {
    return await request.get(`${BASE_URL}/shm/buyer/shopInfo?shopId=${shopId}`);
  },

  async getCategoryListByShopId(shopId) {
    return await request.get(`${BASE_URL}/shm/categoryListByShopId?channl=PC&treeFormat=true&shopId=${shopId}`);
  },

};
