/* eslint-disable @iceworks/best-practices/no-secret-info */
/*
 * @Date: 2022-05-23 16:39:48
 * @LastEditors: royce
 * @LastEditTime: 2022-06-22 17:15:45
 * @FilePath: /mall-web/src/const.ts
 */
export const PRIVACY_POLICY_URL = 'https://shm-1256288681.cos.ap-nanjing.myqcloud.com/agreement-privacy.html';
export const TERMS_OF_SERVICE_URL = 'https://shm-1256288681.cos.ap-nanjing.myqcloud.com/agreement-register.html';
export const descExtraImgs = [
  {
    url: 'https://shm-1256288681.cos.ap-nanjing.myqcloud.com/image/00000000-0000-0000-0000-000000000001/yikoujia2022062113265732561574.png',
  },
  {
    url: 'https://shm-1256288681.cos.ap-nanjing.myqcloud.com/image/00000000-0000-0000-0000-000000000001/order-tips2022062113284217182020.png',
  },
];
export const MAX_FILE_COUNT = 40;
export const STORE_KEYS = {
  SEARCH_HISTORY: 'searchHistory',
  USER_TOKEN: 'token',
  USER_ID: 'userId',
  ENQUIRY_DATA: 'enquiryData',
  PURCHASE_DATA: 'purchaseData',
};

export const openPath = ['/', '/goods', '/order-detail'];
export const BEI_AN1 =  'https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33011302000219'
export const BEI_AN2 =  'https://beian.miit.gov.cn'
export const BEI_AN_ICP =  'https://static.gonglu.info/common/icp.png'

export const purchaselimit = [
  {
    label: '一天',
    value: 1,
  },
  {
    label: '一周',
    value: 7,
  },
  {
    label: '一个月',
    value: 30,
  },
];
export const MIN_SERVICE_FEE = 12;
