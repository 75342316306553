import { MEASUE_METHOD, ORDER_STATUS } from '@/types';
/*
 * @Date: 2022-04-07 13:52:14
 * @LastEditors: royce
 * @LastEditTime: 2022-06-24 15:14:28
 * @FilePath: /mall-web/src/models/order.ts
 */
import { ExpressInfo, OfflinePayItem, ORDER_TYPE, REFUND_ORDER_STATUS, REFUND_VOUCHER_STATUS } from './../types';
import orderServer from '@/services/order';
import { IRootDispatch, IRootState } from '@/store';
import { CONTRACT_STATUS, PAY_TYPE, ShopAccount } from '@/types';
import { getBaseUrl, showSuccess } from '@/utils';
import { times } from 'number-precision';
import { FileItem } from './refund';
import qs from 'qs';
import { shouldReturnAll } from '@/pages/orderRefund';
import { isArray } from 'lodash';
export const ORDER_PAGE_SIZE = 10;
export interface OrderCancelReason {
  desc: string;
  reason: string;
}
export interface OrderGoodsItem {
  count: number;
  defectHandle: string;
  goodsId: string;
  orderGoodsId: string;
  goodsName: string;
  goodsPic: string;
  price: number;
  refundId: string;
  refundReason: string;
  refundState: string;
  specTitle: Object;
  status: string;
  refundCount: string;
  reason: string;
  buyerRemark: string;
  commissions: string;
  discountPrice: number;
  picJson: string[];
  shopName: string;
  shopId: string;
  platPrice: number;
  unitEach: number;
  priceUnit: number;
  check?: boolean;
  measureMethod?: MEASUE_METHOD;
  selectCount?: number;
  goodsUnit: string;
}

export interface OrderItem {
  orderId: string;
  address: string;
  addressArea: string;
  addressCity: string;
  addressProvince: string;
  addressStreet: string;
  offlineAccountId: string;
  buyerPrice: string;
  count: number;
  goodsList: OrderGoodsItem[];
  orderCode: string;
  payType: PAY_TYPE;
  payedTime: string;
  postFee: number;
  userLock: boolean;
  systemLock: boolean;
  postedOutTime: string;
  price: number;
  status: ORDER_STATUS;
  updateAddress: string;
  refundStatus: number;
  receiverName: string;
  receiverTelephone: string;
  orderMark: string;
  createTime: string;
  delayedOnce: number;
  expireTime: string;
  expireUpdateAddressTime: string;
  expressComparyCode: string;
  expressComparyName: string;
  sendcode: string;
  reFundPirce: number;
  platPriceTotal: number;
  originalPrice: number;
  actualPayPrice: number;
  discountPrice: number;
  totalPrice: number;
  receivedTime: string;
  paymentBillId: string;
  contractId?: string;
  deliveryMethod: string;
  payMethod: string;
  payVoucherStatus: REFUND_VOUCHER_STATUS;
  enquiryId?: string;
  billCode?: string;
  payGatewayUrl?: string;
  invoiceInfo?: any;
  orderType: ORDER_TYPE;
  refundList: OrderRefundType[];
}

export interface OrderState {
  orderList: OrderItem[];
  detailItem: OrderItem;
  searchIndex: number;
  pageIndex: number;
  status: string;
  hasNextPage: boolean;
  total: number;
  reasons: OrderCancelReason[];
  shopInfo: ShopAccount | {};
  showOnlinePayModal: boolean;
}
export interface OrderRefundType {
  id: string;
  refundCode: string;
  refundStatus: REFUND_ORDER_STATUS;
}
export const ALL_STATUS = 'signing,pending,payed,paying,posted_out,received,canceled,closed,abnormal_closed';
export default {
  state: {
    orderList: [],
    searchIndex: 1,
    total: 0,
    pageIndex: 1,
    detailItem: {} as OrderItem,
    status: ALL_STATUS,
    hasNextPage: true,
    reasons: [],

    shopInfo: { shopId: '', status: '', shopName: '', offlineAccounts: [] as OfflinePayItem[] },
    offlineAccountId: '',
    express: {
      name: '',
      expressCode: '',
      received: false,
      sendOutTime: '',
      logistics: [],
    } as ExpressInfo,
    showOnlinePayModal: false,
    offlinePayments: [] as FileItem[],
  },
  reducers: {
    update(prevState, payload) {
      const state = { ...prevState, ...payload };
      return state;
    },
  },
  effects: (dispatch: IRootDispatch) => ({
    async getOrderList({
      userId,
      status,
      pageIndex,
      code,
    }: {
      userId: string;
      status: string;
      pageIndex: number;
      code?: string;
    }) {
      const param = {
        memberId: userId,
        pageIndex,
        pageSize: ORDER_PAGE_SIZE,
        status,
        code,
      };
      const data = await orderServer.getOrderListApi(param);
      const { rows, total, hasNextPage } = data;
      let payloadObj = {
        orderList: [] as any,
        total: 0,
        hasNextPage: false,
        pageIndex: 1,
      };
      if (Array.isArray(rows)) {
        payloadObj = { orderList: rows, total, hasNextPage, pageIndex };
      }

      const query = { status, pageIndex, code };
      if (location.pathname.indexOf('order-detail') < 0) {
        history.pushState({}, '', `${location.pathname}?${qs.stringify(query)}`);
        dispatch.order.update({ ...payloadObj, status, pageIndex, code });
      }
    },
    async orderSearch({ code }) {
      const param = {
        pageSize: 100,
        code,
      };
      const data = await orderServer.orderSearch(param);
      const { rows } = data;
      console.log(data, 'data');
      const payloadObj = {
        orderList: rows,
      };

      dispatch.order.update(payloadObj);
    },
    async getShopAccount(shopId) {
      dispatch.order.update({offlinePayments:[]})
      const res = await orderServer.getShopAccount({
        shopId,
      });
      dispatch.order.update({ shopInfo: res });
    },
    async getDetailOrder(orderId, { user }) {
      const param = {
        memberId: user.id,
        orderId,
      };

      const res = await orderServer.getOrderListApi(param);
      console.log('systemLock2')
      if (res && Array.isArray(res.rows) && res.rows[0]) {
        let order = res.rows[0]
        if (shouldReturnAll) {
          order.goodsList = res.rows[0].goodsList.map((item) => {
            return {
              ...item,
              selectCount: item.count,
              check: true,
            };
          });
        }
        dispatch.order.update({
          detailItem: order,
        });
        // TODO: 刷新列表
        const isSend = res.rows[0].sendcode;
        if (isSend) {
          dispatch.order.getExpressInfo(orderId);
        }
        return true;
      } else {
        dispatch.order.update({
          detailItem: {},
        });
        return false;
      }

      // if (order.status === ALL_STATUS) {
      //   const temp = order.orderList.slice();
      //   const index = order.orderList.findIndex((item) => item.orderId === data.orderId);
      //   if (index > 0) {
      //     temp[index] = res.rows[0];
      //   }

      //   dispatch.order.update({
      //     detailItem: res.rows[0],
      //     orderList: temp,
      //   });
      // } else {
      //   dispatch.order.update({
      //     detailItem: res.rows[0],
      //   });
      // }
    },
    async getExpressInfo(orderId) {
      try {
        const data = await orderServer.getExpress(orderId);
        dispatch.order.update({
          express: data,
        });
      } catch (error) {
        // return Promise.reject(error);
      }
    },
    async getOfflinePaymentAttachment({ paymentBillId }) {
      try {
        const data = await orderServer.getOfflinePayVoucher(paymentBillId);
        if (isArray(data)) {
          dispatch.order.update({
            offlinePayments: data.map((item) => {
              return {
                url: item.fileUrl,
                ...item,
              };
            }),
          });
        }
      } catch (error) {
        // return Promise.reject(error);
      }
    },
    async getCancelOrderReason() {
      try {
        const res = await orderServer.orderCancelReason();
        dispatch.order.update({ reasons: res.data });
      } catch (error) {
        // return Promise.reject(error);
      }
    },
    async cancelOrder(data, { user, order }) {
      try {
        await orderServer.orderCancel(data);
        dispatch.order.refreshOrder();
        showSuccess('订单取消成功');
      } catch (error) {
        // return Promise.reject(error);
      }
    },
    async paySuccess(data) {
      try {
        const res = await orderServer.orderPayed(data);
      } catch (error) {
        // return Promise.reject(error);
      }
    },
    async deleteOrder(data) {
      try {
        await orderServer.orderDelete(data.orderId);
        showSuccess('订单删除成功');
        dispatch.order.refreshOrder();
      } catch (error) {
        // return Promise.reject(error);
      }
    },
    async refreshOrder(_, { user, order }: IRootState) {
      const param = { userId: user.id, status: order.status, pageIndex: order.pageIndex, code: order.code };
      dispatch.order.getOrderList(param);
    },
    async evaOrder(data, rootStore) {
      const { user } = rootStore;
      await orderServer.orderEva({ memberId: user.id, ...data });
      showSuccess('订单评价成功');
      dispatch.order.refreshOrder();
    },
    async submitPayDocument({ paymentVouchers, paymentBillId, accountId }, { user, order }: IRootState) {
      const { shopId } = order.shopInfo;
      await orderServer.submitPayDocument({
        paymentBillId,
        accountId,
        paymentVoucher: paymentVouchers,
        memberId: user.id,
        mobile: user.auth.mobile,
        tenantId: shopId,
      });
      showSuccess('凭证上传成功');
      dispatch.order.refreshOrder();

      // 提交凭证 跳转订单列表
    },
    async receiptOrder(data) {
      await orderServer.orderReceive({
        orderId: data.orderId,
        delayed: false,
      });
      showSuccess('订单确认成功');
      dispatch.order.refreshOrder();
    },
    async requestOrderSign(data, rootStore) {
      const { user } = rootStore;
      const res = await orderServer.getContract({
        memberId: user.id,
        ...data,
      });
      if (Array.isArray(res.rows)) {
        const { partyaUrl, contractStatus, contractName, contractContent } = res.rows[0];
        let url = partyaUrl;
        if (contractStatus === CONTRACT_STATUS.COMPLETED) {
          url = contractContent;
        }
        return Promise.resolve({ url });
      }
      return Promise.reject({ message: '获取合同失败' });
      // 提交凭证 跳转订单列表
    },
    async getOrderSign(data, rootStore) {},
    async createOnline({ paymentBillCode, actualPayPrice }, rootState: IRootState) {
      const { user } = rootState;
      const orderPriceFen = times(actualPayPrice, 100);
      const param = {
        billCode: paymentBillCode,
        amount: orderPriceFen,
        remark: orderPriceFen > 50000 ? '超过5W元大额存单的备注' : '',
        memberId: user.id,
        returnUrl: `${getBaseUrl()}/workbench/order`,
      };
      try {
        const res = await orderServer.createOnline(param);
        dispatch.order.refreshOrder();
        return res;
      } catch (error) {
        dispatch.order.refreshOrder();
        // showErrorToast(error.message);
      }
    },
  }),
};
// export default model;
