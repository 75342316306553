/*
 * @Author: your name
 * @Date: 2022-01-18 11:07:20
 * @LastEditTime: 2022-06-23 18:55:57
 * @LastEditors: royce
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /mall-web/src/components/OrderItemView/index.tsx
 */
import { Popconfirm, Space, Row, Col, Tooltip, Button, Image } from 'antd';
import { commonEnumDes, PURCHASE_ORDER_STATUS, REFUND_ACCOUNT_TYPE, REFUND_ORDER_STATUS, ShopInfo } from '@/types';
import _ from 'lodash';
import './index.less';
import appStore from '@/store';
import ActionButton from '../ActionButton';
import { useHistory } from 'ice';
import { OrderItem } from '@/models/order';
import { QuestionCircleOutlined } from '@ant-design/icons';
import PurchaseStatus from '../PurchaseStatus';
import { getRefundStatusColor } from '@/utils';

export function TitleView(props: { title: string }) {
  const { title } = props;

  return <div className="title-view">{title}</div>;
}
export function NormalTitleView(props: { title: string }) {
  const { title } = props;

  return <div className="title-view-normal">{title}</div>;
}
export function FromTitleView(props: { title: string }) {
  const { title } = props;

  return <div className="form-title-view">{title}</div>;
}
export function RefundMoneyInfoView(props: { item: OrderItem; onClick?: Function; hideHeader?: boolean }) {
  const { item } = props;
  const originalPrice = _.defaultTo(item.originalPrice, 0);
  const actualPayPrice = _.defaultTo(item.actualPayPrice, 0);
  const platPriceTotal = _.defaultTo(item.platPriceTotal, 0);
  const discountPrice = _.defaultTo(item.discountPrice, 0);
  const postFee = _.defaultTo(item.postFee, 0);

  return (
    <div className="order-refund-item">
      <Row justify="center" align="middle" className="order-refund-list-header">
        <Col className="col" span={4}>
          商品合计
        </Col>
        <Col className="col" span={5}>
          运费
        </Col>
        <Col className="col" span={5}>
          优惠金额
        </Col>
        <Col className="col" span={5}>
          服务费
        </Col>
        <Col className="col" span={5}>
          <Tooltip title="商品合计+运费+买家服务费-优惠价">
            <span>
              实付金额 <QuestionCircleOutlined />
            </span>
          </Tooltip>
        </Col>
      </Row>
      <Row justify="center" align="middle" className="text-content">
        <Col className="col-content" span={4}>
          ￥{originalPrice.toFixed(2)}
        </Col>
        <Col className="col-content" span={5}>
          ￥{postFee.toFixed(2)}
        </Col>
        <Col className="col-content" span={5}>
          ￥{discountPrice.toFixed(2)}
        </Col>
        <Col className="col-content" span={5}>
          ￥{platPriceTotal.toFixed(2)}
        </Col>
        <Col className="col-content" span={5}>
          ￥{actualPayPrice.toFixed(2)}
        </Col>
      </Row>
    </div>
  );
}
export function ShopInfoView(props: { shopInfo: ShopInfo }) {
  const { shopName, address, companyName } = props.shopInfo;

  return (
    <div className="order-refund-item">
      <Row justify="center" align="middle" className="order-refund-list-header">
        <Col className="col" span={4}>
          店铺名称
        </Col>
        <Col className="col" span={8}>
          公司名称
        </Col>
        <Col className="col" span={12}>
          营业地址
        </Col>
      </Row>
      <Row justify="center" align="middle" className="text-content">
        <Col className="col-content" span={4}>
          {shopName}
        </Col>
        <Col className="col-content" span={8}>
          {companyName}
        </Col>
        <Col className="col-content" span={12}>
          {address}
        </Col>
      </Row>
    </div>
  );
}
export function OfflineAccountView(props: { item: any }) {
  const { offlineAccountName, offlineAccountNo, offlineBankName, offlineAccountType, payVoucher } = props.item;
  const desType = commonEnumDes[offlineAccountType];
  const pzItem = () => (
    <Col className="col-content" span={5}>
      <div className="offline-images">
        {_.isArray(payVoucher) &&
          payVoucher.map((item, index) => <Image key={`${index}`} className="img" src={item.fileUrl} />)}
      </div>
    </Col>
  );
  const aliTitle = () => (
    <>
      <Col className="col" span={4}>
        账号类型
      </Col>
      <Col className="col" span={8}>
        户名
      </Col>
      <Col className="col" span={7}>
        账户
      </Col>
      <Col className="col" span={5}>
        退款凭证照片
      </Col>

    </>
  );
  const aliContent = () => (
    <>
      <Col className="col-content" span={4}>
        {desType}
      </Col>
      <Col className="col-content" span={8}>
        {offlineAccountName}
      </Col>
      <Col className="col-content" span={7}>
        {offlineAccountNo}
      </Col>
      <Col className="col-content" span={5}>
        {pzItem()}
      </Col>
    </>
  );
  const bankContent = () => (
    <>
      <Col className="col-content" span={4}>
        {desType}
      </Col>
      <Col className="col-content" span={6}>
        {offlineAccountName}
      </Col>
      <Col className="col-content" span={6}>
        {offlineAccountNo}
      </Col>
      <Col className="col-content" span={3}>
        {offlineBankName}
      </Col>
      {pzItem()}
    </>
  );
  const bankTitle = () => (
    <>
      <Col className="col" span={4}>
        账号类型
      </Col>
      <Col className="col" span={6}>
        企业名称（户名）
      </Col>
      <Col className="col" span={6}>
        企业银行卡卡号
      </Col>
      <Col className="col" span={3}>
        开户行
      </Col>
      <Col className="col" span={5}>
        退款凭证照片
      </Col>
    </>
  );

  return (
    <div className="order-refund-item">
      <Row justify="center" align="middle" className="order-refund-list-header">
        {offlineAccountType === REFUND_ACCOUNT_TYPE.ALIPAY_ACCOUNT && aliTitle()}
        {offlineAccountType === REFUND_ACCOUNT_TYPE.UNION_ACCOUNT && bankTitle()}
      </Row>
      <Row justify="center" align="middle" className="text-content">
        {offlineAccountType === REFUND_ACCOUNT_TYPE.ALIPAY_ACCOUNT && aliContent()}
        {offlineAccountType === REFUND_ACCOUNT_TYPE.UNION_ACCOUNT && bankContent()}
      </Row>
    </div>
  );
}
export function ReturnAddress(props: {
  province?: string;
  city?: string;
  area?: string;
  address?: string;
  receiptLinkPerson?: string;
  receiptLinkTel?: string;
}) {
  const { province, address, area, city, receiptLinkPerson, receiptLinkTel } = props;

  return (
    <div className="order-refund-item">
      <Row justify="center" align="middle" className="order-refund-list-header">
        <Col className="col" span={4}>
          收货人
        </Col>
        <Col className="col" span={4}>
          联系电话
        </Col>
        <Col className="col" span={6}>
          所在区域
        </Col>
        <Col className="col" span={10}>
          详细地址
        </Col>
      </Row>
      <Row justify="center" align="middle" className="text-content">
        <Col className="col-content" span={4}>
          {receiptLinkPerson}
        </Col>
        <Col className="col-content" span={4}>
          {receiptLinkTel}
        </Col>
        <Col className="col-content" span={6}>
          {`${province} ${city} ${area}`}
        </Col>
        <Col className="col-content" span={10}>
          {address}
        </Col>
      </Row>
    </div>
  );
}

export function ReturnExpressInfo(props) {
  const { expressName, sendCode, expressLinkPerson, expressLinkTel, expressRemark } = props.expressInfo;

  return (
    <div className="order-refund-item">
      <Row justify="center" align="middle" className="order-refund-list-header">
        <Col className="col" span={4}>
          物流公司
        </Col>
        <Col className="col" span={4}>
          物流单号
        </Col>
        <Col className="col" span={4}>
          联系人
        </Col>
        <Col className="col" span={4}>
          联系电话
        </Col>
        <Col className="col" span={8}>
          备注
        </Col>
      </Row>
      <Row justify="center" align="middle" className="text-content">
        <Col className="col-content" span={4}>
          {expressName || '-'}
        </Col>
        <Col className="col-content" span={4}>
          {sendCode || '-'}
        </Col>
        <Col className="col-content" span={4}>
          {expressLinkPerson || '-'}
        </Col>
        <Col className="col-content" span={4}>
          {expressLinkTel || '-'}
        </Col>
        <Col className="col-content" span={8}>
          {expressRemark || '-'}
        </Col>
      </Row>
    </div>
  );
}
